import React from 'react';
import CheatsheetDashboard from './common/CheatsheetDashboard';

const lldbCommands = [
  {
    category: "基础命令",
    commands: [
      {
        command: "run",
        description: "运行程序",
        usage: "高",
        explanation: "开始执行当前加载的程序。如果程序已经在运行，它会重新启动程序。",
        options: [
          { flag: "-arch <architecture>", description: "指定运行的架构" },
          { flag: "--", description: "在此之后的参数将被传递给程序" }
        ]
      },
      {
        command: "breakpoint set -n <function_name>",
        description: "设置断点",
        usage: "高",
        explanation: "在指定的函数名处设置断点。当程序执行到这个函数时，它会暂停执行。",
        options: [
          { flag: "-f <filename>", description: "指定断点所在的文件" },
          { flag: "-l <line_number>", description: "在指定的行号设置断点" }
        ]
      },
      {
        command: "continue",
        description: "继续执行",
        usage: "高",
        explanation: "从当前暂停的位置继续执行程序，直到遇到下一个断点或程序结束。",
        options: []
      },
      {
        command: "quit",
        description: "退出 LLDB",
        usage: "中",
        explanation: "终止调试会话并退出 LLDB。",
        options: []
      }
    ]
  },
  {
    category: "断点管理",
    commands: [
      {
        command: "breakpoint list",
        description: "列出所有断点",
        usage: "高",
        explanation: "显示当前设置的所有断点及其详细信息。",
        options: []
      },
      {
        command: "breakpoint delete <breakpoint_id>",
        description: "删除断点",
        usage: "中",
        explanation: "删除指定 ID 的断点。",
        options: []
      },
      {
        command: "breakpoint enable <breakpoint_id>",
        description: "启用断点",
        usage: "中",
        explanation: "启用之前被禁用的断点。",
        options: []
      },
      {
        command: "breakpoint disable <breakpoint_id>",
        description: "禁用断点",
        usage: "中",
        explanation: "暂时禁用指定的断点，但不删除它。",
        options: []
      }
    ]
  },
  {
    category: "查看和修改",
    commands: [
      {
        command: "frame variable",
        description: "查看局部变量",
        usage: "高",
        explanation: "显示当前栈帧中的所有局部变量及其值。",
        options: [
          { flag: "-F <format>", description: "指定输出格式，如 hex, decimal 等" }
        ]
      },
      {
        command: "expression <expr>",
        description: "计算表达式",
        usage: "中",
        explanation: "计算给定的表达式并显示结果。可以用来修改变量值或调用函数。",
        options: [
          { flag: "--", description: "表示表达式的开始，用于避免与LLDB命令冲突" }
        ]
      },
      {
        command: "memory read <address>",
        description: "读取内存",
        usage: "中",
        explanation: "从指定的内存地址读取并显示内容。",
        options: [
          { flag: "-s <size>", description: "指定要读取的字节数" },
          { flag: "-f <format>", description: "指定输出格式，如 x (十六进制), d (十进制) 等" }
        ]
      },
      {
        command: "register read",
        description: "读取寄存器",
        usage: "中",
        explanation: "显示当前线程的寄存器值。",
        options: [
          { flag: "<register_name>", description: "指定要读取的特定寄存器" }
        ]
      }
    ]
  },
  {
    category: "控制执行",
    commands: [
      {
        command: "thread step-over",
        description: "单步执行（越过函数）",
        usage: "高",
        explanation: "执行当前行，如果当前行包含函数调用，则越过该函数。",
        options: []
      },
      {
        command: "thread step-in",
        description: "单步执行（进入函数）",
        usage: "高",
        explanation: "执行当前行，如果当前行包含函数调用，则进入该函数。",
        options: []
      },
      {
        command: "thread step-out",
        description: "步出",
        usage: "中",
        explanation: "继续执行直到当前函数返回。",
        options: []
      },
      {
        command: "thread until <line_number>",
        description: "运行至指定行",
        usage: "中",
        explanation: "继续执行当前线程，直到达到指定的行号。",
        options: []
      }
    ]
  },
  {
    category: "栈帧操作",
    commands: [
      {
        command: "bt",
        description: "显示调用栈",
        usage: "高",
        explanation: "显示当前线程的完整调用栈。",
        options: [
          { flag: "all", description: "显示所有线程的调用栈" }
        ]
      },
      {
        command: "frame select <frame_number>",
        description: "选择栈帧",
        usage: "中",
        explanation: "切换到指定编号的栈帧。",
        options: []
      },
      {
        command: "frame info",
        description: "显示当前栈帧信息",
        usage: "中",
        explanation: "显示当前栈帧的详细信息，包括源代码位置。",
        options: []
      }
    ]
  },
  {
    category: "数据断点",
    commands: [
      {
        command: "watchpoint set variable <variable_name>",
        description: "设置数据断点",
        usage: "中",
        explanation: "在指定变量上设置断点，当变量的值发生变化时暂停执行。",
        options: []
      },
      {
        command: "watchpoint list",
        description: "列出数据断点",
        usage: "低",
        explanation: "显示所有设置的数据断点。",
        options: []
      },
      {
        command: "watchpoint delete <watchpoint_id>",
        description: "删除数据断点",
        usage: "低",
        explanation: "删除指定 ID 的数据断点。",
        options: []
      }
    ]
  },
  {
    category: "多线程调试",
    commands: [
      {
        command: "thread list",
        description: "列出所有线程",
        usage: "中",
        explanation: "显示当前进程中的所有线程及其状态。",
        options: []
      },
      {
        command: "thread select <thread_id>",
        description: "切换当前线程",
        usage: "中",
        explanation: "将调试焦点切换到指定 ID 的线程。",
        options: []
      },
      {
        command: "thread backtrace all",
        description: "显示所有线程的调用栈",
        usage: "中",
        explanation: "显示所有线程的完整调用栈信息。",
        options: []
      }
    ]
  }
];

const LLDBCommandsDashboard = () => {
  return (
    <CheatsheetDashboard
      title="LLDB 常用命令面板"
      commands={lldbCommands}
    />
  );
};

export default LLDBCommandsDashboard;