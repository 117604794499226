import React, { useState, useEffect, useMemo, useRef } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const TypewriterTitle = () => {
    const { t } = useTranslation();
    const [displayText, setDisplayText] = useState("");
    const [titleIndex, setTitleIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const containerRef = useRef(null);
    const hiddenTextRef = useRef(null);

    const titles = useMemo(() => {
        return [t("sloganPoint1"), t("sloganPoint2"), t("sloganPoint3")];
    }, [t]);

    const longestText = useMemo(() => {
        return t("sloganCommon") + ' ' + titles.reduce((a, b) => a.length > b.length ? a : b);
    }, [t, titles]);

    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current && hiddenTextRef.current) {
                containerRef.current.style.height = 'auto';
                let maxHeight = 0;
                titles.forEach(title => {
                    hiddenTextRef.current.textContent = t("sloganCommon") + ' ' + title;
                    maxHeight = Math.max(maxHeight, hiddenTextRef.current.offsetHeight);
                });
                containerRef.current.style.height = `${maxHeight + 30}px`;
            }
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, [longestText, titles, t]);

    useEffect(() => {
        const currentTitle = titles[titleIndex];
        const updateText = () => {
            if (!isDeleting && displayText === currentTitle) {
                setTimeout(() => setIsDeleting(true), 1000);
            } else if (isDeleting && displayText === "") {
                setIsDeleting(false);
                setTitleIndex((prevIndex) => (prevIndex + 1) % titles.length);
            } else {
                const delta = isDeleting ? 15 : 30;
                setTimeout(() => {
                    setDisplayText(currentTitle.substring(0, isDeleting ? displayText.length - 1 : displayText.length + 1));
                }, delta);
            }
        };

        const timer = setTimeout(updateText, 30);
        return () => clearTimeout(timer);
    }, [displayText, isDeleting, titleIndex, titles]);

    return (
        <div className="w-full max-w-4xl mx-auto px-4 py-4 sm:py-8">
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-black mb-4 sm:mb-8 text-center flex flex-col items-center">
                <span className="text mb-2">{t("sloganCommon")}</span>
                <span className="relative" ref={containerRef}>
                    <motion.span
                        className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600 block leading-tight"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        {displayText}
                    </motion.span>
                    <span
                        ref={hiddenTextRef}
                        className="invisible absolute top-0 left-0 whitespace-nowrap leading-tight"
                        aria-hidden="true"
                    >
                        {longestText}
                    </span>
                </span>
            </h1>
        </div>
    );

};
export default TypewriterTitle;