import React, { useState, useMemo } from 'react';
import { Search, ChevronDown } from 'lucide-react';

const AndroidVersionTable = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchField, setSearchField] = useState('all');

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const calculateTimeSinceRelease = (releaseYear) => {
    if (releaseYear === 'TBD') return 'Not released yet';
    const years = currentYear - parseInt(releaseYear);
    const months = currentMonth;
    if (years === 0) {
      return months === 0 ? 'This year' : `${months} mo${months > 1 ? 's' : ''} ago`;
    } else {
      return `${years} yr${years > 1 ? 's' : ''} ${months} mo${months > 1 ? 's' : ''} ago`;
    }
  };

  const androidVersions = useMemo(() => [
    { version: "15", sdkLevel: "35", versionCode: "VANILLA_ICE_CREAM", codename: "Vanilla Ice Cream", usage: "—", year: "TBD" },
    { version: "14", sdkLevel: "34", versionCode: "UPSIDE_DOWN_CAKE", codename: "Upside Down Cake", usage: "16.3%", year: "2023" },
    { version: "13", sdkLevel: "33", versionCode: "TIRAMISU", codename: "Tiramisu", usage: "42.5%", year: "2022" },
    { version: "12L", sdkLevel: "32", versionCode: "S_V2", codename: "Snow Cone", usage: "59.5%", year: "2022" },
    { version: "12", sdkLevel: "31", versionCode: "S", codename: "Snow Cone", usage: "59.5%", year: "2021" },
    { version: "11", sdkLevel: "30", versionCode: "R", codename: "Red Velvet Cake", usage: "75.7%", year: "2020" },
    { version: "10", sdkLevel: "29", versionCode: "Q", codename: "Quince Tart", usage: "84.5%", year: "2019" },
    { version: "9", sdkLevel: "28", versionCode: "P", codename: "Pie", usage: "90.2%", year: "2018" },
    { version: "8.1", sdkLevel: "27", versionCode: "O_MR1", codename: "Oreo", usage: "92.1%", year: "2017" },
    { version: "8.0", sdkLevel: "26", versionCode: "O", codename: "Oreo", usage: "95.1%", year: "2017" },
    { version: "7.1", sdkLevel: "25", versionCode: "N_MR1", codename: "Nougat", usage: "95.6%", year: "2016" },
    { version: "7.0", sdkLevel: "24", versionCode: "N", codename: "Nougat", usage: "97.0%", year: "2016" },
    { version: "6.0", sdkLevel: "23", versionCode: "M", codename: "Marshmallow", usage: "98.4%", year: "2015" },
    { version: "5.1", sdkLevel: "22", versionCode: "LOLLIPOP_MR1", codename: "Lollipop", usage: "99.2%", year: "2015" },
    { version: "5.0", sdkLevel: "21", versionCode: "LOLLIPOP", codename: "Lollipop", usage: "99.5%", year: "2014" },
    { version: "4.4W", sdkLevel: "20", versionCode: "KITKAT_WATCH", codename: "KitKat", usage: "99.8%", year: "2014" },
    { version: "4.4", sdkLevel: "19", versionCode: "KITKAT", codename: "KitKat", usage: "99.8%", year: "2013" },
    { version: "4.3", sdkLevel: "18", versionCode: "JELLY_BEAN_MR2", codename: "Jelly Bean", usage: "99.8%", year: "2013" },
    { version: "4.2", sdkLevel: "17", versionCode: "JELLY_BEAN_MR1", codename: "Jelly Bean", usage: "99.8%", year: "2012" },
    { version: "4.1", sdkLevel: "16", versionCode: "JELLY_BEAN", codename: "Jelly Bean", usage: "99.8%", year: "2012" },
    { version: "4.0.3", sdkLevel: "15", versionCode: "ICE_CREAM_SANDWICH_MR1", codename: "Ice Cream Sandwich", usage: "99.8%", year: "2011" },
    { version: "4.0.1", sdkLevel: "14", versionCode: "ICE_CREAM_SANDWICH", codename: "Ice Cream Sandwich", usage: "99.8%", year: "2011" },
    { version: "3.2", sdkLevel: "13", versionCode: "HONEYCOMB_MR2", codename: "Honeycomb", usage: "No data", year: "2011" },
    { version: "3.1", sdkLevel: "12", versionCode: "HONEYCOMB_MR1", codename: "Honeycomb", usage: "No data", year: "2011" },
    { version: "3.0", sdkLevel: "11", versionCode: "HONEYCOMB", codename: "Honeycomb", usage: "No data", year: "2011" },
    { version: "2.3.3", sdkLevel: "10", versionCode: "GINGERBREAD_MR1", codename: "Gingerbread", usage: "No data", year: "2011" },
    { version: "2.3.0", sdkLevel: "9", versionCode: "GINGERBREAD", codename: "Gingerbread", usage: "No data", year: "2010" },
    { version: "2.2", sdkLevel: "8", versionCode: "FROYO", codename: "Froyo", usage: "No data", year: "2010" },
    { version: "2.1", sdkLevel: "7", versionCode: "ECLAIR_MR1", codename: "Eclair", usage: "No data", year: "2010" },
    { version: "2.0.1", sdkLevel: "6", versionCode: "ECLAIR_0_1", codename: "Eclair", usage: "No data", year: "2009" },
    { version: "2.0", sdkLevel: "5", versionCode: "ECLAIR", codename: "Eclair", usage: "No data", year: "2009" },
    { version: "1.6", sdkLevel: "4", versionCode: "DONUT", codename: "Donut", usage: "No data", year: "2009" },
    { version: "1.5", sdkLevel: "3", versionCode: "CUPCAKE", codename: "Cupcake", usage: "No data", year: "2009" },
    { version: "1.1", sdkLevel: "2", versionCode: "BASE_1_1", codename: "Petit Four", usage: "No data", year: "2009" },
    { version: "1.0", sdkLevel: "1", versionCode: "BASE", codename: "None", usage: "No data", year: "2008" },
  ], []);

  const filteredVersions = useMemo(() => {
    if (!searchTerm) return androidVersions;
    return androidVersions.filter(version => {
      if (searchField === 'all') {
        return Object.values(version).some(value =>
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
      } else {
        return version[searchField].toString().toLowerCase().includes(searchTerm.toLowerCase());
      }
    });
  }, [searchTerm, searchField, androidVersions]);

  const highlightText = (text, highlight) => {
    if (!highlight.trim() || (searchField !== 'all' && searchField !== 'version')) {
      return <span>{text}</span>;
    }
    const regex = new RegExp(`(${highlight})`, 'gi');
    const parts = text.split(regex);
    return (
      <span>
        {parts.map((part, i) =>
          regex.test(part) ? <mark key={i} className="bg-yellow-200">{part}</mark> : <span key={i}>{part}</span>
        )}
      </span>
    );
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Android Version History</h1>
      <div className="relative mb-6">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <Search className="h-5 w-5 text-gray-400" />
        </div>
        <select
          className="absolute inset-y-0 left-0 flex items-center h-full pl-10 pr-8 py-2 w-36 bg-transparent border-r border-gray-300 appearance-none cursor-pointer"
          value={searchField}
          onChange={(e) => setSearchField(e.target.value)}
        >
          <option value="all">All Fields</option>
          <option value="version">Version</option>
          <option value="sdkLevel">API Level</option>
          <option value="codename">Codename</option>
          <option value="usage">Usage</option>
          <option value="year">Year</option>
        </select>
        <div className="pointer-events-none absolute inset-y-0 left-28 flex items-center px-2 text-gray-700">
          <ChevronDown className="h-4 w-4" />
        </div>
        <input
          type="text"
          placeholder="Search Android versions..."
          className="w-full pl-40 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="overflow-x-auto shadow-md rounded-lg">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Version</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">API level</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Codename & Version Code</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cumulative usage</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Release</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredVersions.map((version, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className="text-lg font-semibold text-gray-900">{highlightText(version.version, searchTerm)}</span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className="font-medium text-gray-900">{highlightText(version.sdkLevel, searchTerm)}</span>
                </td>
                <td className="px-6 py-4">
                  <div className="text-lg font-semibold text-gray-900">{highlightText(version.codename, searchTerm)}</div>
                  <div className="text-sm text-gray-500">{highlightText(version.versionCode, searchTerm)}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className="font-medium text-gray-900">{highlightText(version.usage, searchTerm)}</span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="font-medium text-gray-900">{highlightText(calculateTimeSinceRelease(version.year), searchTerm)}</div>
                  <div className="text-sm text-gray-500">{highlightText(version.year, searchTerm)}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AndroidVersionTable;