import React, { useState, useContext } from 'react';
import { motion } from 'framer-motion';
import { ThemeContext } from '../contexts/ThemeContext';
import { Calculator, Monitor } from 'lucide-react';

const PPICalculator = () => {
  const { theme } = useContext(ThemeContext);
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [diagonalSize, setDiagonalSize] = useState('');
  const [ppi, setPPI] = useState(null);

  const calculatePPI = () => {
    const w = parseFloat(width);
    const h = parseFloat(height);
    const d = parseFloat(diagonalSize);

    if (w > 0 && h > 0 && d > 0) {
      const diagonalResolution = Math.sqrt(w * w + h * h);
      const ppi = diagonalResolution / d;
      setPPI(ppi.toFixed(2));
    } else {
      setPPI(null);
    }
  };

  const inputClass = `w-full p-3 border rounded-lg bg-opacity-50 backdrop-filter backdrop-blur-lg ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'
    } focus:outline-none focus:ring-2 focus:ring-purple-400 transition duration-300 ease-in-out`;

  const buttonClass = `px-6 py-3 rounded-full font-semibold text-white transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500`;

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-purple-50 text-gray-800'} py-12 px-4 sm:px-6 lg:px-8`}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className={`max-w-3xl mx-auto ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} rounded-2xl overflow-hidden shadow-2xl`}
      >
        <div className="relative overflow-hidden p-8">
          {/* Mesh Gradient Background */}
          <div
            className="absolute inset-0 opacity-30"
            style={{
              background: `
                radial-gradient(circle at 0% 0%, #8b5cf6 0%, transparent 50%),
                radial-gradient(circle at 100% 100%, #ec4899 0%, transparent 50%),
                radial-gradient(circle at 50% 50%, #6366f1 0%, transparent 50%)
              `,
              filter: 'blur(100px)',
            }}
          />

          <div className="relative z-10">
            <motion.h1
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2, duration: 0.5 }}
              className="text-4xl font-extrabold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-500"
            >
              PPI Calculator
            </motion.h1>

            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.3, duration: 0.5 }}
              className="mb-6"
            >
              <label className="block mb-2 font-medium">Screen Resolution (pixels):</label>
              <div className="flex space-x-4">
                <input
                  type="number"
                  value={width}
                  onChange={(e) => setWidth(e.target.value)}
                  className={inputClass}
                  placeholder="Width"
                />
                <input
                  type="number"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                  className={inputClass}
                  placeholder="Height"
                />
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.4, duration: 0.5 }}
              className="mb-6"
            >
              <label className="block mb-2 font-medium">Diagonal Size (inches):</label>
              <input
                type="number"
                value={diagonalSize}
                onChange={(e) => setDiagonalSize(e.target.value)}
                className={inputClass}
                placeholder="e.g. 15.6"
              />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 0.5 }}
              className="flex justify-center"
            >
              <button
                onClick={calculatePPI}
                className={`${buttonClass} bg-gradient-to-r from-purple-500 to-pink-500`}
              >
                <Calculator className="inline-block mr-2" size={20} />
                Calculate PPI
              </button>
            </motion.div>

            {ppi !== null && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6, duration: 0.5 }}
                className={`mt-8 ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100'} opacity-50 p-6 rounded-lg text-center`}
              >
                <Monitor className="inline-block mb-2" size={40} />
                <h2 className="text-2xl font-semibold mb-2">Calculated PPI:</h2>
                <p className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-500">
                  {ppi}
                </p>
              </motion.div>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default PPICalculator;