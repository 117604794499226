import React from 'react';
import { motion } from 'framer-motion';

const Button = ({ onClick, children, className = '' }) => (
  <motion.button
    onClick={onClick}
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    className={`px-6 py-3 rounded-full font-semibold text-white transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 bg-gradient-to-r from-blue-500 to-purple-600 ${className}`}
  >
    {children}
  </motion.button>
);

export default Button;