import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Play, Pause, RotateCcw, Copy, Download } from 'lucide-react';

const ModernReactTimer = () => {
  const [times, setTimes] = useState([]);
  const [isRunning, setIsRunning] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [stats, setStats] = useState(null);
  const intervalRef = useRef(null);
  const displayTimeRef = useRef(null);

  const handleKeyPress = useCallback((event) => {
    if (event.code === 'Space') {
      event.preventDefault();
      if (!isRunning) {
        const start = performance.now();
        setIsRunning(true);
        setStartTime(start);
        setCurrentTime(0);
        intervalRef.current = setInterval(() => {
          const now = performance.now();
          setCurrentTime(now - start);
        }, 10);
      } else {
        const end = performance.now();
        const duration = (end - startTime) / 1000;
        setTimes(prevTimes => [...prevTimes, { start: new Date(startTime), end: new Date(end), duration }]);
        setIsRunning(false);
        clearInterval(intervalRef.current);
      }
    }
  }, [isRunning, startTime]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    if (times.length > 0) {
      const durations = times.map(t => t.duration);
      const sortedDurations = [...durations].sort((a, b) => a - b);
      setStats({
        max: Math.max(...durations),
        min: Math.min(...durations),
        median: sortedDurations[Math.floor((durations.length - 1) / 2)],
        average: durations.reduce((a, b) => a + b, 0) / durations.length
      });
    }
  }, [times]);

  useEffect(() => {
    if (displayTimeRef.current) {
      displayTimeRef.current.textContent = (currentTime / 1000).toFixed(2) + 's';
    }
  }, [currentTime]);

  const resetTimer = () => {
    setTimes([]);
    setIsRunning(false);
    setStartTime(null);
    setCurrentTime(0);
    setStats(null);
    if (intervalRef.current) clearInterval(intervalRef.current);
  };

  const formatTime = (date) => {
    return date.toISOString().split('.')[0] + 'Z';
  };

  const copyData = () => {
    const data = times.map((t, i) => `Round ${i + 1}: ${t.duration.toFixed(2)}s (${formatTime(t.start)} - ${formatTime(t.end)})`).join('\n');
    navigator.clipboard.writeText(data + '\n\n' + JSON.stringify(stats, null, 2));
  };

  const downloadData = () => {
    const data = times.map((t, i) => `Round ${i + 1},${t.duration.toFixed(2)},${formatTime(t.start)},${formatTime(t.end)}`).join('\n');
    const statsData = `\n\nStats\nMax,${stats?.max.toFixed(2)}\nMin,${stats?.min.toFixed(2)}\nMedian,${stats?.median.toFixed(2)}\nAverage,${stats?.average.toFixed(2)}`;
    const blob = new Blob([`Round,Duration,Start,End\n${data}${statsData}`], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'timer_data.csv';
    a.click();
    URL.revokeObjectURL(url);
  };

  const StatBadge = ({ type, value }) => (
    <span className={`absolute top-0 right-0 -mt-2 -mr-2 px-1 text-xs font-semibold rounded ${
      type === 'max' ? 'bg-red-500' : type === 'min' ? 'bg-green-500' : 'bg-blue-500'
    } text-white`}>
      {type.slice(0, 3).toUpperCase()}
    </span>
  );

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-cyan-400 via-blue-500 to-purple-600">
      <div className="p-8 bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-3xl shadow-xl border border-white border-opacity-20 w-full max-w-4xl">
        <h1 className="text-4xl font-bold text-white mb-6 text-center">计时器</h1>
        <div className="text-center mb-6">
          <p className="text-white text-4xl mb-2">
            {isRunning ? (
              <span ref={displayTimeRef}>0.00s</span>
            ) : (
              "按下空格键开始/停止计时"
            )}
          </p>
        </div>
        <div className="flex justify-center space-x-4 mb-6">
          <button
            onClick={() => handleKeyPress({ code: 'Space', preventDefault: () => {} })}
            className="px-6 py-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition text-lg"
          >
            {isRunning ? <Pause size={24} /> : <Play size={24} />}
          </button>
          <button
            onClick={resetTimer}
            className="px-6 py-3 bg-red-600 text-white rounded-full hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition text-lg"
          >
            <RotateCcw size={24} />
          </button>
        </div>
        {times.length > 0 && (
          <div className="bg-white bg-opacity-20 rounded-xl p-6 mb-6">
            <h2 className="text-2xl font-semibold text-white mb-4">计时记录</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {times.map((time, index) => (
                <div key={index} className="relative bg-white bg-opacity-30 rounded-lg p-4">
                  <span className="text-white">第 {index + 1} 次:</span>
                  <span 
                    className="block text-2xl font-bold text-white"
                    title={`开始: ${formatTime(time.start)}\n结束: ${formatTime(time.end)}`}
                  >
                    {time.duration.toFixed(2)}s
                    {stats && time.duration === stats.max && <StatBadge type="max" />}
                    {stats && time.duration === stats.min && <StatBadge type="min" />}
                    {stats && time.duration === stats.median && <StatBadge type="median" />}
                  </span>
                </div>
              ))}
            </div>
            {stats && (
              <div className="mt-6 text-white text-lg">
                <p>平均值: {stats.average.toFixed(2)}s</p>
              </div>
            )}
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={copyData}
                className="px-4 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition flex items-center"
              >
                <Copy size={20} className="mr-2" /> 复制数据
              </button>
              <button
                onClick={downloadData}
                className="px-4 py-2 bg-green-600 text-white rounded-full hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition flex items-center"
              >
                <Download size={20} className="mr-2" /> 下载数据
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModernReactTimer;