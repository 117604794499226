import React, { useState, useEffect, useContext, useMemo } from 'react';
import { motion } from 'framer-motion';
import { ThemeContext } from '../contexts/ThemeContext';
import { ChevronDown, Copy, Check } from 'lucide-react';

const BaseConverter = () => {
  const { theme } = useContext(ThemeContext);
  const [inputBase, setInputBase] = useState(10);
  const [inputValue, setInputValue] = useState('');
  const [convertedValues, setConvertedValues] = useState({
    binary: '',
    octal: '',
    decimal: '',
    hexadecimal: ''
  });
  const [error, setError] = useState('');
  const [copiedValue, setCopiedValue] = useState(null);

  // Define base options with short labels
  const baseOptions = useMemo(() => [
    { value: 2, label: 'BIN', fullName: 'Binary' },
    { value: 8, label: 'OCT', fullName: 'Octal' },
    { value: 10, label: 'DEC', fullName: 'Decimal' },
    { value: 16, label: 'HEX', fullName: 'Hexadecimal' }
  ], []);

  // Validate input for given base
  const isValidForBase = (value, base) => {
    const regex = {
      2: /^-?[01]+(\.[01]+)?$/,
      8: /^-?[0-7]+(\.[0-7]+)?$/,
      10: /^-?[0-9]+(\.[0-9]+)?$/,
      16: /^-?[0-9A-Fa-f]+(\.[0-9A-Fa-f]+)?$/
    };
    return regex[base].test(value);
  };

  // Convert number between bases
  const convertBase = (value, fromBase, toBase) => {
    const isNegative = value.startsWith('-');
    value = isNegative ? value.slice(1) : value;
    const parts = value.split('.');
    let intPart = parseInt(parts[0], fromBase);
    let result = intPart.toString(toBase);

    if (parts.length > 1) {
      let fracPart = 0;
      const fracDigits = parts[1].split('');
      for (let i = 0; i < fracDigits.length; i++) {
        fracPart += parseInt(fracDigits[i], fromBase) / Math.pow(fromBase, i + 1);
      }

      if (fracPart > 0) {
        result += '.';
        for (let i = 0; i < 8; i++) {  // limit to 8 decimal places
          fracPart *= toBase;
          const digit = Math.floor(fracPart);
          result += digit.toString(toBase);
          fracPart -= digit;
          if (fracPart === 0) break;
        }
      }
    }

    return (isNegative ? '-' : '') + result.toUpperCase();
  };

  // Effect to handle input changes and perform conversions
  useEffect(() => {
    if (inputValue === '') {
      setConvertedValues({ binary: '', octal: '', decimal: '', hexadecimal: '' });
      setError('');
      return;
    }

    if (!isValidForBase(inputValue, inputBase)) {
      setError(`Invalid input for base ${inputBase}`);
      return;
    }

    setError('');
    setConvertedValues({
      binary: convertBase(inputValue, inputBase, 2),
      octal: convertBase(inputValue, inputBase, 8),
      decimal: convertBase(inputValue, inputBase, 10),
      hexadecimal: convertBase(inputValue, inputBase, 16)
    });
  }, [inputValue, inputBase]);

  // Copy to clipboard function
  const copyToClipboard = (text, base) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedValue(base);
      setTimeout(() => setCopiedValue(null), 2000);
    });
  };

  // Memoized class strings
  const containerClass = useMemo(() => `min-h-screen ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-blue-50 text-gray-800'} py-12 px-4 sm:px-6 lg:px-8`, [theme]);
  const cardClass = useMemo(() => `max-w-3xl mx-auto ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} rounded-2xl overflow-hidden shadow-2xl`, [theme]);
  const inputContainerClass = useMemo(() => `flex rounded-lg overflow-hidden ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100'
    }`, [theme]);
  const inputClass = useMemo(() => `w-full h-12 px-4 bg-opacity-50 backdrop-filter backdrop-blur-lg ${theme === 'dark' ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'
    } focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300 ease-in-out`, [theme]);
  const selectClass = useMemo(() => `appearance-none cursor-pointer h-12 px-4 pr-8 ${theme === 'dark' ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'
    } focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300 ease-in-out`, [theme]);
  const selectContainerClass = useMemo(() => `relative ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100'
    }`, [theme]);

  return (
    <div className={containerClass}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className={cardClass}
      >
        <div className="relative overflow-hidden p-8">
          {/* Mesh Gradient Background */}
          <div
            className="absolute inset-0 opacity-30"
            style={{
              background: `
                radial-gradient(circle at 0% 0%, #3b82f6 0%, transparent 50%),
                radial-gradient(circle at 100% 100%, #10b981 0%, transparent 50%),
                radial-gradient(circle at 50% 50%, #8b5cf6 0%, transparent 50%)
              `,
              filter: 'blur(100px)',
            }}
          />

          <div className="relative z-10">
            <motion.h1
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2, duration: 0.5 }}
              className="text-4xl font-extrabold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-green-500"
            >
              Base Converter
            </motion.h1>

            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.3, duration: 0.5 }}
              className="mb-6"
            >
              <div className={inputContainerClass}>
                <div className={selectContainerClass}>
                  <select
                    value={inputBase}
                    onChange={(e) => setInputBase(Number(e.target.value))}
                    className={selectClass}
                  >
                    {baseOptions.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" size={16} />
                </div>
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="Enter a number"
                  className={inputClass}
                />
              </div>
            </motion.div>

            {error && (
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="text-red-500 mt-4 text-center"
              >
                {error}
              </motion.p>
            )}

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 0.5 }}
              className="mt-8"
            >
              <h2 className="text-2xl font-semibold mb-4">Converted Values:</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {Object.entries(convertedValues).map(([base, value]) => (
                  <div key={base} className={`p-4 rounded-xl ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100'} min-h-[80px] flex flex-col justify-center backdrop-filter backdrop-blur-lg bg-opacity-80 shadow-md relative group`}>
                    <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
                      {baseOptions.find(option => option.fullName.toLowerCase() === base)?.label || base.charAt(0).toUpperCase() + base.slice(1)}
                    </p>
                    <p className="mt-1 text-lg font-semibold break-all">{value || '-'}</p>
                    <button
                      onClick={() => copyToClipboard(value, base)}
                      className={`absolute top-2 right-2 p-1 rounded-full ${theme === 'dark' ? 'bg-gray-600 hover:bg-gray-500' : 'bg-gray-200 hover:bg-gray-300'} opacity-0 group-hover:opacity-100 transition-opacity duration-200`}
                    >
                      {copiedValue === base ? <Check size={16} className="text-green-500" /> : <Copy size={16} />}
                    </button>
                  </div>
                ))}
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default BaseConverter;