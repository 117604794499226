import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../contexts/ThemeContext';
import { Mail, Rss, Twitter, Network } from 'lucide-react';

const Footer = () => {
    const { t } = useTranslation();
    const { theme } = useContext(ThemeContext);

    const currentYear = new Date().getFullYear();

    return (
        <footer className={`${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-teal-50 text-gray-800'} py-8`}>
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                    <div>
                        <h3 className="text-lg font-semibold mb-4">{t('aboutUs')}</h3>
                        <p className="text-sm">{t('footerAboutText')}</p>
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold mb-4">{t('quickLinks')}</h3>
                        <ul className="space-y-2">
                            <li><Link to="/privacy-policy" className="text-sm hover:underline">{t('privacyPolicy')}</Link></li>
                            <li><Link to="/terms-of-service" className="text-sm hover:underline">{t('termsOfService')}</Link></li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold mb-4">{t('contact')}</h3>
                        <ul className="space-y-2">
                            <li className="flex items-center">
                                <Mail size={16} className="mr-2" />
                                <Link to="mailto:contact@example.com" className="text-sm hover:underline">contact@example.com</Link>
                            </li>
                            <li className="flex items-center">
                                <Twitter size={16} className="mr-2" />
                                <Link to="https://x.com/buildnsell" className="text-sm hover:underline">buildnsell</Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold mb-4">{t('stayConnected')}</h3>
                        <ul className="space-y-2">
                            <li className="flex items-center">
                                <Rss size={16} className="mr-2" />
                                <Link to="/rss.xml" className="text-sm hover:underline">{t('subscribeRSS')}</Link>
                            </li>
                            <li className="flex items-center">
                                <Network size={16} className="mr-2" />
                                <Link to="/sitemap" className="text-sm hover:underline">{t('siteMap')}</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="mt-8 pt-8 border-t border-gray-700">
                    <p className="text-sm text-center">
                        © {currentYear} {t('companyName')}. {t('allRightsReserved')}
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;