const regions = [
    {
      "officialName_en": "People's Republic of China",
      "abbreviation_en": "PRC",
      "officialName_cn": "中华人民共和国",
      "abbreviation_cn": "中国",
      "officialLanguage": "汉语",
      "officialScript": "简体中文",
      "localeCode": "zh_CN",
      "timezone": "UTC+8",
      "currencyName": "人民币",
      "currencyCode": "CNY",
      "currencySymbol": "¥",
      "continent": "亚洲",
      "regionCode": "+86",
      "emoji": "🇨🇳",
      "ccTLD": ".cn"
    },
    {
      "officialName_en": "United States of America",
      "abbreviation_en": "USA",
      "officialName_cn": "美利坚合众国",
      "abbreviation_cn": "美国",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_US",
      "timezone": "UTC-4 to UTC-10",
      "currencyName": "美元",
      "currencyCode": "USD",
      "currencySymbol": "$",
      "continent": "北美洲",
      "regionCode": "+1",
      "emoji": "🇺🇸",
      "ccTLD": ".us"
    },
    {
      "officialName_en": "United Kingdom",
      "abbreviation_en": "UK",
      "officialName_cn": "大不列颠及北爱尔兰联合王国",
      "abbreviation_cn": "英国",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_GB",
      "timezone": "UTC+0",
      "currencyName": "英镑",
      "currencyCode": "GBP",
      "currencySymbol": "£",
      "continent": "欧洲",
      "regionCode": "+44",
      "emoji": "🇬🇧",
      "ccTLD": ".uk"
    },
    {
      "officialName_en": "Japan",
      "abbreviation_en": "JPN",
      "officialName_cn": "日本国",
      "abbreviation_cn": "日本",
      "officialLanguage": "日语",
      "officialScript": "日文",
      "localeCode": "ja_JP",
      "timezone": "UTC+9",
      "currencyName": "日元",
      "currencyCode": "JPY",
      "currencySymbol": "¥",
      "continent": "亚洲",
      "regionCode": "+81",
      "emoji": "🇯🇵",
      "ccTLD": ".jp"
    },
    {
      "officialName_en": "Germany",
      "abbreviation_en": "DEU",
      "officialName_cn": "德意志联邦共和国",
      "abbreviation_cn": "德国",
      "officialLanguage": "德语",
      "officialScript": "拉丁字母",
      "localeCode": "de_DE",
      "timezone": "UTC+1",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+49",
      "emoji": "🇩🇪",
      "ccTLD": ".de"
    },
    {
      "officialName_en": "France",
      "abbreviation_en": "FRA",
      "officialName_cn": "法兰西共和国",
      "abbreviation_cn": "法国",
      "officialLanguage": "法语",
      "officialScript": "拉丁字母",
      "localeCode": "fr_FR",
      "timezone": "UTC+1",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+33",
      "emoji": "🇫🇷",
      "ccTLD": ".fr"
    },
    {
      "officialName_en": "Canada",
      "abbreviation_en": "CAN",
      "officialName_cn": "加拿大",
      "abbreviation_cn": "加拿大",
      "officialLanguage": "英语、法语",
      "officialScript": "拉丁字母",
      "localeCode": "en_CA/fr_CA",
      "timezone": "UTC-3.5 to UTC-8",
      "currencyName": "加拿大元",
      "currencyCode": "CAD",
      "currencySymbol": "$",
      "continent": "北美洲",
      "regionCode": "+1",
      "emoji": "🇨🇦",
      "ccTLD": ".ca"
    },
    {
      "officialName_en": "Australia",
      "abbreviation_en": "AUS",
      "officialName_cn": "澳大利亚联邦",
      "abbreviation_cn": "澳大利亚",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_AU",
      "timezone": "UTC+8 to UTC+10.5",
      "currencyName": "澳大利亚元",
      "currencyCode": "AUD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+61",
      "emoji": "🇦🇺",
      "ccTLD": ".au"
    },
    {
      "officialName_en": "Brazil",
      "abbreviation_en": "BRA",
      "officialName_cn": "巴西联邦共和国",
      "abbreviation_cn": "巴西",
      "officialLanguage": "葡萄牙语",
      "officialScript": "拉丁字母",
      "localeCode": "pt_BR",
      "timezone": "UTC-2 to UTC-5",
      "currencyName": "巴西雷亚尔",
      "currencyCode": "BRL",
      "currencySymbol": "R$",
      "continent": "南美洲",
      "regionCode": "+55",
      "emoji": "🇧🇷",
      "ccTLD": ".br"
    },
    {
      "officialName_en": "India",
      "abbreviation_en": "IND",
      "officialName_cn": "印度共和国",
      "abbreviation_cn": "印度",
      "officialLanguage": "印地语、英语",
      "officialScript": "天城文、拉丁字母",
      "localeCode": "hi_IN/en_IN",
      "timezone": "UTC+5:30",
      "currencyName": "印度卢比",
      "currencyCode": "INR",
      "currencySymbol": "₹",
      "continent": "亚洲",
      "regionCode": "+91",
      "emoji": "🇮🇳",
      "ccTLD": ".in"
    },
    {
      "officialName_en": "Russian Federation",
      "abbreviation_en": "RUS",
      "officialName_cn": "俄罗斯联邦",
      "abbreviation_cn": "俄罗斯",
      "officialLanguage": "俄语",
      "officialScript": "西里尔字母",
      "localeCode": "ru_RU",
      "timezone": "UTC+2 to UTC+12",
      "currencyName": "俄罗斯卢布",
      "currencyCode": "RUB",
      "currencySymbol": "₽",
      "continent": "欧亚",
      "regionCode": "+7",
      "emoji": "🇷🇺",
      "ccTLD": ".ru"
    },
    {
      "officialName_en": "South Africa",
      "abbreviation_en": "ZAF",
      "officialName_cn": "南非共和国",
      "abbreviation_cn": "南非",
      "officialLanguage": "11种officialLanguage",
      "officialScript": "拉丁字母",
      "localeCode": "en_ZA",
      "timezone": "UTC+2",
      "currencyName": "南非兰特",
      "currencyCode": "ZAR",
      "currencySymbol": "R",
      "continent": "非洲",
      "regionCode": "+27",
      "emoji": "🇿🇦",
      "ccTLD": ".za"
    },
    {
      "officialName_en": "Mexico",
      "abbreviation_en": "MEX",
      "officialName_cn": "墨西哥合众国",
      "abbreviation_cn": "墨西哥",
      "officialLanguage": "西班牙语",
      "officialScript": "拉丁字母",
      "localeCode": "es_MX",
      "timezone": "UTC-6 to UTC-8",
      "currencyName": "墨西哥比索",
      "currencyCode": "MXN",
      "currencySymbol": "$",
      "continent": "北美洲",
      "regionCode": "+52",
      "emoji": "🇲🇽",
      "ccTLD": ".mx"
    },
    {
      "officialName_en": "Indonesia",
      "abbreviation_en": "IDN",
      "officialName_cn": "印度尼西亚共和国",
      "abbreviation_cn": "印度尼西亚",
      "officialLanguage": "印度尼西亚语",
      "officialScript": "拉丁字母",
      "localeCode": "id_ID",
      "timezone": "UTC+7 to UTC+9",
      "currencyName": "印度尼西亚盾",
      "currencyCode": "IDR",
      "currencySymbol": "Rp",
      "continent": "亚洲",
      "regionCode": "+62",
      "emoji": "🇮🇩",
      "ccTLD": ".id"
    },
    {
      "officialName_en": "Netherlands",
      "abbreviation_en": "NLD",
      "officialName_cn": "荷兰王国",
      "abbreviation_cn": "荷兰",
      "officialLanguage": "荷兰语",
      "officialScript": "拉丁字母",
      "localeCode": "nl_NL",
      "timezone": "UTC+1",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+31",
      "emoji": "🇳🇱",
      "ccTLD": ".nl"
    },
    {
      "officialName_en": "Saudi Arabia",
      "abbreviation_en": "SAU",
      "officialName_cn": "沙特阿拉伯王国",
      "abbreviation_cn": "沙特阿拉伯",
      "officialLanguage": "阿拉伯语",
      "officialScript": "阿拉伯字母",
      "localeCode": "ar_SA",
      "timezone": "UTC+3",
      "currencyName": "沙特里亚尔",
      "currencyCode": "SAR",
      "currencySymbol": "﷼",
      "continent": "亚洲",
      "regionCode": "+966",
      "emoji": "🇸🇦",
      "ccTLD": ".sa"
    },
    {
      "officialName_en": "Turkey",
      "abbreviation_en": "TUR",
      "officialName_cn": "土耳其共和国",
      "abbreviation_cn": "土耳其",
      "officialLanguage": "土耳其语",
      "officialScript": "拉丁字母",
      "localeCode": "tr_TR",
      "timezone": "UTC+3",
      "currencyName": "土耳其里拉",
      "currencyCode": "TRY",
      "currencySymbol": "₺",
      "continent": "欧亚",
      "regionCode": "+90",
      "emoji": "🇹🇷",
      "ccTLD": ".tr"
    },
    {
      "officialName_en": "Switzerland",
      "abbreviation_en": "CHE",
      "officialName_cn": "瑞士联邦",
      "abbreviation_cn": "瑞士",
      "officialLanguage": "德语、法语、意大利语、罗曼什语",
      "officialScript": "拉丁字母",
      "localeCode": "de_CH/fr_CH/it_CH",
      "timezone": "UTC+1",
      "currencyName": "瑞士法郎",
      "currencyCode": "CHF",
      "currencySymbol": "Fr.",
      "continent": "欧洲",
      "regionCode": "+41",
      "emoji": "🇨🇭",
      "ccTLD": ".ch"
    },
    {
      "officialName_en": "Argentina",
      "abbreviation_en": "ARG",
      "officialName_cn": "阿根廷共和国",
      "abbreviation_cn": "阿根廷",
      "officialLanguage": "西班牙语",
      "officialScript": "拉丁字母",
      "localeCode": "es_AR",
      "timezone": "UTC-3",
      "currencyName": "阿根廷比索",
      "currencyCode": "ARS",
      "currencySymbol": "$",
      "continent": "南美洲",
      "regionCode": "+54",
      "emoji": "🇦🇷",
      "ccTLD": ".ar"
    },
    {
      "officialName_en": "Sweden",
      "abbreviation_en": "SWE",
      "officialName_cn": "瑞典王国",
      "abbreviation_cn": "瑞典",
      "officialLanguage": "瑞典语",
      "officialScript": "拉丁字母",
      "localeCode": "sv_SE",
      "timezone": "UTC+1",
      "currencyName": "瑞典克朗",
      "currencyCode": "SEK",
      "currencySymbol": "kr",
      "continent": "欧洲",
      "regionCode": "+46",
      "emoji": "🇸🇪",
      "ccTLD": ".se"
    },
    {
      "officialName_en": "Spain",
      "abbreviation_en": "ESP",
      "officialName_cn": "西班牙王国",
      "abbreviation_cn": "西班牙",
      "officialLanguage": "西班牙语",
      "officialScript": "拉丁字母",
      "localeCode": "es_ES",
      "timezone": "UTC+1",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+34",
      "emoji": "🇪🇸",
      "ccTLD": ".es"
    },
    {
      "officialName_en": "Poland",
      "abbreviation_en": "POL",
      "officialName_cn": "波兰共和国",
      "abbreviation_cn": "波兰",
      "officialLanguage": "波兰语",
      "officialScript": "拉丁字母",
      "localeCode": "pl_PL",
      "timezone": "UTC+1",
      "currencyName": "兹罗提",
      "currencyCode": "PLN",
      "currencySymbol": "zł",
      "continent": "欧洲",
      "regionCode": "+48",
      "emoji": "🇵🇱",
      "ccTLD": ".pl"
    },
    {
      "officialName_en": "Italy",
      "abbreviation_en": "ITA",
      "officialName_cn": "意大利共和国",
      "abbreviation_cn": "意大利",
      "officialLanguage": "意大利语",
      "officialScript": "拉丁字母",
      "localeCode": "it_IT",
      "timezone": "UTC+1",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+39",
      "emoji": "🇮🇹",
      "ccTLD": ".it"
    },
    {
      "officialName_en": "Egypt",
      "abbreviation_en": "EGY",
      "officialName_cn": "阿拉伯埃及共和国",
      "abbreviation_cn": "埃及",
      "officialLanguage": "阿拉伯语",
      "officialScript": "阿拉伯字母",
      "localeCode": "ar_EG",
      "timezone": "UTC+2",
      "currencyName": "埃及镑",
      "currencyCode": "EGP",
      "currencySymbol": "E£",
      "continent": "非洲",
      "regionCode": "+20",
      "emoji": "🇪🇬",
      "ccTLD": ".eg"
    },
    {
      "officialName_en": "South Korea",
      "abbreviation_en": "KOR",
      "officialName_cn": "大韩民国",
      "abbreviation_cn": "韩国",
      "officialLanguage": "韩语",
      "officialScript": "谚文",
      "localeCode": "ko_KR",
      "timezone": "UTC+9",
      "currencyName": "韩元",
      "currencyCode": "KRW",
      "currencySymbol": "₩",
      "continent": "亚洲",
      "regionCode": "+82",
      "emoji": "🇰🇷",
      "ccTLD": ".kr"
    },
    {
      "officialName_en": "Ukraine",
      "abbreviation_en": "UKR",
      "officialName_cn": "乌克兰",
      "abbreviation_cn": "乌克兰",
      "officialLanguage": "乌克兰语",
      "officialScript": "西里尔字母",
      "localeCode": "uk_UA",
      "timezone": "UTC+2",
      "currencyName": "格里夫纳",
      "currencyCode": "UAH",
      "currencySymbol": "₴",
      "continent": "欧洲",
      "regionCode": "+380",
      "emoji": "🇺🇦",
      "ccTLD": ".ua"
    },
    {
      "officialName_en": "Thailand",
      "abbreviation_en": "THA",
      "officialName_cn": "泰王国",
      "abbreviation_cn": "泰国",
      "officialLanguage": "泰语",
      "officialScript": "泰文",
      "localeCode": "th_TH",
      "timezone": "UTC+7",
      "currencyName": "泰铢",
      "currencyCode": "THB",
      "currencySymbol": "฿",
      "continent": "亚洲",
      "regionCode": "+66",
      "emoji": "🇹🇭",
      "ccTLD": ".th"
    },
    {
      "officialName_en": "Nigeria",
      "abbreviation_en": "NGA",
      "officialName_cn": "尼日利亚联邦共和国",
      "abbreviation_cn": "尼日利亚",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_NG",
      "timezone": "UTC+1",
      "currencyName": "尼日利亚奈拉",
      "currencyCode": "NGN",
      "currencySymbol": "₦",
      "continent": "非洲",
      "regionCode": "+234",
      "emoji": "🇳🇬",
      "ccTLD": ".ng"
    },
    {
      "officialName_en": "New Zealand",
      "abbreviation_en": "NZL",
      "officialName_cn": "新西兰",
      "abbreviation_cn": "新西兰",
      "officialLanguage": "英语、毛利语",
      "officialScript": "拉丁字母",
      "localeCode": "en_NZ/mi_NZ",
      "timezone": "UTC+12",
      "currencyName": "新西兰元",
      "currencyCode": "NZD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+64",
      "emoji": "🇳🇿",
      "ccTLD": ".nz"
    },
    {
      "officialName_en": "Singapore",
      "abbreviation_en": "SGP",
      "officialName_cn": "新加坡共和国",
      "abbreviation_cn": "新加坡",
      "officialLanguage": "英语、马来语、华语、泰米尔语",
      "officialScript": "拉丁字母、汉字、泰米尔文",
      "localeCode": "en_SG/zh_SG/ms_SG/ta_SG",
      "timezone": "UTC+8",
      "currencyName": "新加坡元",
      "currencyCode": "SGD",
      "currencySymbol": "$",
      "continent": "亚洲",
      "regionCode": "+65",
      "emoji": "🇸🇬",
      "ccTLD": ".sg"
    },
    {
      "officialName_en": "Malaysia",
      "abbreviation_en": "MYS",
      "officialName_cn": "马来西亚",
      "abbreviation_cn": "马来西亚",
      "officialLanguage": "马来语",
      "officialScript": "拉丁字母",
      "localeCode": "ms_MY",
      "timezone": "UTC+8",
      "currencyName": "马来西亚林吉特",
      "currencyCode": "MYR",
      "currencySymbol": "RM",
      "continent": "亚洲",
      "regionCode": "+60",
      "emoji": "🇲🇾",
      "ccTLD": ".my"
    },
    {
      "officialName_en": "Portugal",
      "abbreviation_en": "PRT",
      "officialName_cn": "葡萄牙共和国",
      "abbreviation_cn": "葡萄牙",
      "officialLanguage": "葡萄牙语",
      "officialScript": "拉丁字母",
      "localeCode": "pt_PT",
      "timezone": "UTC+0",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+351",
      "emoji": "🇵🇹",
      "ccTLD": ".pt"
    },
    {
      "officialName_en": "Israel",
      "abbreviation_en": "ISR",
      "officialName_cn": "以色列国",
      "abbreviation_cn": "以色列",
      "officialLanguage": "希伯来语、阿拉伯语",
      "officialScript": "希伯来字母、阿拉伯字母",
      "localeCode": "he_IL/ar_IL",
      "timezone": "UTC+2",
      "currencyName": "新谢克尔",
      "currencyCode": "ILS",
      "currencySymbol": "₪",
      "continent": "亚洲",
      "regionCode": "+972",
      "emoji": "🇮🇱",
      "ccTLD": ".il"
    },
    {
      "officialName_en": "Philippines",
      "abbreviation_en": "PHL",
      "officialName_cn": "菲律宾共和国",
      "abbreviation_cn": "菲律宾",
      "officialLanguage": "菲律宾语、英语",
      "officialScript": "拉丁字母",
      "localeCode": "fil_PH/en_PH",
      "timezone": "UTC+8",
      "currencyName": "菲律宾比索",
      "currencyCode": "PHP",
      "currencySymbol": "₱",
      "continent": "亚洲",
      "regionCode": "+63",
      "emoji": "🇵🇭",
      "ccTLD": ".ph"
    },
    {
      "officialName_en": "Pakistan",
      "abbreviation_en": "PAK",
      "officialName_cn": "巴基斯坦伊斯兰共和国",
      "abbreviation_cn": "巴基斯坦",
      "officialLanguage": "乌尔都语、英语",
      "officialScript": "阿拉伯-波斯字母、拉丁字母",
      "localeCode": "ur_PK/en_PK",
      "timezone": "UTC+5",
      "currencyName": "巴基斯坦卢比",
      "currencyCode": "PKR",
      "currencySymbol": "₨",
      "continent": "亚洲",
      "regionCode": "+92",
      "emoji": "🇵🇰",
      "ccTLD": ".pk"
    },
    {
      "officialName_en": "Vietnam",
      "abbreviation_en": "VNM",
      "officialName_cn": "越南社会主义共和国",
      "abbreviation_cn": "越南",
      "officialLanguage": "越南语",
      "officialScript": "拉丁字母",
      "localeCode": "vi_VN",
      "timezone": "UTC+7",
      "currencyName": "越南盾",
      "currencyCode": "VND",
      "currencySymbol": "₫",
      "continent": "亚洲",
      "regionCode": "+84",
      "emoji": "🇻🇳",
      "ccTLD": ".vn"
    },
    {
      "officialName_en": "Chile",
      "abbreviation_en": "CHL",
      "officialName_cn": "智利共和国",
      "abbreviation_cn": "智利",
      "officialLanguage": "西班牙语",
      "officialScript": "拉丁字母",
      "localeCode": "es_CL",
      "timezone": "UTC-4",
      "currencyName": "智利比索",
      "currencyCode": "CLP",
      "currencySymbol": "$",
      "continent": "南美洲",
      "regionCode": "+56",
      "emoji": "🇨🇱",
      "ccTLD": ".cl"
    },
    {
      "officialName_en": "Bangladesh",
      "abbreviation_en": "BGD",
      "officialName_cn": "孟加拉人民共和国",
      "abbreviation_cn": "孟加拉国",
      "officialLanguage": "孟加拉语",
      "officialScript": "孟加拉文",
      "localeCode": "bn_BD",
      "timezone": "UTC+6",
      "currencyName": "孟加拉塔卡",
      "currencyCode": "BDT",
      "currencySymbol": "৳",
      "continent": "亚洲",
      "regionCode": "+880",
      "emoji": "🇧🇩",
      "ccTLD": ".bd"
    },
    {
      "officialName_en": "Greece",
      "abbreviation_en": "GRC",
      "officialName_cn": "希腊共和国",
      "abbreviation_cn": "希腊",
      "officialLanguage": "希腊语",
      "officialScript": "希腊字母",
      "localeCode": "el_GR",
      "timezone": "UTC+2",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+30",
      "emoji": "🇬🇷",
      "ccTLD": ".gr"
    },
    {
      "officialName_en": "Denmark",
      "abbreviation_en": "DNK",
      "officialName_cn": "丹麦王国",
      "abbreviation_cn": "丹麦",
      "officialLanguage": "丹麦语",
      "officialScript": "拉丁字母",
      "localeCode": "da_DK",
      "timezone": "UTC+1",
      "currencyName": "丹麦克朗",
      "currencyCode": "DKK",
      "currencySymbol": "kr",
      "continent": "欧洲",
      "regionCode": "+45",
      "emoji": "🇩🇰",
      "ccTLD": ".dk"
    },
    {
      "officialName_en": "Finland",
      "abbreviation_en": "FIN",
      "officialName_cn": "芬兰共和国",
      "abbreviation_cn": "芬兰",
      "officialLanguage": "芬兰语、瑞典语",
      "officialScript": "拉丁字母",
      "localeCode": "fi_FI/sv_FI",
      "timezone": "UTC+2",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+358",
      "emoji": "🇫🇮",
      "ccTLD": ".fi"
    },
    {
      "officialName_en": "Ireland",
      "abbreviation_en": "IRL",
      "officialName_cn": "爱尔兰",
      "abbreviation_cn": "爱尔兰",
      "officialLanguage": "爱尔兰语、英语",
      "officialScript": "拉丁字母",
      "localeCode": "ga_IE/en_IE",
      "timezone": "UTC+0",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+353",
      "emoji": "🇮🇪",
      "ccTLD": ".ie"
    },
    {
      "officialName_en": "Norway",
      "abbreviation_en": "NOR",
      "officialName_cn": "挪威王国",
      "abbreviation_cn": "挪威",
      "officialLanguage": "挪威语",
      "officialScript": "拉丁字母",
      "localeCode": "nb_NO/nn_NO",
      "timezone": "UTC+1",
      "currencyName": "挪威克朗",
      "currencyCode": "NOK",
      "currencySymbol": "kr",
      "continent": "欧洲",
      "regionCode": "+47",
      "emoji": "🇳🇴",
      "ccTLD": ".no"
    },
    {
      "officialName_en": "Austria",
      "abbreviation_en": "AUT",
      "officialName_cn": "奥地利共和国",
      "abbreviation_cn": "奥地利",
      "officialLanguage": "德语",
      "officialScript": "拉丁字母",
      "localeCode": "de_AT",
      "timezone": "UTC+1",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+43",
      "emoji": "🇦🇹",
      "ccTLD": ".at"
    },
    {
      "officialName_en": "United Arab Emirates",
      "abbreviation_en": "ARE",
      "officialName_cn": "阿拉伯联合酋长国",
      "abbreviation_cn": "阿联酋",
      "officialLanguage": "阿拉伯语",
      "officialScript": "阿拉伯字母",
      "localeCode": "ar_AE",
      "timezone": "UTC+4",
      "currencyName": "阿联酋迪拉姆",
      "currencyCode": "AED",
      "currencySymbol": "د.إ",
      "continent": "亚洲",
      "regionCode": "+971",
      "emoji": "🇦🇪",
      "ccTLD": ".ae"
    },
    {
      "officialName_en": "Colombia",
      "abbreviation_en": "COL",
      "officialName_cn": "哥伦比亚共和国",
      "abbreviation_cn": "哥伦比亚",
      "officialLanguage": "西班牙语",
      "officialScript": "拉丁字母",
      "localeCode": "es_CO",
      "timezone": "UTC-5",
      "currencyName": "哥伦比亚比索",
      "currencyCode": "COP",
      "currencySymbol": "$",
      "continent": "南美洲",
      "regionCode": "+57",
      "emoji": "🇨🇴",
      "ccTLD": ".co"
    },
    {
      "officialName_en": "Czech Republic",
      "abbreviation_en": "CZE",
      "officialName_cn": "捷克共和国",
      "abbreviation_cn": "捷克",
      "officialLanguage": "捷克语",
      "officialScript": "拉丁字母",
      "localeCode": "cs_CZ",
      "timezone": "UTC+1",
      "currencyName": "捷克克朗",
      "currencyCode": "CZK",
      "currencySymbol": "Kč",
      "continent": "欧洲",
      "regionCode": "+420",
      "emoji": "🇨🇿",
      "ccTLD": ".cz"
    },
    {
      "officialName_en": "Romania",
      "abbreviation_en": "ROU",
      "officialName_cn": "罗马尼亚",
      "abbreviation_cn": "罗马尼亚",
      "officialLanguage": "罗马尼亚语",
      "officialScript": "拉丁字母",
      "localeCode": "ro_RO",
      "timezone": "UTC+2",
      "currencyName": "罗马尼亚列伊",
      "currencyCode": "RON",
      "currencySymbol": "lei",
      "continent": "欧洲",
      "regionCode": "+40",
      "emoji": "🇷🇴",
      "ccTLD": ".ro"
    },
    {
      "officialName_en": "Peru",
      "abbreviation_en": "PER",
      "officialName_cn": "秘鲁共和国",
      "abbreviation_cn": "秘鲁",
      "officialLanguage": "西班牙语、克丘亚语、艾马拉语",
      "officialScript": "拉丁字母",
      "localeCode": "es_PE/qu_PE/ay_PE",
      "timezone": "UTC-5",
      "currencyName": "秘鲁索尔",
      "currencyCode": "PEN",
      "currencySymbol": "S/",
      "continent": "南美洲",
      "regionCode": "+51",
      "emoji": "🇵🇪",
      "ccTLD": ".pe"
    },
    {
      "officialName_en": "Hungary",
      "abbreviation_en": "HUN",
      "officialName_cn": "匈牙利",
      "abbreviation_cn": "匈牙利",
      "officialLanguage": "匈牙利语",
      "officialScript": "拉丁字母",
      "localeCode": "hu_HU",
      "timezone": "UTC+1",
      "currencyName": "匈牙利福林",
      "currencyCode": "HUF",
      "currencySymbol": "Ft",
      "continent": "欧洲",
      "regionCode": "+36",
      "emoji": "🇭🇺",
      "ccTLD": ".hu"
    },
    {
      "officialName_en": "Belgium",
      "abbreviation_en": "BEL",
      "officialName_cn": "比利时王国",
      "abbreviation_cn": "比利时",
      "officialLanguage": "荷兰语、法语、德语",
      "officialScript": "拉丁字母",
      "localeCode": "nl_BE/fr_BE/de_BE",
      "timezone": "UTC+1",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+32",
      "emoji": "🇧🇪",
      "ccTLD": ".be"
    },
    {
      "officialName_en": "Kazakhstan",
      "abbreviation_en": "KAZ",
      "officialName_cn": "哈萨克斯坦共和国",
      "abbreviation_cn": "哈萨克斯坦",
      "officialLanguage": "哈萨克语、俄语",
      "officialScript": "西里尔字母",
      "localeCode": "kk_KZ/ru_KZ",
      "timezone": "UTC+5/+6",
      "currencyName": "坚戈",
      "currencyCode": "KZT",
      "currencySymbol": "₸",
      "continent": "亚洲",
      "regionCode": "+7",
      "emoji": "🇰🇿",
      "ccTLD": ".kz"
    },
    {
      "officialName_en": "Morocco",
      "abbreviation_en": "MAR",
      "officialName_cn": "摩洛哥王国",
      "abbreviation_cn": "摩洛哥",
      "officialLanguage": "阿拉伯语、柏柏尔语",
      "officialScript": "阿拉伯字母、提菲纳字母",
      "localeCode": "ar_MA/ber_MA",
      "timezone": "UTC+1",
      "currencyName": "摩洛哥迪拉姆",
      "currencyCode": "MAD",
      "currencySymbol": "د.م.",
      "continent": "非洲",
      "regionCode": "+212",
      "emoji": "🇲🇦",
      "ccTLD": ".ma"
    },
    {
      "officialName_en": "Ecuador",
      "abbreviation_en": "ECU",
      "officialName_cn": "厄瓜多尔共和国",
      "abbreviation_cn": "厄瓜多尔",
      "officialLanguage": "西班牙语",
      "officialScript": "拉丁字母",
      "localeCode": "es_EC",
      "timezone": "UTC-5",
      "currencyName": "美元",
      "currencyCode": "USD",
      "currencySymbol": "$",
      "continent": "南美洲",
      "regionCode": "+593",
      "emoji": "🇪🇨",
      "ccTLD": ".ec"
    },
    {
      "officialName_en": "Belarus",
      "abbreviation_en": "BLR",
      "officialName_cn": "白俄罗斯共和国",
      "abbreviation_cn": "白俄罗斯",
      "officialLanguage": "白俄罗斯语、俄语",
      "officialScript": "西里尔字母",
      "localeCode": "be_BY/ru_BY",
      "timezone": "UTC+3",
      "currencyName": "白俄罗斯卢布",
      "currencyCode": "BYN",
      "currencySymbol": "Br",
      "continent": "欧洲",
      "regionCode": "+375",
      "emoji": "🇧🇾",
      "ccTLD": ".by"
    },
    {
      "officialName_en": "Sri Lanka",
      "abbreviation_en": "LKA",
      "officialName_cn": "斯里兰卡民主社会主义共和国",
      "abbreviation_cn": "斯里兰卡",
      "officialLanguage": "僧伽罗语、泰米尔语",
      "officialScript": "僧伽罗文、泰米尔文",
      "localeCode": "si_LK/ta_LK",
      "timezone": "UTC+5:30",
      "currencyName": "斯里兰卡卢比",
      "currencyCode": "LKR",
      "currencySymbol": "Rs",
      "continent": "亚洲",
      "regionCode": "+94",
      "emoji": "🇱🇰",
      "ccTLD": ".lk"
    },
    {
      "officialName_en": "Bulgaria",
      "abbreviation_en": "BGR",
      "officialName_cn": "保加利亚共和国",
      "abbreviation_cn": "保加利亚",
      "officialLanguage": "保加利亚语",
      "officialScript": "西里尔字母",
      "localeCode": "bg_BG",
      "timezone": "UTC+2",
      "currencyName": "保加利亚列弗",
      "currencyCode": "BGN",
      "currencySymbol": "лв",
      "continent": "欧洲",
      "regionCode": "+359",
      "emoji": "🇧🇬",
      "ccTLD": ".bg"
    },
    {
      "officialName_en": "Dominican Republic",
      "abbreviation_en": "DOM",
      "officialName_cn": "多米尼加共和国",
      "abbreviation_cn": "多米尼加",
      "officialLanguage": "西班牙语",
      "officialScript": "拉丁字母",
      "localeCode": "es_DO",
      "timezone": "UTC-4",
      "currencyName": "多米尼加比索",
      "currencyCode": "DOP",
      "currencySymbol": "RD$",
      "continent": "北美洲",
      "regionCode": "+1-809/+1-829/+1-849",
      "emoji": "🇩🇴",
      "ccTLD": ".do"
    },
    {
      "officialName_en": "Azerbaijan",
      "abbreviation_en": "AZE",
      "officialName_cn": "阿塞拜疆共和国",
      "abbreviation_cn": "阿塞拜疆",
      "officialLanguage": "阿塞拜疆语",
      "officialScript": "拉丁字母",
      "localeCode": "az_AZ",
      "timezone": "UTC+4",
      "currencyName": "阿塞拜疆马纳特",
      "currencyCode": "AZN",
      "currencySymbol": "₼",
      "continent": "亚洲",
      "regionCode": "+994",
      "emoji": "🇦🇿",
      "ccTLD": ".az"
    },
    {
      "officialName_en": "Slovakia",
      "abbreviation_en": "SVK",
      "officialName_cn": "斯洛伐克共和国",
      "abbreviation_cn": "斯洛伐克",
      "officialLanguage": "斯洛伐克语",
      "officialScript": "拉丁字母",
      "localeCode": "sk_SK",
      "timezone": "UTC+1",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+421",
      "emoji": "🇸🇰",
      "ccTLD": ".sk"
    },
    {
      "officialName_en": "Croatia",
      "abbreviation_en": "HRV",
      "officialName_cn": "克罗地亚共和国",
      "abbreviation_cn": "克罗地亚",
      "officialLanguage": "克罗地亚语",
      "officialScript": "拉丁字母",
      "localeCode": "hr_HR",
      "timezone": "UTC+1",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+385",
      "emoji": "🇭🇷",
      "ccTLD": ".hr"
    },
    {
      "officialName_en": "Uruguay",
      "abbreviation_en": "URY",
      "officialName_cn": "乌拉圭东岸共和国",
      "abbreviation_cn": "乌拉圭",
      "officialLanguage": "西班牙语",
      "officialScript": "拉丁字母",
      "localeCode": "es_UY",
      "timezone": "UTC-3",
      "currencyName": "乌拉圭比索",
      "currencyCode": "UYU",
      "currencySymbol": "$",
      "continent": "南美洲",
      "regionCode": "+598",
      "emoji": "🇺🇾",
      "ccTLD": ".uy"
    },
    {
      "officialName_en": "Costa Rica",
      "abbreviation_en": "CRI",
      "officialName_cn": "哥斯达黎加共和国",
      "abbreviation_cn": "哥斯达黎加",
      "officialLanguage": "西班牙语",
      "officialScript": "拉丁字母",
      "localeCode": "es_CR",
      "timezone": "UTC-6",
      "currencyName": "哥斯达黎加科朗",
      "currencyCode": "CRC",
      "currencySymbol": "₡",
      "continent": "北美洲",
      "regionCode": "+506",
      "emoji": "🇨🇷",
      "ccTLD": ".cr"
    },
    {
      "officialName_en": "Panama",
      "abbreviation_en": "PAN",
      "officialName_cn": "巴拿马共和国",
      "abbreviation_cn": "巴拿马",
      "officialLanguage": "西班牙语",
      "officialScript": "拉丁字母",
      "localeCode": "es_PA",
      "timezone": "UTC-5",
      "currencyName": "巴拿马巴波亚",
      "currencyCode": "PAB",
      "currencySymbol": "B/.",
      "continent": "北美洲",
      "regionCode": "+507",
      "emoji": "🇵🇦",
      "ccTLD": ".pa"
    },
    {
      "officialName_en": "Kuwait",
      "abbreviation_en": "KWT",
      "officialName_cn": "科威特国",
      "abbreviation_cn": "科威特",
      "officialLanguage": "阿拉伯语",
      "officialScript": "阿拉伯字母",
      "localeCode": "ar_KW",
      "timezone": "UTC+3",
      "currencyName": "科威特第纳尔",
      "currencyCode": "KWD",
      "currencySymbol": "د.ك",
      "continent": "亚洲",
      "regionCode": "+965",
      "emoji": "🇰🇼",
      "ccTLD": ".kw"
    },
    {
      "officialName_en": "Slovenia",
      "abbreviation_en": "SVN",
      "officialName_cn": "斯洛文尼亚共和国",
      "abbreviation_cn": "斯洛文尼亚",
      "officialLanguage": "斯洛文尼亚语",
      "officialScript": "拉丁字母",
      "localeCode": "sl_SI",
      "timezone": "UTC+1",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+386",
      "emoji": "🇸🇮",
      "ccTLD": ".si"
    },
    {
      "officialName_en": "Lithuania",
      "abbreviation_en": "LTU",
      "officialName_cn": "立陶宛共和国",
      "abbreviation_cn": "立陶宛",
      "officialLanguage": "立陶宛语",
      "officialScript": "拉丁字母",
      "localeCode": "lt_LT",
      "timezone": "UTC+2",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+370",
      "emoji": "🇱🇹",
      "ccTLD": ".lt"
    },
    {
      "officialName_en": "Lebanon",
      "abbreviation_en": "LBN",
      "officialName_cn": "黎巴嫩共和国",
      "abbreviation_cn": "黎巴嫩",
      "officialLanguage": "阿拉伯语",
      "officialScript": "阿拉伯字母",
      "localeCode": "ar_LB",
      "timezone": "UTC+2",
      "currencyName": "黎巴嫩镑",
      "currencyCode": "LBP",
      "currencySymbol": "ل.ل",
      "continent": "亚洲",
      "regionCode": "+961",
      "emoji": "🇱🇧",
      "ccTLD": ".lb"
    },
    {
      "officialName_en": "Oman",
      "abbreviation_en": "OMN",
      "officialName_cn": "阿曼苏丹国",
      "abbreviation_cn": "阿曼",
      "officialLanguage": "阿拉伯语",
      "officialScript": "阿拉伯字母",
      "localeCode": "ar_OM",
      "timezone": "UTC+4",
      "currencyName": "阿曼里亚尔",
      "currencyCode": "OMR",
      "currencySymbol": "ر.ع.",
      "continent": "亚洲",
      "regionCode": "+968",
      "emoji": "🇴🇲",
      "ccTLD": ".om"
    },
    {
      "officialName_en": "Latvia",
      "abbreviation_en": "LVA",
      "officialName_cn": "拉脱维亚共和国",
      "abbreviation_cn": "拉脱维亚",
      "officialLanguage": "拉脱维亚语",
      "officialScript": "拉丁字母",
      "localeCode": "lv_LV",
      "timezone": "UTC+2",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+371",
      "emoji": "🇱🇻",
      "ccTLD": ".lv"
    },
    {
      "officialName_en": "Estonia",
      "abbreviation_en": "EST",
      "officialName_cn": "爱沙尼亚共和国",
      "abbreviation_cn": "爱沙尼亚",
      "officialLanguage": "爱沙尼亚语",
      "officialScript": "拉丁字母",
      "localeCode": "et_EE",
      "timezone": "UTC+2",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+372",
      "emoji": "🇪🇪",
      "ccTLD": ".ee"
    },
    {
      "officialName_en": "Cyprus",
      "abbreviation_en": "CYP",
      "officialName_cn": "塞浦路斯共和国",
      "abbreviation_cn": "塞浦路斯",
      "officialLanguage": "希腊语、土耳其语",
      "officialScript": "希腊字母、拉丁字母",
      "localeCode": "el_CY/tr_CY",
      "timezone": "UTC+2",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+357",
      "emoji": "🇨🇾",
      "ccTLD": ".cy"
    },
    {
      "officialName_en": "Qatar",
      "abbreviation_en": "QAT",
      "officialName_cn": "卡塔尔国",
      "abbreviation_cn": "卡塔尔",
      "officialLanguage": "阿拉伯语",
      "officialScript": "阿拉伯字母",
      "localeCode": "ar_QA",
      "timezone": "UTC+3",
      "currencyName": "卡塔尔里亚尔",
      "currencyCode": "QAR",
      "currencySymbol": "ر.ق",
      "continent": "亚洲",
      "regionCode": "+974",
      "emoji": "🇶🇦",
      "ccTLD": ".qa"
    },
    {
      "officialName_en": "Paraguay",
      "abbreviation_en": "PRY",
      "officialName_cn": "巴拉圭共和国",
      "abbreviation_cn": "巴拉圭",
      "officialLanguage": "西班牙语、瓜拉尼语",
      "officialScript": "拉丁字母",
      "localeCode": "es_PY/gn_PY",
      "timezone": "UTC-4",
      "currencyName": "巴拉圭瓜拉尼",
      "currencyCode": "PYG",
      "currencySymbol": "₲",
      "continent": "南美洲",
      "regionCode": "+595",
      "emoji": "🇵🇾",
      "ccTLD": ".py"
    },
    {
      "officialName_en": "Bolivia",
      "abbreviation_en": "BOL",
      "officialName_cn": "玻利维亚多民族国",
      "abbreviation_cn": "玻利维亚",
      "officialLanguage": "西班牙语等36种",
      "officialScript": "拉丁字母",
      "localeCode": "es_BO",
      "timezone": "UTC-4",
      "currencyName": "玻利维亚诺",
      "currencyCode": "BOB",
      "currencySymbol": "Bs.",
      "continent": "南美洲",
      "regionCode": "+591",
      "emoji": "🇧🇴",
      "ccTLD": ".bo"
    },
    {
      "officialName_en": "Tunisia",
      "abbreviation_en": "TUN",
      "officialName_cn": "突尼斯共和国",
      "abbreviation_cn": "突尼斯",
      "officialLanguage": "阿拉伯语",
      "officialScript": "阿拉伯字母",
      "localeCode": "ar_TN",
      "timezone": "UTC+1",
      "currencyName": "突尼斯第纳尔",
      "currencyCode": "TND",
      "currencySymbol": "د.ت",
      "continent": "非洲",
      "regionCode": "+216",
      "emoji": "🇹🇳",
      "ccTLD": ".tn"
    },
    {
      "officialName_en": "Bahrain",
      "abbreviation_en": "BHR",
      "officialName_cn": "巴林王国",
      "abbreviation_cn": "巴林",
      "officialLanguage": "阿拉伯语",
      "officialScript": "阿拉伯字母",
      "localeCode": "ar_BH",
      "timezone": "UTC+3",
      "currencyName": "巴林第纳尔",
      "currencyCode": "BHD",
      "currencySymbol": ".د.ب",
      "continent": "亚洲",
      "regionCode": "+973",
      "emoji": "🇧🇭",
      "ccTLD": ".bh"
    },
    {
      "officialName_en": "Honduras",
      "abbreviation_en": "HND",
      "officialName_cn": "洪都拉斯共和国",
      "abbreviation_cn": "洪都拉斯",
      "officialLanguage": "西班牙语",
      "officialScript": "拉丁字母",
      "localeCode": "es_HN",
      "timezone": "UTC-6",
      "currencyName": "洪都拉斯伦皮拉",
      "currencyCode": "HNL",
      "currencySymbol": "L",
      "continent": "北美洲",
      "regionCode": "+504",
      "emoji": "🇭🇳",
      "ccTLD": ".hn"
    },
    {
      "officialName_en": "El Salvador",
      "abbreviation_en": "SLV",
      "officialName_cn": "萨尔瓦多共和国",
      "abbreviation_cn": "萨尔瓦多",
      "officialLanguage": "西班牙语",
      "officialScript": "拉丁字母",
      "localeCode": "es_SV",
      "timezone": "UTC-6",
      "currencyName": "美元",
      "currencyCode": "USD",
      "currencySymbol": "$",
      "continent": "北美洲",
      "regionCode": "+503",
      "emoji": "🇸🇻",
      "ccTLD": ".sv"
    },
    {
      "officialName_en": "Libya",
      "abbreviation_en": "LBY",
      "officialName_cn": "利比亚国",
      "abbreviation_cn": "利比亚",
      "officialLanguage": "阿拉伯语",
      "officialScript": "阿拉伯字母",
      "localeCode": "ar_LY",
      "timezone": "UTC+2",
      "currencyName": "利比亚第纳尔",
      "currencyCode": "LYD",
      "currencySymbol": "ل.د",
      "continent": "非洲",
      "regionCode": "+218",
      "emoji": "🇱🇾",
      "ccTLD": ".ly"
    },
    {
      "officialName_en": "Serbia",
      "abbreviation_en": "SRB",
      "officialName_cn": "塞尔维亚共和国",
      "abbreviation_cn": "塞尔维亚",
      "officialLanguage": "塞尔维亚语",
      "officialScript": "西里尔字母、拉丁字母",
      "localeCode": "sr_RS",
      "timezone": "UTC+1",
      "currencyName": "塞尔维亚第纳尔",
      "currencyCode": "RSD",
      "currencySymbol": "дин.",
      "continent": "欧洲",
      "regionCode": "+381",
      "emoji": "🇷🇸",
      "ccTLD": ".rs"
    },
    {
      "officialName_en": "Georgia",
      "abbreviation_en": "GEO",
      "officialName_cn": "格鲁吉亚",
      "abbreviation_cn": "格鲁吉亚",
      "officialLanguage": "格鲁吉亚语",
      "officialScript": "格鲁吉亚字母",
      "localeCode": "ka_GE",
      "timezone": "UTC+4",
      "currencyName": "格鲁吉亚拉里",
      "currencyCode": "GEL",
      "currencySymbol": "₾",
      "continent": "亚洲",
      "regionCode": "+995",
      "emoji": "🇬🇪",
      "ccTLD": ".ge"
    },
    {
      "officialName_en": "Jordan",
      "abbreviation_en": "JOR",
      "officialName_cn": "约旦哈希姆王国",
      "abbreviation_cn": "约旦",
      "officialLanguage": "阿拉伯语",
      "officialScript": "阿拉伯字母",
      "localeCode": "ar_JO",
      "timezone": "UTC+3",
      "currencyName": "约旦第纳尔",
      "currencyCode": "JOD",
      "currencySymbol": "د.ا",
      "continent": "亚洲",
      "regionCode": "+962",
      "emoji": "🇯🇴",
      "ccTLD": ".jo"
    },
    {
      "officialName_en": "Iceland",
      "abbreviation_en": "ISL",
      "officialName_cn": "冰岛共和国",
      "abbreviation_cn": "冰岛",
      "officialLanguage": "冰岛语",
      "officialScript": "拉丁字母",
      "localeCode": "is_IS",
      "timezone": "UTC+0",
      "currencyName": "冰岛克朗",
      "currencyCode": "ISK",
      "currencySymbol": "kr",
      "continent": "欧洲",
      "regionCode": "+354",
      "emoji": "🇮🇸",
      "ccTLD": ".is"
    },
    {
      "officialName_en": "Moldova",
      "abbreviation_en": "MDA",
      "officialName_cn": "摩尔多瓦共和国",
      "abbreviation_cn": "摩尔多瓦",
      "officialLanguage": "摩尔多瓦语",
      "officialScript": "拉丁字母",
      "localeCode": "ro_MD",
      "timezone": "UTC+2",
      "currencyName": "摩尔多瓦列伊",
      "currencyCode": "MDL",
      "currencySymbol": "L",
      "continent": "欧洲",
      "regionCode": "+373",
      "emoji": "🇲🇩",
      "ccTLD": ".md"
    },
    {
      "officialName_en": "Uzbekistan",
      "abbreviation_en": "UZB",
      "officialName_cn": "乌兹别克斯坦共和国",
      "abbreviation_cn": "乌兹别克斯坦",
      "officialLanguage": "乌兹别克语",
      "officialScript": "拉丁字母",
      "localeCode": "uz_UZ",
      "timezone": "UTC+5",
      "currencyName": "乌兹别克斯坦索姆",
      "currencyCode": "UZS",
      "currencySymbol": "so'm",
      "continent": "亚洲",
      "regionCode": "+998",
      "emoji": "🇺🇿",
      "ccTLD": ".uz"
    },
    {
      "officialName_en": "Brunei",
      "abbreviation_en": "BRN",
      "officialName_cn": "文莱达鲁萨兰国",
      "abbreviation_cn": "文莱",
      "officialLanguage": "马来语",
      "officialScript": "拉丁字母",
      "localeCode": "ms_BN",
      "timezone": "UTC+8",
      "currencyName": "文莱元",
      "currencyCode": "BND",
      "currencySymbol": "$",
      "continent": "亚洲",
      "regionCode": "+673",
      "emoji": "🇧🇳",
      "ccTLD": ".bn"
    },
    {
      "officialName_en": "Armenia",
      "abbreviation_en": "ARM",
      "officialName_cn": "亚美尼亚共和国",
      "abbreviation_cn": "亚美尼亚",
      "officialLanguage": "亚美尼亚语",
      "officialScript": "亚美尼亚字母",
      "localeCode": "hy_AM",
      "timezone": "UTC+4",
      "currencyName": "亚美尼亚德拉姆",
      "currencyCode": "AMD",
      "currencySymbol": "֏",
      "continent": "亚洲",
      "regionCode": "+374",
      "emoji": "🇦🇲",
      "ccTLD": ".am"
    },
    {
      "officialName_en": "Albania",
      "abbreviation_en": "ALB",
      "officialName_cn": "阿尔巴尼亚共和国",
      "abbreviation_cn": "阿尔巴尼亚",
      "officialLanguage": "阿尔巴尼亚语",
      "officialScript": "拉丁字母",
      "localeCode": "sq_AL",
      "timezone": "UTC+1",
      "currencyName": "阿尔巴尼亚列克",
      "currencyCode": "ALL",
      "currencySymbol": "L",
      "continent": "欧洲",
      "regionCode": "+355",
      "emoji": "🇦🇱",
      "ccTLD": ".al"
    },
    {
      "officialName_en": "Mongolia",
      "abbreviation_en": "MNG",
      "officialName_cn": "蒙古国",
      "abbreviation_cn": "蒙古",
      "officialLanguage": "蒙古语",
      "officialScript": "西里尔字母",
      "localeCode": "mn_MN",
      "timezone": "UTC+8",
      "currencyName": "蒙古图格里克",
      "currencyCode": "MNT",
      "currencySymbol": "₮",
      "continent": "亚洲",
      "regionCode": "+976",
      "emoji": "🇲🇳",
      "ccTLD": ".mn"
    },
    {
      "officialName_en": "Jamaica",
      "abbreviation_en": "JAM",
      "officialName_cn": "牙买加",
      "abbreviation_cn": "牙买加",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_JM",
      "timezone": "UTC-5",
      "currencyName": "牙买加元",
      "currencyCode": "JMD",
      "currencySymbol": "$",
      "continent": "北美洲",
      "regionCode": "+1-876",
      "emoji": "🇯🇲",
      "ccTLD": ".jm"
    },
    {
      "officialName_en": "Bosnia and Herzegovina",
      "abbreviation_en": "BIH",
      "officialName_cn": "波斯尼亚和黑塞哥维那",
      "abbreviation_cn": "波黑",
      "officialLanguage": "波斯尼亚语、塞尔维亚语、克罗地亚语",
      "officialScript": "拉丁字母、西里尔字母",
      "localeCode": "bs_BA/sr_BA/hr_BA",
      "timezone": "UTC+1",
      "currencyName": "波斯尼亚可兑换马克",
      "currencyCode": "BAM",
      "currencySymbol": "KM",
      "continent": "欧洲",
      "regionCode": "+387",
      "emoji": "🇧🇦",
      "ccTLD": ".ba"
    },
    {
      "officialName_en": "North Macedonia",
      "abbreviation_en": "MKD",
      "officialName_cn": "北马其顿共和国",
      "abbreviation_cn": "北马其顿",
      "officialLanguage": "马其顿语、阿尔巴尼亚语",
      "officialScript": "西里尔字母、拉丁字母",
      "localeCode": "mk_MK/sq_MK",
      "timezone": "UTC+1",
      "currencyName": "北马其顿第纳尔",
      "currencyCode": "MKD",
      "currencySymbol": "ден",
      "continent": "欧洲",
      "regionCode": "+389",
      "emoji": "🇲🇰",
      "ccTLD": ".mk"
    },
    {
      "officialName_en": "Laos",
      "abbreviation_en": "LAO",
      "officialName_cn": "老挝人民民主共和国",
      "abbreviation_cn": "老挝",
      "officialLanguage": "老挝语",
      "officialScript": "老挝文",
      "localeCode": "lo_LA",
      "timezone": "UTC+7",
      "currencyName": "老挝基普",
      "currencyCode": "LAK",
      "currencySymbol": "₭",
      "continent": "亚洲",
      "regionCode": "+856",
      "emoji": "🇱🇦",
      "ccTLD": ".la"
    },
    {
      "officialName_en": "Kenya",
      "abbreviation_en": "KEN",
      "officialName_cn": "肯尼亚共和国",
      "abbreviation_cn": "肯尼亚",
      "officialLanguage": "英语、斯瓦希里语",
      "officialScript": "拉丁字母",
      "localeCode": "en_KE/sw_KE",
      "timezone": "UTC+3",
      "currencyName": "肯尼亚先令",
      "currencyCode": "KES",
      "currencySymbol": "KSh",
      "continent": "非洲",
      "regionCode": "+254",
      "emoji": "🇰🇪",
      "ccTLD": ".ke"
    },
    {
      "officialName_en": "Kyrgyzstan",
      "abbreviation_en": "KGZ",
      "officialName_cn": "吉尔吉斯共和国",
      "abbreviation_cn": "吉尔吉斯斯坦",
      "officialLanguage": "吉尔吉斯语、俄语",
      "officialScript": "西里尔字母",
      "localeCode": "ky_KG/ru_KG",
      "timezone": "UTC+6",
      "currencyName": "吉尔吉斯斯坦索姆",
      "currencyCode": "KGS",
      "currencySymbol": "с",
      "continent": "亚洲",
      "regionCode": "+996",
      "emoji": "🇰🇬",
      "ccTLD": ".kg"
    },
    {
      "officialName_en": "Mauritius",
      "abbreviation_en": "MUS",
      "officialName_cn": "毛里求斯共和国",
      "abbreviation_cn": "毛里求斯",
      "officialLanguage": "英语、法语",
      "officialScript": "拉丁字母",
      "localeCode": "en_MU/fr_MU",
      "timezone": "UTC+4",
      "currencyName": "毛里求斯卢比",
      "currencyCode": "MUR",
      "currencySymbol": "₨",
      "continent": "非洲",
      "regionCode": "+230",
      "emoji": "🇲🇺",
      "ccTLD": ".mu"
    },
    {
      "officialName_en": "Namibia",
      "abbreviation_en": "NAM",
      "officialName_cn": "纳米比亚共和国",
      "abbreviation_cn": "纳米比亚",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_NA",
      "timezone": "UTC+2",
      "currencyName": "纳米比亚元",
      "currencyCode": "NAD",
      "currencySymbol": "$",
      "continent": "非洲",
      "regionCode": "+264",
      "emoji": "🇳🇦",
      "ccTLD": ".na"
    },
    {
      "officialName_en": "Botswana",
      "abbreviation_en": "BWA",
      "officialName_cn": "博茨瓦纳共和国",
      "abbreviation_cn": "博茨瓦纳",
      "officialLanguage": "英语、茨瓦纳语",
      "officialScript": "拉丁字母",
      "localeCode": "en_BW/tn_BW",
      "timezone": "UTC+2",
      "currencyName": "博茨瓦纳普拉",
      "currencyCode": "BWP",
      "currencySymbol": "P",
      "continent": "非洲",
      "regionCode": "+267",
      "emoji": "🇧🇼",
      "ccTLD": ".bw"
    },
    {
      "officialName_en": "Gabon",
      "abbreviation_en": "GAB",
      "officialName_cn": "加蓬共和国",
      "abbreviation_cn": "加蓬",
      "officialLanguage": "法语",
      "officialScript": "拉丁字母",
      "localeCode": "fr_GA",
      "timezone": "UTC+1",
      "currencyName": "中非法郎",
      "currencyCode": "XAF",
      "currencySymbol": "FCFA",
      "continent": "非洲",
      "regionCode": "+241",
      "emoji": "🇬🇦",
      "ccTLD": ".ga"
    },
    {
      "officialName_en": "Myanmar",
      "abbreviation_en": "MMR",
      "officialName_cn": "缅甸联邦共和国",
      "abbreviation_cn": "缅甸",
      "officialLanguage": "缅甸语",
      "officialScript": "缅甸文",
      "localeCode": "my_MM",
      "timezone": "UTC+6:30",
      "currencyName": "缅甸元",
      "currencyCode": "MMK",
      "currencySymbol": "K",
      "continent": "亚洲",
      "regionCode": "+95",
      "emoji": "🇲🇲",
      "ccTLD": ".mm"
    },
    {
      "officialName_en": "Malta",
      "abbreviation_en": "MLT",
      "officialName_cn": "马耳他共和国",
      "abbreviation_cn": "马耳他",
      "officialLanguage": "马耳他语、英语",
      "officialScript": "拉丁字母",
      "localeCode": "mt_MT/en_MT",
      "timezone": "UTC+1",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+356",
      "emoji": "🇲🇹",
      "ccTLD": ".mt"
    },
    {
      "officialName_en": "Trinidad and Tobago",
      "abbreviation_en": "TTO",
      "officialName_cn": "特立尼达和多巴哥共和国",
      "abbreviation_cn": "特立尼达和多巴哥",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_TT",
      "timezone": "UTC-4",
      "currencyName": "特立尼达和多巴哥元",
      "currencyCode": "TTD",
      "currencySymbol": "$",
      "continent": "北美洲",
      "regionCode": "+1-868",
      "emoji": "🇹🇹",
      "ccTLD": ".tt"
    },
    {
      "officialName_en": "Bahamas",
      "abbreviation_en": "BHS",
      "officialName_cn": "巴哈马国",
      "abbreviation_cn": "巴哈马",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_BS",
      "timezone": "UTC-5",
      "currencyName": "巴哈马元",
      "currencyCode": "BSD",
      "currencySymbol": "$",
      "continent": "北美洲",
      "regionCode": "+1-242",
      "emoji": "🇧🇸",
      "ccTLD": ".bs"
    },
    {
      "officialName_en": "Cambodia",
      "abbreviation_en": "KHM",
      "officialName_cn": "柬埔寨王国",
      "abbreviation_cn": "柬埔寨",
      "officialLanguage": "高棉语",
      "officialScript": "高棉文",
      "localeCode": "km_KH",
      "timezone": "UTC+7",
      "currencyName": "柬埔寨瑞尔",
      "currencyCode": "KHR",
      "currencySymbol": "៛",
      "continent": "亚洲",
      "regionCode": "+855",
      "emoji": "🇰🇭",
      "ccTLD": ".kh"
    },
    {
      "officialName_en": "Zimbabwe",
      "abbreviation_en": "ZWE",
      "officialName_cn": "津巴布韦共和国",
      "abbreviation_cn": "津巴布韦",
      "officialLanguage": "英语、绍纳语、恩德贝莱语等",
      "officialScript": "拉丁字母",
      "localeCode": "en_ZW/sn_ZW/nd_ZW",
      "timezone": "UTC+2",
      "currencyName": "津巴布韦元",
      "currencyCode": "ZWL",
      "currencySymbol": "$",
      "continent": "非洲",
      "regionCode": "+263",
      "emoji": "🇿🇼",
      "ccTLD": ".zw"
    },
    {
      "officialName_en": "Rwanda",
      "abbreviation_en": "RWA",
      "officialName_cn": "卢旺达共和国",
      "abbreviation_cn": "卢旺达",
      "officialLanguage": "基尼亚卢旺达语、英语、法语",
      "officialScript": "拉丁字母",
      "localeCode": "rw_RW/en_RW/fr_RW",
      "timezone": "UTC+2",
      "currencyName": "卢旺达法郎",
      "currencyCode": "RWF",
      "currencySymbol": "Fr",
      "continent": "非洲",
      "regionCode": "+250",
      "emoji": "🇷🇼",
      "ccTLD": ".rw"
    },
    {
      "officialName_en": "Senegal",
      "abbreviation_en": "SEN",
      "officialName_cn": "塞内加尔共和国",
      "abbreviation_cn": "塞内加尔",
      "officialLanguage": "法语",
      "officialScript": "拉丁字母",
      "localeCode": "fr_SN",
      "timezone": "UTC+0",
      "currencyName": "西非法郎",
      "currencyCode": "XOF",
      "currencySymbol": "CFA",
      "continent": "非洲",
      "regionCode": "+221",
      "emoji": "🇸🇳",
      "ccTLD": ".sn"
    },
    {
      "officialName_en": "Guinea",
      "abbreviation_en": "GIN",
      "officialName_cn": "几内亚共和国",
      "abbreviation_cn": "几内亚",
      "officialLanguage": "法语",
      "officialScript": "拉丁字母",
      "localeCode": "fr_GN",
      "timezone": "UTC+0",
      "currencyName": "几内亚法郎",
      "currencyCode": "GNF",
      "currencySymbol": "FG",
      "continent": "非洲",
      "regionCode": "+224",
      "emoji": "🇬🇳",
      "ccTLD": ".gn"
    },
    {
      "officialName_en": "Montenegro",
      "abbreviation_en": "MNE",
      "officialName_cn": "黑山共和国",
      "abbreviation_cn": "黑山",
      "officialLanguage": "黑山语",
      "officialScript": "拉丁字母",
      "localeCode": "sr_ME",
      "timezone": "UTC+1",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+382",
      "emoji": "🇲🇪",
      "ccTLD": ".me"
    },
    {
      "officialName_en": "Mozambique",
      "abbreviation_en": "MOZ",
      "officialName_cn": "莫桑比克共和国",
      "abbreviation_cn": "莫桑比克",
      "officialLanguage": "葡萄牙语",
      "officialScript": "拉丁字母",
      "localeCode": "pt_MZ",
      "timezone": "UTC+2",
      "currencyName": "莫桑比克梅蒂卡尔",
      "currencyCode": "MZN",
      "currencySymbol": "MT",
      "continent": "非洲",
      "regionCode": "+258",
      "emoji": "🇲🇿",
      "ccTLD": ".mz"
    },
    {
      "officialName_en": "Uganda",
      "abbreviation_en": "UGA",
      "officialName_cn": "乌干达共和国",
      "abbreviation_cn": "乌干达",
      "officialLanguage": "英语、斯瓦希里语",
      "officialScript": "拉丁字母",
      "localeCode": "en_UG/sw_UG",
      "timezone": "UTC+3",
      "currencyName": "乌干达先令",
      "currencyCode": "UGX",
      "currencySymbol": "USh",
      "continent": "非洲",
      "regionCode": "+256",
      "emoji": "🇺🇬",
      "ccTLD": ".ug"
    },
    {
      "officialName_en": "Zambia",
      "abbreviation_en": "ZMB",
      "officialName_cn": "赞比亚共和国",
      "abbreviation_cn": "赞比亚",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_ZM",
      "timezone": "UTC+2",
      "currencyName": "赞比亚克瓦查",
      "currencyCode": "ZMW",
      "currencySymbol": "ZK",
      "continent": "非洲",
      "regionCode": "+260",
      "emoji": "🇿🇲",
      "ccTLD": ".zm"
    },
    {
      "officialName_en": "Madagascar",
      "abbreviation_en": "MDG",
      "officialName_cn": "马达加斯加共和国",
      "abbreviation_cn": "马达加斯加",
      "officialLanguage": "马达加斯加语、法语",
      "officialScript": "拉丁字母",
      "localeCode": "mg_MG/fr_MG",
      "timezone": "UTC+3",
      "currencyName": "马达加斯加阿里亚里",
      "currencyCode": "MGA",
      "currencySymbol": "Ar",
      "continent": "非洲",
      "regionCode": "+261",
      "emoji": "🇲🇬",
      "ccTLD": ".mg"
    },
    {
      "officialName_en": "Tajikistan",
      "abbreviation_en": "TJK",
      "officialName_cn": "塔吉克斯坦共和国",
      "abbreviation_cn": "塔吉克斯坦",
      "officialLanguage": "塔吉克语",
      "officialScript": "西里尔字母",
      "localeCode": "tg_TJ",
      "timezone": "UTC+5",
      "currencyName": "塔吉克斯坦索莫尼",
      "currencyCode": "TJS",
      "currencySymbol": "ЅМ",
      "continent": "亚洲",
      "regionCode": "+992",
      "emoji": "🇹🇯",
      "ccTLD": ".tj"
    },
    {
      "officialName_en": "Haiti",
      "abbreviation_en": "HTI",
      "officialName_cn": "海地共和国",
      "abbreviation_cn": "海地",
      "officialLanguage": "海地克里奥尔语、法语",
      "officialScript": "拉丁字母",
      "localeCode": "ht_HT/fr_HT",
      "timezone": "UTC-5",
      "currencyName": "海地古德",
      "currencyCode": "HTG",
      "currencySymbol": "G",
      "continent": "北美洲",
      "regionCode": "+509",
      "emoji": "🇭🇹",
      "ccTLD": ".ht"
    },
    {
      "officialName_en": "Benin",
      "abbreviation_en": "BEN",
      "officialName_cn": "贝宁共和国",
      "abbreviation_cn": "贝宁",
      "officialLanguage": "法语",
      "officialScript": "拉丁字母",
      "localeCode": "fr_BJ",
      "timezone": "UTC+1",
      "currencyName": "西非法郎",
      "currencyCode": "XOF",
      "currencySymbol": "CFA",
      "continent": "非洲",
      "regionCode": "+229",
      "emoji": "🇧🇯",
      "ccTLD": ".bj"
    },
    {
      "officialName_en": "Libya",
      "abbreviation_en": "LBY",
      "officialName_cn": "利比亚国",
      "abbreviation_cn": "利比亚",
      "officialLanguage": "阿拉伯语",
      "officialScript": "阿拉伯字母",
      "localeCode": "ar_LY",
      "timezone": "UTC+2",
      "currencyName": "利比亚第纳尔",
      "currencyCode": "LYD",
      "currencySymbol": "ل.د",
      "continent": "非洲",
      "regionCode": "+218",
      "emoji": "🇱🇾",
      "ccTLD": ".ly"
    },
    {
      "officialName_en": "Nicaragua",
      "abbreviation_en": "NIC",
      "officialName_cn": "尼加拉瓜共和国",
      "abbreviation_cn": "尼加拉瓜",
      "officialLanguage": "西班牙语",
      "officialScript": "拉丁字母",
      "localeCode": "es_NI",
      "timezone": "UTC-6",
      "currencyName": "尼加拉瓜科多巴",
      "currencyCode": "NIO",
      "currencySymbol": "C$",
      "continent": "北美洲",
      "regionCode": "+505",
      "emoji": "🇳🇮",
      "ccTLD": ".ni"
    },
    {
      "officialName_en": "Latvia",
      "abbreviation_en": "LVA",
      "officialName_cn": "拉脱维亚共和国",
      "abbreviation_cn": "拉脱维亚",
      "officialLanguage": "拉脱维亚语",
      "officialScript": "拉丁字母",
      "localeCode": "lv_LV",
      "timezone": "UTC+2",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+371",
      "emoji": "🇱🇻",
      "ccTLD": ".lv"
    },
    {
      "officialName_en": "Mali",
      "abbreviation_en": "MLI",
      "officialName_cn": "马里共和国",
      "abbreviation_cn": "马里",
      "officialLanguage": "法语",
      "officialScript": "拉丁字母",
      "localeCode": "fr_ML",
      "timezone": "UTC+0",
      "currencyName": "西非法郎",
      "currencyCode": "XOF",
      "currencySymbol": "CFA",
      "continent": "非洲",
      "regionCode": "+223",
      "emoji": "🇲🇱",
      "ccTLD": ".ml"
    },
    {
      "officialName_en": "Burkina Faso",
      "abbreviation_en": "BFA",
      "officialName_cn": "布基纳法索",
      "abbreviation_cn": "布基纳法索",
      "officialLanguage": "法语",
      "officialScript": "拉丁字母",
      "localeCode": "fr_BF",
      "timezone": "UTC+0",
      "currencyName": "西非法郎",
      "currencyCode": "XOF",
      "currencySymbol": "CFA",
      "continent": "非洲",
      "regionCode": "+226",
      "emoji": "🇧🇫",
      "ccTLD": ".bf"
    },
    {
      "officialName_en": "South Sudan",
      "abbreviation_en": "SSD",
      "officialName_cn": "南苏丹共和国",
      "abbreviation_cn": "南苏丹",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_SS",
      "timezone": "UTC+3",
      "currencyName": "南苏丹镑",
      "currencyCode": "SSP",
      "currencySymbol": "£",
      "continent": "非洲",
      "regionCode": "+211",
      "emoji": "🇸🇸",
      "ccTLD": ".ss"
    },
    {
      "officialName_en": "Malawi",
      "abbreviation_en": "MWI",
      "officialName_cn": "马拉维共和国",
      "abbreviation_cn": "马拉维",
      "officialLanguage": "英语、齐切瓦语",
      "officialScript": "拉丁字母",
      "localeCode": "en_MW/ny_MW",
      "timezone": "UTC+2",
      "currencyName": "马拉维克瓦查",
      "currencyCode": "MWK",
      "currencySymbol": "MK",
      "continent": "非洲",
      "regionCode": "+265",
      "emoji": "🇲🇼",
      "ccTLD": ".mw"
    },
    {
      "officialName_en": "Togo",
      "abbreviation_en": "TGO",
      "officialName_cn": "多哥共和国",
      "abbreviation_cn": "多哥",
      "officialLanguage": "法语",
      "officialScript": "拉丁字母",
      "localeCode": "fr_TG",
      "timezone": "UTC+0",
      "currencyName": "西非法郎",
      "currencyCode": "XOF",
      "currencySymbol": "CFA",
      "continent": "非洲",
      "regionCode": "+228",
      "emoji": "🇹🇬",
      "ccTLD": ".tg"
    },
    {
      "officialName_en": "Sierra Leone",
      "abbreviation_en": "SLE",
      "officialName_cn": "塞拉利昂共和国",
      "abbreviation_cn": "塞拉利昂",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_SL",
      "timezone": "UTC+0",
      "currencyName": "塞拉利昂利昂",
      "currencyCode": "SLL",
      "currencySymbol": "Le",
      "continent": "非洲",
      "regionCode": "+232",
      "emoji": "🇸🇱",
      "ccTLD": ".sl"
    },
    {
      "officialName_en": "Macao",
      "abbreviation_en": "MAC",
      "officialName_cn": "中华人民共和国澳门特别行政区",
      "abbreviation_cn": "澳门",
      "officialLanguage": "中文、葡萄牙语",
      "officialScript": "简体中文、拉丁字母",
      "localeCode": "zh_MO/pt_MO",
      "timezone": "UTC+8",
      "currencyName": "澳门元",
      "currencyCode": "MOP",
      "currencySymbol": "MOP$",
      "continent": "亚洲",
      "regionCode": "+853",
      "emoji": "🇲🇴",
      "ccTLD": ".mo"
    },
    {
      "officialName_en": "Fiji",
      "abbreviation_en": "FJI",
      "officialName_cn": "斐济共和国",
      "abbreviation_cn": "斐济",
      "officialLanguage": "英语、斐济语、斐济印地语",
      "officialScript": "拉丁字母",
      "localeCode": "en_FJ/fj_FJ/hif_FJ",
      "timezone": "UTC+12",
      "currencyName": "斐济元",
      "currencyCode": "FJD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+679",
      "emoji": "🇫🇯",
      "ccTLD": ".fj"
    },
    {
      "officialName_en": "Luxembourg",
      "abbreviation_en": "LUX",
      "officialName_cn": "卢森堡大公国",
      "abbreviation_cn": "卢森堡",
      "officialLanguage": "卢森堡语、法语、德语",
      "officialScript": "拉丁字母",
      "localeCode": "lb_LU/fr_LU/de_LU",
      "timezone": "UTC+1",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+352",
      "emoji": "🇱🇺",
      "ccTLD": ".lu"
    },
    {
      "officialName_en": "Equatorial Guinea",
      "abbreviation_en": "GNQ",
      "officialName_cn": "赤道几内亚共和国",
      "abbreviation_cn": "赤道几内亚",
      "officialLanguage": "西班牙语、法语、葡萄牙语",
      "officialScript": "拉丁字母",
      "localeCode": "es_GQ/fr_GQ/pt_GQ",
      "timezone": "UTC+1",
      "currencyName": "中非法郎",
      "currencyCode": "XAF",
      "currencySymbol": "FCFA",
      "continent": "非洲",
      "regionCode": "+240",
      "emoji": "🇬🇶",
      "ccTLD": ".gq"
    },
    {
      "officialName_en": "Mauritania",
      "abbreviation_en": "MRT",
      "officialName_cn": "毛里塔尼亚伊斯兰共和国",
      "abbreviation_cn": "毛里塔尼亚",
      "officialLanguage": "阿拉伯语",
      "officialScript": "阿拉伯字母",
      "localeCode": "ar_MR",
      "timezone": "UTC+0",
      "currencyName": "毛里塔尼亚乌吉亚",
      "currencyCode": "MRU",
      "currencySymbol": "UM",
      "continent": "非洲",
      "regionCode": "+222",
      "emoji": "🇲🇷",
      "ccTLD": ".mr"
    },
    {
      "officialName_en": "Papua New Guinea",
      "abbreviation_en": "PNG",
      "officialName_cn": "巴布亚新几内亚独立国",
      "abbreviation_cn": "巴布亚新几内亚",
      "officialLanguage": "英语、托克皮辛语、希里莫图语",
      "officialScript": "拉丁字母",
      "localeCode": "en_PG/tpi_PG/ho_PG",
      "timezone": "UTC+10",
      "currencyName": "巴布亚新几内亚基那",
      "currencyCode": "PGK",
      "currencySymbol": "K",
      "continent": "大洋洲",
      "regionCode": "+675",
      "emoji": "🇵🇬",
      "ccTLD": ".pg"
    },
    {
      "officialName_en": "Djibouti",
      "abbreviation_en": "DJI",
      "officialName_cn": "吉布提共和国",
      "abbreviation_cn": "吉布提",
      "officialLanguage": "阿拉伯语、法语",
      "officialScript": "阿拉伯字母、拉丁字母",
      "localeCode": "ar_DJ/fr_DJ",
      "timezone": "UTC+3",
      "currencyName": "吉布提法郎",
      "currencyCode": "DJF",
      "currencySymbol": "Fdj",
      "continent": "非洲",
      "regionCode": "+253",
      "emoji": "🇩🇯",
      "ccTLD": ".dj"
    },
    {
      "officialName_en": "Liberia",
      "abbreviation_en": "LBR",
      "officialName_cn": "利比里亚共和国",
      "abbreviation_cn": "利比里亚",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_LR",
      "timezone": "UTC+0",
      "currencyName": "利比里亚元",
      "currencyCode": "LRD",
      "currencySymbol": "$",
      "continent": "非洲",
      "regionCode": "+231",
      "emoji": "🇱🇷",
      "ccTLD": ".lr"
    },
    {
      "officialName_en": "Kosovo",
      "abbreviation_en": "XKX",
      "officialName_cn": "科索沃共和国",
      "abbreviation_cn": "科索沃",
      "officialLanguage": "阿尔巴尼亚语、塞尔维亚语",
      "officialScript": "拉丁字母、西里尔字母",
      "localeCode": "sq_XK/sr_XK",
      "timezone": "UTC+1",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+383",
      "emoji": "🇽🇰",
      "ccTLD": "-"
    },
    {
      "officialName_en": "Montenegro",
      "abbreviation_en": "MNE",
      "officialName_cn": "黑山共和国",
      "abbreviation_cn": "黑山",
      "officialLanguage": "黑山语",
      "officialScript": "拉丁字母",
      "localeCode": "sr_ME",
      "timezone": "UTC+1",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+382",
      "emoji": "🇲🇪",
      "ccTLD": ".me"
    },
    {
      "officialName_en": "Solomon Islands",
      "abbreviation_en": "SLB",
      "officialName_cn": "所罗门群岛",
      "abbreviation_cn": "所罗门群岛",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_SB",
      "timezone": "UTC+11",
      "currencyName": "所罗门群岛元",
      "currencyCode": "SBD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+677",
      "emoji": "🇸🇧",
      "ccTLD": ".sb"
    },
    {
      "officialName_en": "Suriname",
      "abbreviation_en": "SUR",
      "officialName_cn": "苏里南共和国",
      "abbreviation_cn": "苏里南",
      "officialLanguage": "荷兰语",
      "officialScript": "拉丁字母",
      "localeCode": "nl_SR",
      "timezone": "UTC-3",
      "currencyName": "苏里南元",
      "currencyCode": "SRD",
      "currencySymbol": "$",
      "continent": "南美洲",
      "regionCode": "+597",
      "emoji": "🇸🇷",
      "ccTLD": ".sr"
    },
    {
      "officialName_en": "Cape Verde",
      "abbreviation_en": "CPV",
      "officialName_cn": "佛得角共和国",
      "abbreviation_cn": "佛得角",
      "officialLanguage": "葡萄牙语",
      "officialScript": "拉丁字母",
      "localeCode": "pt_CV",
      "timezone": "UTC-1",
      "currencyName": "佛得角埃斯库多",
      "currencyCode": "CVE",
      "currencySymbol": "$",
      "continent": "非洲",
      "regionCode": "+238",
      "emoji": "🇨🇻",
      "ccTLD": ".cv"
    },
    {
      "officialName_en": "Maldives",
      "abbreviation_en": "MDV",
      "officialName_cn": "马尔代夫共和国",
      "abbreviation_cn": "马尔代夫",
      "officialLanguage": "迪维希语",
      "officialScript": "它纳文",
      "localeCode": "dv_MV",
      "timezone": "UTC+5",
      "currencyName": "马尔代夫卢菲亚",
      "currencyCode": "MVR",
      "currencySymbol": ".ރ",
      "continent": "亚洲",
      "regionCode": "+960",
      "emoji": "🇲🇻",
      "ccTLD": ".mv"
    },
    {
      "officialName_en": "Bhutan",
      "abbreviation_en": "BTN",
      "officialName_cn": "不丹王国",
      "abbreviation_cn": "不丹",
      "officialLanguage": "宗卡语",
      "officialScript": "藏文",
      "localeCode": "dz_BT",
      "timezone": "UTC+6",
      "currencyName": "不丹努尔特鲁姆",
      "currencyCode": "BTN",
      "currencySymbol": "Nu.",
      "continent": "亚洲",
      "regionCode": "+975",
      "emoji": "🇧🇹",
      "ccTLD": ".bt"
    },
    {
      "officialName_en": "Lesotho",
      "abbreviation_en": "LSO",
      "officialName_cn": "莱索托王国",
      "abbreviation_cn": "莱索托",
      "officialLanguage": "英语、索托语",
      "officialScript": "拉丁字母",
      "localeCode": "en_LS/st_LS",
      "timezone": "UTC+2",
      "currencyName": "莱索托洛蒂",
      "currencyCode": "LSL",
      "currencySymbol": "L",
      "continent": "非洲",
      "regionCode": "+266",
      "emoji": "🇱🇸",
      "ccTLD": ".ls"
    },
    {
      "officialName_en": "Timor-Leste",
      "abbreviation_en": "TLS",
      "officialName_cn": "东帝汶民主共和国",
      "abbreviation_cn": "东帝汶",
      "officialLanguage": "葡萄牙语、德顿语",
      "officialScript": "拉丁字母",
      "localeCode": "pt_TL/tet_TL",
      "timezone": "UTC+9",
      "currencyName": "美元",
      "currencyCode": "USD",
      "currencySymbol": "$",
      "continent": "亚洲",
      "regionCode": "+670",
      "emoji": "🇹🇱",
      "ccTLD": ".tl"
    },
    {
      "officialName_en": "Eswatini",
      "abbreviation_en": "SWZ",
      "officialName_cn": "斯威士兰王国",
      "abbreviation_cn": "斯威士兰",
      "officialLanguage": "英语、斯瓦蒂语",
      "officialScript": "拉丁字母",
      "localeCode": "en_SZ/ss_SZ",
      "timezone": "UTC+2",
      "currencyName": "斯威士兰里兰吉尼",
      "currencyCode": "SZL",
      "currencySymbol": "L",
      "continent": "非洲",
      "regionCode": "+268",
      "emoji": "🇸🇿",
      "ccTLD": ".sz"
    },
    {
      "officialName_en": "Burundi",
      "abbreviation_en": "BDI",
      "officialName_cn": "布隆迪共和国",
      "abbreviation_cn": "布隆迪",
      "officialLanguage": "基隆迪语、法语、英语",
      "officialScript": "拉丁字母",
      "localeCode": "rn_BI/fr_BI/en_BI",
      "timezone": "UTC+2",
      "currencyName": "布隆迪法郎",
      "currencyCode": "BIF",
      "currencySymbol": "FBu",
      "continent": "非洲",
      "regionCode": "+257",
      "emoji": "🇧🇮",
      "ccTLD": ".bi"
    },
    {
      "officialName_en": "Comoros",
      "abbreviation_en": "COM",
      "officialName_cn": "科摩罗联盟",
      "abbreviation_cn": "科摩罗",
      "officialLanguage": "阿拉伯语、法语、科摩罗语",
      "officialScript": "阿拉伯字母、拉丁字母",
      "localeCode": "ar_KM/fr_KM/zdj_KM",
      "timezone": "UTC+3",
      "currencyName": "科摩罗法郎",
      "currencyCode": "KMF",
      "currencySymbol": "CF",
      "continent": "非洲",
      "regionCode": "+269",
      "emoji": "🇰🇲",
      "ccTLD": ".km"
    },
    {
      "officialName_en": "Samoa",
      "abbreviation_en": "WSM",
      "officialName_cn": "萨摩亚独立国",
      "abbreviation_cn": "萨摩亚",
      "officialLanguage": "萨摩亚语、英语",
      "officialScript": "拉丁字母",
      "localeCode": "sm_WS/en_WS",
      "timezone": "UTC+13",
      "currencyName": "萨摩亚塔拉",
      "currencyCode": "WST",
      "currencySymbol": "T",
      "continent": "大洋洲",
      "regionCode": "+685",
      "emoji": "🇼🇸",
      "ccTLD": ".ws"
    },
    {
      "officialName_en": "Vanuatu",
      "abbreviation_en": "VUT",
      "officialName_cn": "瓦努阿图共和国",
      "abbreviation_cn": "瓦努阿图",
      "officialLanguage": "比斯拉马语、英语、法语",
      "officialScript": "拉丁字母",
      "localeCode": "bi_VU/en_VU/fr_VU",
      "timezone": "UTC+11",
      "currencyName": "瓦努阿图瓦图",
      "currencyCode": "VUV",
      "currencySymbol": "VT",
      "continent": "大洋洲",
      "regionCode": "+678",
      "emoji": "🇻🇺",
      "ccTLD": ".vu"
    },
    {
      "officialName_en": "São Tomé and Príncipe",
      "abbreviation_en": "STP",
      "officialName_cn": "圣多美和普林西比民主共和国",
      "abbreviation_cn": "圣多美和普林西比",
      "officialLanguage": "葡萄牙语",
      "officialScript": "拉丁字母",
      "localeCode": "pt_ST",
      "timezone": "UTC+0",
      "currencyName": "圣多美多布拉",
      "currencyCode": "STN",
      "currencySymbol": "Db",
      "continent": "非洲",
      "regionCode": "+239",
      "emoji": "🇸🇹",
      "ccTLD": ".st"
    },
    {
      "officialName_en": "Tonga",
      "abbreviation_en": "TON",
      "officialName_cn": "汤加王国",
      "abbreviation_cn": "汤加",
      "officialLanguage": "汤加语、英语",
      "officialScript": "拉丁字母",
      "localeCode": "to_TO/en_TO",
      "timezone": "UTC+13",
      "currencyName": "汤加潘加",
      "currencyCode": "TOP",
      "currencySymbol": "T$",
      "continent": "大洋洲",
      "regionCode": "+676",
      "emoji": "🇹🇴",
      "ccTLD": ".to"
    },
    {
      "officialName_en": "Kiribati",
      "abbreviation_en": "KIR",
      "officialName_cn": "基里巴斯共和国",
      "abbreviation_cn": "基里巴斯",
      "officialLanguage": "英语、吉尔伯特语",
      "officialScript": "拉丁字母",
      "localeCode": "en_KI/gil_KI",
      "timezone": "UTC+12 to +14",
      "currencyName": "澳大利亚元",
      "currencyCode": "AUD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+686",
      "emoji": "🇰🇮",
      "ccTLD": ".ki"
    },
    {
      "officialName_en": "Micronesia",
      "abbreviation_en": "FSM",
      "officialName_cn": "密克罗尼西亚联邦",
      "abbreviation_cn": "密克罗尼西亚",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_FM",
      "timezone": "UTC+10 to +11",
      "currencyName": "美元",
      "currencyCode": "USD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+691",
      "emoji": "🇫🇲",
      "ccTLD": ".fm"
    },
    {
      "officialName_en": "Seychelles",
      "abbreviation_en": "SYC",
      "officialName_cn": "塞舌尔共和国",
      "abbreviation_cn": "塞舌尔",
      "officialLanguage": "英语、法语、塞舌尔克里奥尔语",
      "officialScript": "拉丁字母",
      "localeCode": "en_SC/fr_SC/crs_SC",
      "timezone": "UTC+4",
      "currencyName": "塞舌尔卢比",
      "currencyCode": "SCR",
      "currencySymbol": "SR",
      "continent": "非洲",
      "regionCode": "+248",
      "emoji": "🇸🇨",
      "ccTLD": ".sc"
    },
    {
      "officialName_en": "Andorra",
      "abbreviation_en": "AND",
      "officialName_cn": "安道尔公国",
      "abbreviation_cn": "安道尔",
      "officialLanguage": "加泰罗尼亚语",
      "officialScript": "拉丁字母",
      "localeCode": "ca_AD",
      "timezone": "UTC+1",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+376",
      "emoji": "🇦🇩",
      "ccTLD": ".ad"
    },
    {
      "officialName_en": "Dominica",
      "abbreviation_en": "DMA",
      "officialName_cn": "多米尼克国",
      "abbreviation_cn": "多米尼克",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_DM",
      "timezone": "UTC-4",
      "currencyName": "东加勒比元",
      "currencyCode": "XCD",
      "currencySymbol": "$",
      "continent": "北美洲",
      "regionCode": "+1-767",
      "emoji": "🇩🇲",
      "ccTLD": ".dm"
    },
    {
      "officialName_en": "Tuvalu",
      "abbreviation_en": "TUV",
      "officialName_cn": "图瓦卢",
      "abbreviation_cn": "图瓦卢",
      "officialLanguage": "英语、图瓦卢语",
      "officialScript": "拉丁字母",
      "localeCode": "en_TV/tvl_TV",
      "timezone": "UTC+12",
      "currencyName": "澳大利亚元",
      "currencyCode": "AUD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+688",
      "emoji": "🇹🇻",
      "ccTLD": ".tv"
    },
    {
      "officialName_en": "Saint Kitts and Nevis",
      "abbreviation_en": "KNA",
      "officialName_cn": "圣基茨和尼维斯联邦",
      "abbreviation_cn": "圣基茨和尼维斯",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_KN",
      "timezone": "UTC-4",
      "currencyName": "东加勒比元",
      "currencyCode": "XCD",
      "currencySymbol": "$",
      "continent": "北美洲",
      "regionCode": "+1-869",
      "emoji": "🇰🇳",
      "ccTLD": ".kn"
    },
    {
      "officialName_en": "San Marino",
      "abbreviation_en": "SMR",
      "officialName_cn": "圣马力诺共和国",
      "abbreviation_cn": "圣马力诺",
      "officialLanguage": "意大利语",
      "officialScript": "拉丁字母",
      "localeCode": "it_SM",
      "timezone": "UTC+1",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+378",
      "emoji": "🇸🇲",
      "ccTLD": ".sm"
    },
    {
      "officialName_en": "Palau",
      "abbreviation_en": "PLW",
      "officialName_cn": "帕劳共和国",
      "abbreviation_cn": "帕劳",
      "officialLanguage": "英语、帕劳语",
      "officialScript": "拉丁字母",
      "localeCode": "en_PW/pau_PW",
      "timezone": "UTC+9",
      "currencyName": "美元",
      "currencyCode": "USD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+680",
      "emoji": "🇵🇼",
      "ccTLD": ".pw"
    },
    {
      "officialName_en": "Antigua and Barbuda",
      "abbreviation_en": "ATG",
      "officialName_cn": "安提瓜和巴布达",
      "abbreviation_cn": "安提瓜和巴布达",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_AG",
      "timezone": "UTC-4",
      "currencyName": "东加勒比元",
      "currencyCode": "XCD",
      "currencySymbol": "$",
      "continent": "北美洲",
      "regionCode": "+1-268",
      "emoji": "🇦🇬",
      "ccTLD": ".ag"
    },
    {
      "officialName_en": "Liechtenstein",
      "abbreviation_en": "LIE",
      "officialName_cn": "列支敦士登公国",
      "abbreviation_cn": "列支敦士登",
      "officialLanguage": "德语",
      "officialScript": "拉丁字母",
      "localeCode": "de_LI",
      "timezone": "UTC+1",
      "currencyName": "瑞士法郎",
      "currencyCode": "CHF",
      "currencySymbol": "Fr.",
      "continent": "欧洲",
      "regionCode": "+423",
      "emoji": "🇱🇮",
      "ccTLD": ".li"
    },
    {
      "officialName_en": "Monaco",
      "abbreviation_en": "MCO",
      "officialName_cn": "摩纳哥公国",
      "abbreviation_cn": "摩纳哥",
      "officialLanguage": "法语",
      "officialScript": "拉丁字母",
      "localeCode": "fr_MC",
      "timezone": "UTC+1",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+377",
      "emoji": "🇲🇨",
      "ccTLD": ".mc"
    },
    {
      "officialName_en": "Vatican City",
      "abbreviation_en": "VAT",
      "officialName_cn": "梵蒂冈城国",
      "abbreviation_cn": "梵蒂冈",
      "officialLanguage": "意大利语、拉丁语",
      "officialScript": "拉丁字母",
      "localeCode": "it_VA/la_VA",
      "timezone": "UTC+1",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "欧洲",
      "regionCode": "+39-06",
      "emoji": "🇻🇦",
      "ccTLD": ".va"
    },
    {
      "officialName_en": "Marshall Islands",
      "abbreviation_en": "MHL",
      "officialName_cn": "马绍尔群岛共和国",
      "abbreviation_cn": "马绍尔群岛",
      "officialLanguage": "马绍尔语、英语",
      "officialScript": "拉丁字母",
      "localeCode": "mh_MH/en_MH",
      "timezone": "UTC+12",
      "currencyName": "美元",
      "currencyCode": "USD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+692",
      "emoji": "🇲🇭",
      "ccTLD": ".mh"
    },
    {
      "officialName_en": "Saint Vincent and the Grenadines",
      "abbreviation_en": "VCT",
      "officialName_cn": "圣文森特和格林纳丁斯",
      "abbreviation_cn": "圣文森特",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_VC",
      "timezone": "UTC-4",
      "currencyName": "东加勒比元",
      "currencyCode": "XCD",
      "currencySymbol": "$",
      "continent": "北美洲",
      "regionCode": "+1-784",
      "emoji": "🇻🇨",
      "ccTLD": ".vc"
    },
    {
      "officialName_en": "Grenada",
      "abbreviation_en": "GRD",
      "officialName_cn": "格林纳达",
      "abbreviation_cn": "格林纳达",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_GD",
      "timezone": "UTC-4",
      "currencyName": "东加勒比元",
      "currencyCode": "XCD",
      "currencySymbol": "$",
      "continent": "北美洲",
      "regionCode": "+1-473",
      "emoji": "🇬🇩",
      "ccTLD": ".gd"
    },
    {
      "officialName_en": "Saint Lucia",
      "abbreviation_en": "LCA",
      "officialName_cn": "圣卢西亚",
      "abbreviation_cn": "圣卢西亚",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_LC",
      "timezone": "UTC-4",
      "currencyName": "东加勒比元",
      "currencyCode": "XCD",
      "currencySymbol": "$",
      "continent": "北美洲",
      "regionCode": "+1-758",
      "emoji": "🇱🇨",
      "ccTLD": ".lc"
    },
    {
      "officialName_en": "Nauru",
      "abbreviation_en": "NRU",
      "officialName_cn": "瑙鲁共和国",
      "abbreviation_cn": "瑙鲁",
      "officialLanguage": "瑙鲁语、英语",
      "officialScript": "拉丁字母",
      "localeCode": "na_NR/en_NR",
      "timezone": "UTC+12",
      "currencyName": "澳大利亚元",
      "currencyCode": "AUD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+674",
      "emoji": "🇳🇷",
      "ccTLD": ".nr"
    },
    {
      "officialName_en": "Cook Islands",
      "abbreviation_en": "COK",
      "officialName_cn": "库克群岛",
      "abbreviation_cn": "库克群岛",
      "officialLanguage": "英语、库克群岛毛利语",
      "officialScript": "拉丁字母",
      "localeCode": "en_CK/rar_CK",
      "timezone": "UTC-10",
      "currencyName": "新西兰元",
      "currencyCode": "NZD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+682",
      "emoji": "🇨🇰",
      "ccTLD": ".ck"
    },
    {
      "officialName_en": "Niue",
      "abbreviation_en": "NIU",
      "officialName_cn": "纽埃",
      "abbreviation_cn": "纽埃",
      "officialLanguage": "英语、纽埃语",
      "officialScript": "拉丁字母",
      "localeCode": "en_NU/niu_NU",
      "timezone": "UTC-11",
      "currencyName": "新西兰元",
      "currencyCode": "NZD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+683",
      "emoji": "🇳🇺",
      "ccTLD": ".nu"
    },
    {
      "officialName_en": "Hong Kong",
      "abbreviation_en": "HKG",
      "officialName_cn": "中华人民共和国香港特别行政区",
      "abbreviation_cn": "香港",
      "officialLanguage": "中文、英文",
      "officialScript": "繁体中文、拉丁字母",
      "localeCode": "zh_HK/en_HK",
      "timezone": "UTC+8",
      "currencyName": "港元",
      "currencyCode": "HKD",
      "currencySymbol": "$",
      "continent": "亚洲",
      "regionCode": "+852",
      "emoji": "🇭🇰",
      "ccTLD": ".hk"
    },
    {
      "officialName_en": "Greenland",
      "abbreviation_en": "GRL",
      "officialName_cn": "格陵兰",
      "abbreviation_cn": "格陵兰",
      "officialLanguage": "格陵兰语、丹麦语",
      "officialScript": "拉丁字母",
      "localeCode": "kl_GL/da_GL",
      "timezone": "UTC-4 to UTC-1",
      "currencyName": "丹麦克朗",
      "currencyCode": "DKK",
      "currencySymbol": "kr",
      "continent": "北美洲",
      "regionCode": "+299",
      "emoji": "🇬🇱",
      "ccTLD": ".gl"
    },
    {
      "officialName_en": "Faroe Islands",
      "abbreviation_en": "FRO",
      "officialName_cn": "法罗群岛",
      "abbreviation_cn": "法罗群岛",
      "officialLanguage": "法罗语、丹麦语",
      "officialScript": "拉丁字母",
      "localeCode": "fo_FO/da_FO",
      "timezone": "UTC+0",
      "currencyName": "法罗群岛克朗",
      "currencyCode": "FOK",
      "currencySymbol": "kr",
      "continent": "欧洲",
      "regionCode": "+298",
      "emoji": "🇫🇴",
      "ccTLD": ".fo"
    },
    {
      "officialName_en": "Puerto Rico",
      "abbreviation_en": "PRI",
      "officialName_cn": "波多黎各自由邦",
      "abbreviation_cn": "波多黎各",
      "officialLanguage": "西班牙语、英语",
      "officialScript": "拉丁字母",
      "localeCode": "es_PR/en_PR",
      "timezone": "UTC-4",
      "currencyName": "美元",
      "currencyCode": "USD",
      "currencySymbol": "$",
      "continent": "北美洲",
      "regionCode": "+1-787/+1-939",
      "emoji": "🇵🇷",
      "ccTLD": ".pr"
    },
    {
      "officialName_en": "New Caledonia",
      "abbreviation_en": "NCL",
      "officialName_cn": "新喀里多尼亚",
      "abbreviation_cn": "新喀里多尼亚",
      "officialLanguage": "法语",
      "officialScript": "拉丁字母",
      "localeCode": "fr_NC",
      "timezone": "UTC+11",
      "currencyName": "太平洋法郎",
      "currencyCode": "XPF",
      "currencySymbol": "₣",
      "continent": "大洋洲",
      "regionCode": "+687",
      "emoji": "🇳🇨",
      "ccTLD": ".nc"
    },
    {
      "officialName_en": "French Polynesia",
      "abbreviation_en": "PYF",
      "officialName_cn": "法属波利尼西亚",
      "abbreviation_cn": "法属波利尼西亚",
      "officialLanguage": "法语、塔希提语",
      "officialScript": "拉丁字母",
      "localeCode": "fr_PF/ty_PF",
      "timezone": "UTC-10",
      "currencyName": "太平洋法郎",
      "currencyCode": "XPF",
      "currencySymbol": "₣",
      "continent": "大洋洲",
      "regionCode": "+689",
      "emoji": "🇵🇫",
      "ccTLD": ".pf"
    },
    {
      "officialName_en": "Guam",
      "abbreviation_en": "GUM",
      "officialName_cn": "关岛",
      "abbreviation_cn": "关岛",
      "officialLanguage": "英语、查莫罗语",
      "officialScript": "拉丁字母",
      "localeCode": "en_GU/ch_GU",
      "timezone": "UTC+10",
      "currencyName": "美元",
      "currencyCode": "USD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+1-671",
      "emoji": "🇬🇺",
      "ccTLD": ".gu"
    },
    {
      "officialName_en": "U.S. Virgin Islands",
      "abbreviation_en": "VIR",
      "officialName_cn": "美属维尔京群岛",
      "abbreviation_cn": "美属维尔京群岛",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_VI",
      "timezone": "UTC-4",
      "currencyName": "美元",
      "currencyCode": "USD",
      "currencySymbol": "$",
      "continent": "北美洲",
      "regionCode": "+1-340",
      "emoji": "🇻🇮",
      "ccTLD": ".vi"
    },
    {
      "officialName_en": "American Samoa",
      "abbreviation_en": "ASM",
      "officialName_cn": "美属萨摩亚",
      "abbreviation_cn": "美属萨摩亚",
      "officialLanguage": "英语、萨摩亚语",
      "officialScript": "拉丁字母",
      "localeCode": "en_AS/sm_AS",
      "timezone": "UTC-11",
      "currencyName": "美元",
      "currencyCode": "USD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+1-684",
      "emoji": "🇦🇸",
      "ccTLD": ".as"
    },
    {
      "officialName_en": "Northern Mariana Islands",
      "abbreviation_en": "MNP",
      "officialName_cn": "北马里亚纳群岛",
      "abbreviation_cn": "北马里亚纳群岛",
      "officialLanguage": "英语、查莫罗语",
      "officialScript": "拉丁字母",
      "localeCode": "en_MP/ch_MP",
      "timezone": "UTC+10",
      "currencyName": "美元",
      "currencyCode": "USD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+1-670",
      "emoji": "🇲🇵",
      "ccTLD": ".mp"
    },
    {
      "officialName_en": "Taiwan",
      "abbreviation_en": "TWN",
      "officialName_cn": "中华民国",
      "abbreviation_cn": "台湾",
      "officialLanguage": "中文",
      "officialScript": "繁体中文",
      "localeCode": "zh_TW",
      "timezone": "UTC+8",
      "currencyName": "新台币",
      "currencyCode": "TWD",
      "currencySymbol": "NT$",
      "continent": "亚洲",
      "regionCode": "+886",
      "emoji": "🇹🇼",
      "ccTLD": ".tw"
    },
    {
      "officialName_en": "Gibraltar",
      "abbreviation_en": "GIB",
      "officialName_cn": "直布罗陀",
      "abbreviation_cn": "直布罗陀",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_GI",
      "timezone": "UTC+1",
      "currencyName": "直布罗陀镑",
      "currencyCode": "GIP",
      "currencySymbol": "£",
      "continent": "欧洲",
      "regionCode": "+350",
      "emoji": "🇬🇮",
      "ccTLD": ".gi"
    },
    {
      "officialName_en": "Falkland Islands",
      "abbreviation_en": "FLK",
      "officialName_cn": "福克兰群岛",
      "abbreviation_cn": "福克兰群岛",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_FK",
      "timezone": "UTC-4",
      "currencyName": "福克兰群岛镑",
      "currencyCode": "FKP",
      "currencySymbol": "£",
      "continent": "南美洲",
      "regionCode": "+500",
      "emoji": "🇫🇰",
      "ccTLD": ".fk"
    },
    {
      "officialName_en": "Guernsey",
      "abbreviation_en": "GGY",
      "officialName_cn": "根西岛",
      "abbreviation_cn": "根西岛",
      "officialLanguage": "英语、法语",
      "officialScript": "拉丁字母",
      "localeCode": "en_GG/fr_GG",
      "timezone": "UTC+0",
      "currencyName": "根西镑",
      "currencyCode": "GGP",
      "currencySymbol": "£",
      "continent": "欧洲",
      "regionCode": "+44-1481",
      "emoji": "🇬🇬",
      "ccTLD": ".gg"
    },
    {
      "officialName_en": "Jersey",
      "abbreviation_en": "JEY",
      "officialName_cn": "泽西岛",
      "abbreviation_cn": "泽西岛",
      "officialLanguage": "英语、法语",
      "officialScript": "拉丁字母",
      "localeCode": "en_JE/fr_JE",
      "timezone": "UTC+0",
      "currencyName": "泽西镑",
      "currencyCode": "JEP",
      "currencySymbol": "£",
      "continent": "欧洲",
      "regionCode": "+44-1534",
      "emoji": "🇯🇪",
      "ccTLD": ".je"
    },
    {
      "officialName_en": "Isle of Man",
      "abbreviation_en": "IMN",
      "officialName_cn": "马恩岛",
      "abbreviation_cn": "马恩岛",
      "officialLanguage": "英语、马恩语",
      "officialScript": "拉丁字母",
      "localeCode": "en_IM/gv_IM",
      "timezone": "UTC+0",
      "currencyName": "马恩岛镑",
      "currencyCode": "IMP",
      "currencySymbol": "£",
      "continent": "欧洲",
      "regionCode": "+44-1624",
      "emoji": "🇮🇲",
      "ccTLD": ".im"
    },
    {
      "officialName_en": "Cayman Islands",
      "abbreviation_en": "CYM",
      "officialName_cn": "开曼群岛",
      "abbreviation_cn": "开曼群岛",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_KY",
      "timezone": "UTC-5",
      "currencyName": "开曼群岛元",
      "currencyCode": "KYD",
      "currencySymbol": "$",
      "continent": "北美洲",
      "regionCode": "+1-345",
      "emoji": "🇰🇾",
      "ccTLD": ".ky"
    },
    {
      "officialName_en": "Bermuda",
      "abbreviation_en": "BMU",
      "officialName_cn": "百慕大",
      "abbreviation_cn": "百慕大",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_BM",
      "timezone": "UTC-4",
      "currencyName": "百慕大元",
      "currencyCode": "BMD",
      "currencySymbol": "$",
      "continent": "北美洲",
      "regionCode": "+1-441",
      "emoji": "🇧🇲",
      "ccTLD": ".bm"
    },
    {
      "officialName_en": "Anguilla",
      "abbreviation_en": "AIA",
      "officialName_cn": "安圭拉",
      "abbreviation_cn": "安圭拉",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_AI",
      "timezone": "UTC-4",
      "currencyName": "东加勒比元",
      "currencyCode": "XCD",
      "currencySymbol": "$",
      "continent": "北美洲",
      "regionCode": "+1-264",
      "emoji": "🇦🇮",
      "ccTLD": ".ai"
    },
    {
      "officialName_en": "Tokelau",
      "abbreviation_en": "TKL",
      "officialName_cn": "托克劳",
      "abbreviation_cn": "托克劳",
      "officialLanguage": "英语、托克劳语",
      "officialScript": "拉丁字母",
      "localeCode": "en_TK/tkl_TK",
      "timezone": "UTC+13",
      "currencyName": "新西兰元",
      "currencyCode": "NZD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+690",
      "emoji": "🇹🇰",
      "ccTLD": ".tk"
    },
    {
      "officialName_en": "Sint Maarten",
      "abbreviation_en": "SXM",
      "officialName_cn": "荷属圣马丁",
      "abbreviation_cn": "荷属圣马丁",
      "officialLanguage": "荷兰语、英语",
      "officialScript": "拉丁字母",
      "localeCode": "nl_SX/en_SX",
      "timezone": "UTC-4",
      "currencyName": "荷属安的列斯盾",
      "currencyCode": "ANG",
      "currencySymbol": "ƒ",
      "continent": "北美洲",
      "regionCode": "+1-721",
      "emoji": "🇸🇽",
      "ccTLD": ".sx"
    },
    {
      "officialName_en": "Curaçao",
      "abbreviation_en": "CUW",
      "officialName_cn": "库拉索",
      "abbreviation_cn": "库拉索",
      "officialLanguage": "荷兰语、帕皮亚门托语、英语",
      "officialScript": "拉丁字母",
      "localeCode": "nl_CW/pap_CW/en_CW",
      "timezone": "UTC-4",
      "currencyName": "荷属安的列斯盾",
      "currencyCode": "ANG",
      "currencySymbol": "ƒ",
      "continent": "北美洲",
      "regionCode": "+599",
      "emoji": "🇨🇼",
      "ccTLD": ".cw"
    },
    {
      "officialName_en": "Aruba",
      "abbreviation_en": "ABW",
      "officialName_cn": "阿鲁巴",
      "abbreviation_cn": "阿鲁巴",
      "officialLanguage": "荷兰语、帕皮亚门托语",
      "officialScript": "拉丁字母",
      "localeCode": "nl_AW/pap_AW",
      "timezone": "UTC-4",
      "currencyName": "阿鲁巴弗罗林",
      "currencyCode": "AWG",
      "currencySymbol": "ƒ",
      "continent": "北美洲",
      "regionCode": "+297",
      "emoji": "🇦🇼",
      "ccTLD": ".aw"
    },
    {
      "officialName_en": "French Guiana",
      "abbreviation_en": "GUF",
      "officialName_cn": "法属圭亚那",
      "abbreviation_cn": "法属圭亚那",
      "officialLanguage": "法语",
      "officialScript": "拉丁字母",
      "localeCode": "fr_GF",
      "timezone": "UTC-3",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "南美洲",
      "regionCode": "+594",
      "emoji": "🇬🇫",
      "ccTLD": ".gf"
    },
    {
      "officialName_en": "Svalbard and Jan Mayen",
      "abbreviation_en": "SJM",
      "officialName_cn": "斯瓦尔巴和扬马延",
      "abbreviation_cn": "斯瓦尔巴",
      "officialLanguage": "挪威语",
      "officialScript": "拉丁字母",
      "localeCode": "no_SJ",
      "timezone": "UTC+1",
      "currencyName": "挪威克朗",
      "currencyCode": "NOK",
      "currencySymbol": "kr",
      "continent": "欧洲",
      "regionCode": "+47",
      "emoji": "🇸🇯",
      "ccTLD": ".sj"
    },
    {
      "officialName_en": "Pitcairn Islands",
      "abbreviation_en": "PCN",
      "officialName_cn": "皮特凯恩群岛",
      "abbreviation_cn": "皮特凯恩",
      "officialLanguage": "英语、皮特凯恩语",
      "officialScript": "拉丁字母",
      "localeCode": "en_PN/pih_PN",
      "timezone": "UTC-8",
      "currencyName": "新西兰元",
      "currencyCode": "NZD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+64",
      "emoji": "🇵🇳",
      "ccTLD": ".pn"
    },
    {
      "officialName_en": "Christmas Island",
      "abbreviation_en": "CXR",
      "officialName_cn": "圣诞岛",
      "abbreviation_cn": "圣诞岛",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_CX",
      "timezone": "UTC+7",
      "currencyName": "澳大利亚元",
      "currencyCode": "AUD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+61",
      "emoji": "🇨🇽",
      "ccTLD": ".cx"
    },
    {
      "officialName_en": "Cocos (Keeling) Islands",
      "abbreviation_en": "CCK",
      "officialName_cn": "科科斯（基林）群岛",
      "abbreviation_cn": "科科斯群岛",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_CC",
      "timezone": "UTC+6:30",
      "currencyName": "澳大利亚元",
      "currencyCode": "AUD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+61",
      "emoji": "🇨🇨",
      "ccTLD": ".cc"
    },
    {
      "officialName_en": "Norfolk Island",
      "abbreviation_en": "NFK",
      "officialName_cn": "诺福克岛",
      "abbreviation_cn": "诺福克岛",
      "officialLanguage": "英语、诺福克语",
      "officialScript": "拉丁字母",
      "localeCode": "en_NF/pih_NF",
      "timezone": "UTC+11:30",
      "currencyName": "澳大利亚元",
      "currencyCode": "AUD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+672",
      "emoji": "🇳🇫",
      "ccTLD": ".nf"
    },
    {
      "officialName_en": "Wallis and Futuna",
      "abbreviation_en": "WLF",
      "officialName_cn": "瓦利斯和富图纳",
      "abbreviation_cn": "瓦利斯和富图纳",
      "officialLanguage": "法语",
      "officialScript": "拉丁字母",
      "localeCode": "fr_WF",
      "timezone": "UTC+12",
      "currencyName": "太平洋法郎",
      "currencyCode": "XPF",
      "currencySymbol": "₣",
      "continent": "大洋洲",
      "regionCode": "+681",
      "emoji": "🇼🇫",
      "ccTLD": ".wf"
    },
    {
      "officialName_en": "Western Sahara",
      "abbreviation_en": "ESH",
      "officialName_cn": "西撒哈拉",
      "abbreviation_cn": "西撒哈拉",
      "officialLanguage": "阿拉伯语",
      "officialScript": "阿拉伯字母",
      "localeCode": "ar_EH",
      "timezone": "UTC+0",
      "currencyName": "摩洛哥迪拉姆",
      "currencyCode": "MAD",
      "currencySymbol": "د.م.",
      "continent": "非洲",
      "regionCode": "+212",
      "emoji": "🇪🇭",
      "ccTLD": ".eh"
    },
    {
      "officialName_en": "Palestinian Territories",
      "abbreviation_en": "PSE",
      "officialName_cn": "巴勒斯坦国",
      "abbreviation_cn": "巴勒斯坦",
      "officialLanguage": "阿拉伯语",
      "officialScript": "阿拉伯字母",
      "localeCode": "ar_PS",
      "timezone": "UTC+2",
      "currencyName": "以色列新谢克尔",
      "currencyCode": "ILS",
      "currencySymbol": "₪",
      "continent": "亚洲",
      "regionCode": "+970",
      "emoji": "🇵🇸",
      "ccTLD": ".ps"
    },
    {
      "officialName_en": "Transnistria",
      "abbreviation_en": "-",
      "officialName_cn": "德涅斯特河沿岸",
      "abbreviation_cn": "德涅斯特河",
      "officialLanguage": "俄语、乌克兰语、摩尔多瓦语",
      "officialScript": "西里尔字母",
      "localeCode": "-",
      "timezone": "UTC+2",
      "currencyName": "德涅斯特卢布",
      "currencyCode": "PRB",
      "currencySymbol": "р.",
      "continent": "欧洲",
      "regionCode": "+373",
      "emoji": "-",
      "ccTLD": "-"
    },
    {
      "officialName_en": "Somaliland",
      "abbreviation_en": "-",
      "officialName_cn": "索马里兰共和国",
      "abbreviation_cn": "索马里兰",
      "officialLanguage": "索马里语、阿拉伯语、英语",
      "officialScript": "拉丁字母、阿拉伯字母",
      "localeCode": "-",
      "timezone": "UTC+3",
      "currencyName": "索马里先令",
      "currencyCode": "SOS",
      "currencySymbol": "Sh.so.",
      "continent": "非洲",
      "regionCode": "+252",
      "emoji": "-",
      "ccTLD": "-"
    },
    {
      "officialName_en": "South Ossetia",
      "abbreviation_en": "-",
      "officialName_cn": "南奥塞梯共和国",
      "abbreviation_cn": "南奥塞梯",
      "officialLanguage": "俄语、奥塞梯语",
      "officialScript": "西里尔字母",
      "localeCode": "-",
      "timezone": "UTC+4",
      "currencyName": "俄罗斯卢布",
      "currencyCode": "RUB",
      "currencySymbol": "₽",
      "continent": "亚洲",
      "regionCode": "+7",
      "emoji": "-",
      "ccTLD": "-"
    },
    {
      "officialName_en": "Abkhazia",
      "abbreviation_en": "-",
      "officialName_cn": "阿布哈兹共和国",
      "abbreviation_cn": "阿布哈兹",
      "officialLanguage": "阿布哈兹语、俄语",
      "officialScript": "西里尔字母",
      "localeCode": "-",
      "timezone": "UTC+4",
      "currencyName": "俄罗斯卢布",
      "currencyCode": "RUB",
      "currencySymbol": "₽",
      "continent": "亚洲",
      "regionCode": "+7",
      "emoji": "-",
      "ccTLD": "-"
    },
    {
      "officialName_en": "Northern Cyprus",
      "abbreviation_en": "-",
      "officialName_cn": "北塞浦路斯土耳其共和国",
      "abbreviation_cn": "北塞浦路斯",
      "officialLanguage": "土耳其语",
      "officialScript": "拉丁字母",
      "localeCode": "-",
      "timezone": "UTC+2",
      "currencyName": "土耳其里拉",
      "currencyCode": "TRY",
      "currencySymbol": "₺",
      "continent": "欧洲",
      "regionCode": "+90",
      "emoji": "-",
      "ccTLD": "-"
    },
    {
      "officialName_en": "British Indian Ocean Territory",
      "abbreviation_en": "IOT",
      "officialName_cn": "英属印度洋领地",
      "abbreviation_cn": "英属印度洋领地",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_IO",
      "timezone": "UTC+6",
      "currencyName": "美元",
      "currencyCode": "USD",
      "currencySymbol": "$",
      "continent": "亚洲",
      "regionCode": "+246",
      "emoji": "🇮🇴",
      "ccTLD": ".io"
    },
    {
      "officialName_en": "Mayotte",
      "abbreviation_en": "MYT",
      "officialName_cn": "马约特",
      "abbreviation_cn": "马约特",
      "officialLanguage": "法语",
      "officialScript": "拉丁字母",
      "localeCode": "fr_YT",
      "timezone": "UTC+3",
      "currencyName": "欧元",
      "currencyCode": "EUR",
      "currencySymbol": "€",
      "continent": "非洲",
      "regionCode": "+262",
      "emoji": "🇾🇹",
      "ccTLD": ".yt"
    },
    {
      "officialName_en": "Saint Helena/Ascension and Tristan da Cunha",
      "abbreviation_en": "SHN",
      "officialName_cn": "圣赫勒拿、阿森松和特里斯坦-达库尼亚",
      "abbreviation_cn": "圣赫勒拿",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_SH",
      "timezone": "UTC+0",
      "currencyName": "圣赫勒拿镑",
      "currencyCode": "SHP",
      "currencySymbol": "£",
      "continent": "非洲",
      "regionCode": "+290",
      "emoji": "🇸🇭",
      "ccTLD": ".sh"
    },
    {
      "officialName_en": "Bouvet Island",
      "abbreviation_en": "BVT",
      "officialName_cn": "布韦岛",
      "abbreviation_cn": "布韦岛",
      "officialLanguage": "挪威语",
      "officialScript": "拉丁字母",
      "localeCode": "no_BV",
      "timezone": "UTC+1",
      "currencyName": "挪威克朗",
      "currencyCode": "NOK",
      "currencySymbol": "kr",
      "continent": "南极洲",
      "regionCode": "+47",
      "emoji": "🇧🇻",
      "ccTLD": ".bv"
    },
    {
      "officialName_en": "Heard Island and McDonald Islands",
      "abbreviation_en": "HMD",
      "officialName_cn": "赫德岛和麦克唐纳群岛",
      "abbreviation_cn": "赫德岛",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_HM",
      "timezone": "UTC+5",
      "currencyName": "澳大利亚元",
      "currencyCode": "AUD",
      "currencySymbol": "$",
      "continent": "南极洲",
      "regionCode": "+672",
      "emoji": "🇭🇲",
      "ccTLD": ".hm"
    },
    {
      "officialName_en": "South Georgia and the South Sandwich Islands",
      "abbreviation_en": "SGS",
      "officialName_cn": "南乔治亚和南桑威奇群岛",
      "abbreviation_cn": "南乔治亚",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_GS",
      "timezone": "UTC-2",
      "currencyName": "英镑",
      "currencyCode": "GBP",
      "currencySymbol": "£",
      "continent": "南美洲",
      "regionCode": "+500",
      "emoji": "🇬🇸",
      "ccTLD": ".gs"
    },
    {
      "officialName_en": "United States Minor Outlying Islands",
      "abbreviation_en": "UMI",
      "officialName_cn": "美国本土外小岛屿",
      "abbreviation_cn": "美国外岛",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_UM",
      "timezone": "UTC-11 to UTC+12",
      "currencyName": "美元",
      "currencyCode": "USD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+1",
      "emoji": "🇺🇲",
      "ccTLD": ".um"
    },
    {
      "officialName_en": "Baker Island",
      "abbreviation_en": "-",
      "officialName_cn": "贝克岛",
      "abbreviation_cn": "贝克岛",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_UM",
      "timezone": "UTC+12",
      "currencyName": "美元",
      "currencyCode": "USD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+1",
      "emoji": "🇺🇲",
      "ccTLD": "-"
    },
    {
      "officialName_en": "Howland Island",
      "abbreviation_en": "-",
      "officialName_cn": "豪兰岛",
      "abbreviation_cn": "豪兰岛",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_UM",
      "timezone": "UTC+12",
      "currencyName": "美元",
      "currencyCode": "USD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+1",
      "emoji": "🇺🇲",
      "ccTLD": "-"
    },
    {
      "officialName_en": "Jarvis Island",
      "abbreviation_en": "-",
      "officialName_cn": "贾维斯岛",
      "abbreviation_cn": "贾维斯岛",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_UM",
      "timezone": "UTC+12",
      "currencyName": "美元",
      "currencyCode": "USD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+1",
      "emoji": "🇺🇲",
      "ccTLD": "-"
    },
    {
      "officialName_en": "Johnston Atoll",
      "abbreviation_en": "-",
      "officialName_cn": "约翰斯顿环礁",
      "abbreviation_cn": "约翰斯顿岛",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_UM",
      "timezone": "UTC-10",
      "currencyName": "美元",
      "currencyCode": "USD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+1",
      "emoji": "🇺🇲",
      "ccTLD": "-"
    },
    {
      "officialName_en": "Kingman Reef",
      "abbreviation_en": "-",
      "officialName_cn": "金曼礁",
      "abbreviation_cn": "金曼礁",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_UM",
      "timezone": "UTC-11",
      "currencyName": "美元",
      "currencyCode": "USD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+1",
      "emoji": "🇺🇲",
      "ccTLD": "-"
    },
    {
      "officialName_en": "Midway Islands",
      "abbreviation_en": "-",
      "officialName_cn": "中途岛",
      "abbreviation_cn": "中途岛",
      "officialLanguage": "英语",
      "officialScript": "拉丁字母",
      "localeCode": "en_UM",
      "timezone": "UTC-11",
      "currencyName": "美元",
      "currencyCode": "USD",
      "currencySymbol": "$",
      "continent": "大洋洲",
      "regionCode": "+1",
      "emoji": "🇺🇲",
      "ccTLD": "-"
    }
  ]

  export default regions;