import React, { useState, useContext } from 'react';
import { motion } from 'framer-motion';
import { parsePhoneNumber } from 'libphonenumber-js';
import { ThemeContext } from '../contexts/ThemeContext';
import { Phone } from 'lucide-react';

const PhoneNumberParser = () => {
  const { theme } = useContext(ThemeContext);
  const [inputNumber, setInputNumber] = useState('');
  const [parsedNumber, setParsedNumber] = useState(null);
  const [error, setError] = useState('');

  const handleParse = () => {
    try {
      const phoneNumber = parsePhoneNumber(inputNumber);
      setParsedNumber(phoneNumber);
      setError('');
    } catch (err) {
      setError('Invalid phone number');
      setParsedNumber(null);
    }
  };

  const inputClass = `w-full p-3 border rounded-lg bg-opacity-50 backdrop-filter backdrop-blur-lg ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'
    } focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300 ease-in-out`;

  const buttonClass = `px-6 py-3 rounded-full font-semibold text-white transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`;

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-blue-50 text-gray-800'} py-12 px-4 sm:px-6 lg:px-8`}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className={`max-w-3xl mx-auto ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} rounded-2xl overflow-hidden shadow-2xl`}
      >
        <div className="relative overflow-hidden p-8">
          {/* Mesh Gradient Background */}
          <div
            className="absolute inset-0 opacity-30"
            style={{
              background: `
                radial-gradient(circle at 0% 0%, #3b82f6 0%, transparent 50%),
                radial-gradient(circle at 100% 100%, #10b981 0%, transparent 50%),
                radial-gradient(circle at 50% 50%, #8b5cf6 0%, transparent 50%)
              `,
              filter: 'blur(100px)',
            }}
          />

          <div className="relative z-10">
            <motion.h1
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2, duration: 0.5 }}
              className="text-4xl font-extrabold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-green-500"
            >
              Phone Number Parser
            </motion.h1>

            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.3, duration: 0.5 }}
              className="mb-6"
            >
              <label className="block mb-2 font-medium">Enter Phone Number:</label>
              <input
                type="text"
                value={inputNumber}
                onChange={(e) => setInputNumber(e.target.value)}
                className={inputClass}
                placeholder="e.g. +1 234 567 8900"
              />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4, duration: 0.5 }}
              className="flex justify-center"
            >
              <button
                onClick={handleParse}
                className={`${buttonClass} bg-gradient-to-r from-blue-500 to-green-500`}
              >
                <Phone className="inline-block mr-2" size={20} />
                Parse Number
              </button>
            </motion.div>

            {error && (
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="text-red-500 mt-4 text-center"
              >
                {error}
              </motion.p>
            )}

            {parsedNumber && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 0.5 }}
                className={`mt-8 ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100'} p-6 rounded-lg`}
              >
                <h2 className="text-2xl font-semibold mb-4">Parsed Number Information:</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <InfoItem label="Formatted Number" value={parsedNumber.formatInternational()} />
                  <InfoItem label="Country" value={parsedNumber.country} />
                  <InfoItem label="Number Type" value={parsedNumber.getType()} />
                  <InfoItem label="Valid" value={parsedNumber.isValid() ? 'Yes' : 'No'} />
                  <InfoItem label="Possible" value={parsedNumber.isPossible() ? 'Yes' : 'No'} />
                  <InfoItem label="National Number" value={parsedNumber.nationalNumber} />
                  <InfoItem label="Country Calling Code" value={`+${parsedNumber.countryCallingCode}`} />
                </div>
              </motion.div>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

const InfoItem = ({ label, value }) => (
  <div className="bg-opacity-50 backdrop-filter backdrop-blur-lg bg-white dark:bg-gray-800 p-3 rounded-lg">
    <p className="text-sm font-medium text-gray-500 dark:text-gray-400">{label}</p>
    <p className="mt-1 text-lg font-semibold">{value}</p>
  </div>
);

export default PhoneNumberParser;