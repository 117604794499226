import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const CommandPalette = ({ isOpen, setIsOpen, commands }) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(0);
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const resultsRef = useRef(null);

    const filteredCommands = commands.filter(command =>
        t(command.name).toLowerCase().includes(search.toLowerCase()) ||
        t(command.desc).toLowerCase().includes(search.toLowerCase())
    );

    const handleKeyDown = useCallback((e) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setSelectedIndex(prevIndex =>
                prevIndex < filteredCommands.length - 1 ? prevIndex + 1 : prevIndex
            );
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setSelectedIndex(prevIndex => prevIndex > 0 ? prevIndex - 1 : prevIndex);
        } else if (e.key === 'Enter') {
            e.preventDefault();
            if (filteredCommands[selectedIndex]) {
                navigate(filteredCommands[selectedIndex].path);
                setIsOpen(false);
            }
        } else if (e.key === 'Escape') {
            setIsOpen(false);
        }
    }, [filteredCommands, selectedIndex, navigate, setIsOpen]);

    useEffect(() => {
        if (isOpen) {
            inputRef.current?.focus();
        }
    }, [isOpen]);

    useEffect(() => {
        setSelectedIndex(0);
    }, [search]);

    useEffect(() => {
        const selectedElement = resultsRef.current?.children[selectedIndex];
        if (selectedElement) {
            selectedElement.scrollIntoView({ block: 'nearest' });
        }
    }, [selectedIndex]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 backdrop-blur-sm">
            <div className="min-h-screen px-4 text-center">
                <div className="fixed inset-0" onClick={() => setIsOpen(false)} />
                <div className="inline-block w-full max-w-2xl p-4 sm:p-6 my-20 sm:my-40 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                    <div className="relative">
                        <Search className="absolute left-2 top-2 h-5 w-5 text-gray-400" />
                        <input
                            ref={inputRef}
                            type="text"
                            placeholder="Search commands..."
                            className="w-full pl-10 pr-10 py-2 text-sm text-gray-900 bg-gray-100 rounded-md focus:outline-none focus:bg-white focus:ring-2 focus:ring-blue-500"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                        <button
                            className="absolute right-2 top-2 text-gray-400 hover:text-gray-600"
                            onClick={() => setIsOpen(false)}
                        >
                            <X className="h-5 w-5" />
                        </button>
                    </div>
                    <div className="mt-4 max-h-96 overflow-y-auto" ref={resultsRef}>
                        {filteredCommands.map((command, index) => (
                            <div
                                key={index}
                                className={`p-2 rounded-md cursor-pointer ${index === selectedIndex ? 'bg-blue-100' : 'hover:bg-gray-100'
                                    }`}
                                onClick={() => {
                                    navigate(command.path);
                                    setIsOpen(false);
                                }}
                            >
                                <div className="flex items-center">
                                    {command.icon && (
                                        <command.icon className="h-6 w-6 mr-3 text-gray-500" />
                                    )}
                                    <div>
                                        <div className="font-medium">{t(command.name)}</div>
                                        <div className="text-sm text-gray-500">{t(command.desc)}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="mt-4 flex justify-between text-xs text-gray-500">
                        <div>
                            <span className="mr-2">
                                <kbd className="px-1 py-0.5 bg-gray-100 border border-gray-300 rounded">↑</kbd>
                                <kbd className="px-1 py-0.5 bg-gray-100 border border-gray-300 rounded">↓</kbd> to navigate
                            </span>
                            <span className="mr-2">
                                <kbd className="px-1 py-0.5 bg-gray-100 border border-gray-300 rounded">Enter</kbd> to select
                            </span>
                        </div>
                        <div>
                            <kbd className="px-1 py-0.5 bg-gray-100 border border-gray-300 rounded">Esc</kbd> to close
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommandPalette;