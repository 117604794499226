import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslations from './locales/en.json';
import zhTranslations from './locales/zh.json';
import frTranslations from './locales/fr.json';
import deTranslations from './locales/de.json';
import esTranslations from './locales/es.json';
import jaTranslations from './locales/ja.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslations },
      zh: { translation: zhTranslations },
      fr: { translation: frTranslations },
      de: { translation: deTranslations },
      es: { translation: esTranslations },
      ja: { translation: jaTranslations },
    },
    lng: navigator.language.split('-')[0], // 使用浏览器的语言设置
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;