import React, { useState, useContext, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ThemeContext } from '../contexts/ThemeContext';
import { Calculator } from 'lucide-react';

const ChargingTimeCalculator = () => {
  const { theme } = useContext(ThemeContext);
  const [batteryCapacity, setBatteryCapacity] = useState('');
  const [chargingPower, setChargingPower] = useState('');
  const [chargingTimes, setChargingTimes] = useState(null);
  const [error, setError] = useState('');

  // 重置计算结果
  useEffect(() => {
    setChargingTimes(null);
    setError('');
  }, [batteryCapacity, chargingPower]);


  const calculateChargingTime = () => {
    if (!batteryCapacity || !chargingPower) {
      setError('Please enter both battery capacity and charging power.');
      return;
    }

    const capacity = parseFloat(batteryCapacity);
    const power = parseFloat(chargingPower);

    if (isNaN(capacity) || isNaN(power)) {
      setError('Please enter valid numbers for both fields.');
      return;
    }

    if (capacity <= 0) {
      setError('Battery capacity must be greater than zero.');
      return;
    }

    if (power <= 0) {
      setError('Charging power must be greater than zero.');
      return;
    }

    const voltage = 3.7; // Assuming a typical Li-ion battery voltage
    const fullChargingTime = (capacity / 1000) * voltage / power;

    const times = {
      '25%': fullChargingTime * 0.25,
      '50%': fullChargingTime * 0.5,
      '75%': fullChargingTime * 0.75,
      '100%': fullChargingTime
    };

    setChargingTimes(times);
    setError('');
  };

  const formatTime = (hours) => {
    const h = Math.floor(hours);
    const m = Math.floor((hours - h) * 60);
    const s = Math.round(((hours - h) * 60 - m) * 60);
    return `${h.toString().padStart(2, '0')}h ${m.toString().padStart(2, '0')}m ${s.toString().padStart(2, '0')}s`;
  };

  const BatteryIcon = ({ percentage }) => (
    <svg width="50" height="25" viewBox="0 0 50 25" className="mr-2">
      <rect x="1" y="1" width="44" height="23" rx="2" ry="2" fill="#d1d5db" stroke="currentColor" strokeWidth="2" />
      <rect x="45" y="8" width="4" height="9" rx="1" ry="1" fill="currentColor" />
      <rect x="3" y="3" width={`${percentage * 0.4}`} height="19" fill="#10b981" />
      <text x="25" y="12.5" fontSize="12" fill="white" textAnchor="middle" dominantBaseline="middle">{percentage}%</text>
    </svg>
  );

  const inputClass = `w-full p-3 border rounded-lg bg-opacity-50 backdrop-filter backdrop-blur-lg ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'
    } focus:outline-none focus:ring-2 focus:ring-green-400 transition duration-300 ease-in-out`;

  const buttonClass = `px-6 py-3 rounded-full font-semibold text-white transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`;

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-green-50 text-gray-800'} py-12 px-4 sm:px-6 lg:px-8`}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className={`max-w-3xl mx-auto ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} rounded-2xl overflow-hidden shadow-2xl`}
      >
        <div className="relative overflow-hidden p-8">
          {/* Mesh Gradient Background */}
          <div
            className="absolute inset-0 opacity-30"
            style={{
              background: `
                radial-gradient(circle at 0% 0%, #10b981 0%, transparent 50%),
                radial-gradient(circle at 100% 100%, #3b82f6 0%, transparent 50%),
                radial-gradient(circle at 50% 50%, #f59e0b 0%, transparent 50%)
              `,
              filter: 'blur(100px)',
            }}
          />

          <div className="relative z-10">
            <motion.h1
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2, duration: 0.5 }}
              className="text-4xl font-extrabold mb-8 py-4 text-center bg-clip-text text-transparent bg-gradient-to-r from-green-500 to-blue-500"
            >
              Charging Time Calculator
            </motion.h1>

            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.3, duration: 0.5 }}
              className="mb-6"
            >
              <label className="block mb-2 font-medium">Battery Capacity (mAh):</label>
              <input
                type="number"
                value={batteryCapacity}
                onChange={(e) => setBatteryCapacity(e.target.value)}
                placeholder="e.g. 4000"
                className={inputClass}
              />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.4, duration: 0.5 }}
              className="mb-6"
            >
              <label className="block mb-2 font-medium">Charging Power (W):</label>
              <input
                type="number"
                value={chargingPower}
                onChange={(e) => setChargingPower(e.target.value)}
                placeholder="e.g. 18"
                className={inputClass}
              />
            </motion.div>

            {error && (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.5 }}
                className='text-xl font-extrabold mb-8 text-center text-red-400'
              >
                {error}
              </motion.div>
            )}

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 0.5 }}
              className="flex justify-center"
            >
              <button
                onClick={calculateChargingTime}
                className={`${buttonClass} bg-gradient-to-r from-green-500 to-blue-500`}
              >
                <Calculator className="inline-block mr-2" size={20} />
                Calculate Charging Time
              </button>
            </motion.div>

            {chargingTimes && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6, duration: 0.5 }}
                className="mt-8"
              >
                <h2 className="text-2xl font-semibold mb-4">Estimated Charging Times:</h2>
                <div className="space-y-4">
                  {Object.entries(chargingTimes).map(([percentage, time]) => (
                    <motion.div
                      key={percentage}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.7 + parseInt(percentage) / 200, duration: 0.5 }}
                      className={`flex items-center justify-between p-4 rounded-lg ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100'
                        }`}
                    >
                      <div className="flex items-center">
                        <BatteryIcon percentage={parseInt(percentage)} />
                        <span className="font-semibold">{percentage}</span>
                      </div>
                      <span className="font-mono">{formatTime(time)}</span>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default ChargingTimeCalculator;