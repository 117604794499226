import React, { useState, useEffect } from 'react';
import { Search, Globe, DollarSign, MoreHorizontal, ChevronLeft, ChevronRight } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import regions from '../data/regions';

const continentColors = {
  '亚洲': 'bg-blue-500 text-blue-500',
  '欧洲': 'bg-yellow-500 text-yellow-500',
  '非洲': 'bg-black text-black',
  '北美洲': 'bg-green-500 text-green-500',
  '南美洲': 'bg-red-500 text-red-500',
  '大洋洲': 'bg-purple-500 text-purple-500',
  '南极洲': 'bg-gray-500 text-gray-500',
};

const infoLabels = {
  timezone: '🕛',
  currencyCode: '💰',
  currencySymbol: '💱',
  regionCode: '📞',
  ccTLD: '🛜',
  localeCode: '🌐',
};

const ContinentToggle = ({ continent, colorClass, isSelected, onClick }) => {
  const [bgClass, textClass] = colorClass.split(' ');
  return (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className={`px-4 py-2 rounded-full text-xl font-medium transition-colors duration-200 w-28 flex items-center justify-center ${isSelected
          ? `${bgClass} text-white`
          : `bg-white ${textClass}`
        }`}
      onClick={onClick}
    >
      <span className="truncate">{continent}</span>
    </motion.button>
  );
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <motion.div
      className="inline-flex items-center rounded-full bg-white bg-opacity-80 shadow-lg backdrop-filter backdrop-blur-sm border border-gray-200 overflow-hidden"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <motion.button
        whileHover={{ backgroundColor: "#f3f4f6" }}
        whileTap={{ scale: 0.95 }}
        className="px-3 py-2 focus:outline-none"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <ChevronLeft className="h-6 w-6" />
      </motion.button>
      <span className="px-3 py-2 text-md font-medium text-gray-700">
        第 {currentPage} 页 / 共 {totalPages} 页
      </span>
      <motion.button
        whileHover={{ backgroundColor: "#f3f4f6" }}
        whileTap={{ scale: 0.95 }}
        className="px-3 py-2 focus:outline-none"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <ChevronRight className="h-6 w-6" />
      </motion.button>
    </motion.div>
  );
};

const RegionsExplorer = () => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [selectedContinents, setSelectedContinents] = useState(Object.keys(continentColors));
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedCards, setExpandedCards] = useState({});

  const itemsPerPage = 30;

  useEffect(() => {
    setData(regions);
    setFilteredData(regions);
  }, []);

  useEffect(() => {
    let filtered = data.filter(item =>
      Object.values(item).some(value =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    if (selectedContinents.length > 0) {
      filtered = filtered.filter(item => selectedContinents.includes(item.continent));
    }

    setFilteredData(filtered);
    setCurrentPage(1);
  }, [searchTerm, data, selectedContinents]);

  const highlightText = (text, highlight) => {
    if (!highlight.trim() || !text) {
      return <span>{text}</span>;
    }
    const regex = new RegExp(`(${highlight})`, 'gi');
    const parts = text.split(regex);
    return (
      <span>
        {parts.filter(String).map((part, i) =>
          regex.test(part) ? <mark key={i} className="bg-yellow-200 rounded">{part}</mark> : <span key={i}>{part}</span>
        )}
      </span>
    );
  };

  const toggleContinent = (continent) => {
    setSelectedContinents(prev =>
      prev.includes(continent)
        ? prev.filter(c => c !== continent)
        : [...prev, continent]
    );
  };

  const toggleCardExpansion = (index) => {
    setExpandedCards(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 to-purple-100 p-8">
      <div className="max-w-7xl mx-auto">
        <motion.h1
          className="text-4xl font-bold text-gray-800 mb-8 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Regions Explorer
        </motion.h1>

        <div className="mb-8">
          <motion.div
            className="relative mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <input
              type="text"
              className="w-full py-4 pl-12 pr-4 text-gray-700 bg-white bg-opacity-80 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent shadow-lg backdrop-filter backdrop-blur-sm"
              placeholder="搜索国家、地区、语言..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
              <Search className="h-6 w-6 text-gray-400" aria-hidden="true" />
            </div>
          </motion.div>
          <motion.div
            className="flex flex-wrap justify-center gap-2 mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            {Object.entries(continentColors).map(([continent, colorClass]) => (
              <ContinentToggle
                key={continent}
                continent={continent}
                colorClass={colorClass}
                isSelected={selectedContinents.includes(continent)}
                onClick={() => toggleContinent(continent)}
              />
            ))}
          </motion.div>
          <hr className="border-gray-300 my-6" />
        </div>

        <AnimatePresence mode="wait">
          {paginatedData.length > 0 ? (
            <motion.div
              key="content"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {paginatedData.map((item, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: index * 0.05 }}
                    className={`bg-white bg-opacity-75 rounded-xl shadow-lg overflow-hidden backdrop-filter backdrop-blur-lg border border-gray-200 hover:shadow-xl transition duration-300 ${continentColors[item.continent]?.split(' ')[0] || 'bg-gray-500'}`}
                  >
                    <div className="p-6">
                      <div className="flex items-center justify-between mb-2">
                        <div>
                          <h2 className="text-4xl font-semibold text-gray-800">{highlightText(item.abbreviation_cn, searchTerm)}</h2>
                          <p className="text-md text-gray-500">{highlightText(item.officialName_cn, searchTerm)}</p>
                        </div>
                        <span className="text-7xl">{item.emoji}</span>
                      </div>
                      <div className="space-y-2">
                        <p className="flex items-center text-gray-600">
                          <Globe className="h-6 w-6 mr-2 text-blue-500" />
                          {highlightText(item.officialName_en, searchTerm)} ({item.abbreviation_en})
                        </p>
                        <p className="flex items-center text-gray-600">
                          <DollarSign className="h-6 w-6 mr-2 text-yellow-500" />
                          {highlightText(item.currencyName, searchTerm)} ({item.currencyCode})
                        </p>
                      </div>
                    </div>
                    <div className="px-4 py-3 flex flex-wrap gap-2">
                      {Object.entries(infoLabels).slice(0, expandedCards[index] ? undefined : 5).map(([key, emoji]) => (
                        <span
                          key={key}
                          className="inline-flex items-center px-4 py-2 rounded-full text-sm font-medium bg-gray-100 text-gray-800"
                          title={key}
                        >
                          {emoji} {item[key]}
                        </span>
                      ))}
                      {!expandedCards[index] && Object.keys(infoLabels).length > 5 && (
                        <button
                          onClick={() => toggleCardExpansion(index)}
                          className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
                        >
                          <MoreHorizontal className="h-4 w-4" />
                        </button>
                      )}
                    </div>
                  </motion.div>
                ))}
              </div>
              <div className="mt-8 flex justify-center">
                <Pagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(filteredData.length / itemsPerPage)}
                  onPageChange={setCurrentPage}
                />
              </div>
            </motion.div>
          ) : (
            <motion.div
              key="no-results"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="text-center py-12 bg-white bg-opacity-70 rounded-xl shadow-lg backdrop-filter backdrop-blur-sm"
            >
              <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <h3 className="mt-2 text-lg font-medium text-gray-900">未找到匹配结果</h3>
              <p className="mt-1 text-gray-500">请尝试不同的搜索词或检查拼写。</p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default RegionsExplorer;