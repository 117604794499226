import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './components/layout/NavBar';
import Footer from './components/layout/Footer';

import routes from './routes';
import { ThemeContext } from './contexts/ThemeContext';
import { AuthProvider } from './components/features/login/AuthProvider';
import './i18n';
import './App.css';

const App = () => {
  const [theme, setTheme] = useState('light');

  const toggleTheme = () => {
    setTheme(prevTheme => prevTheme === 'light' ? 'dark' : 'light');
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <AuthProvider>
      <Router>
        <div className={`App ${theme} flex flex-col min-h-screen`}>
          <NavBar />
          <main className="flex-grow pt-16">
            
          <Routes>
            {routes.map(({ path, Component }) => (
              <Route key={path} path={path} element={<Component />} />
            ))}
          </Routes>
          </main>

          <Footer />
        </div>
      </Router>
      </AuthProvider>
    </ThemeContext.Provider>
  );
};

export default App;