import React, { useState, useMemo } from 'react';
import { Search } from 'lucide-react';

const iPhoneData = [
  {
    name: "iPhone 15 Pro Max",
    model: "A2849",
    id: "iPhone16,2",
    family: "iPhone 15 Pro Max",
    intro: "September 12, 2023",
    disc: "N/A",
    region: "United States",
    minIOS: "17.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 15 Pro Max",
    model: "A3105",
    id: "iPhone16,2",
    family: "iPhone 15 Pro Max",
    intro: "September 12, 2023",
    disc: "N/A",
    region: "Canada, Mexico, Japan, Saudi Arabia",
    minIOS: "17.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 15 Pro Max",
    model: "A3106",
    id: "iPhone16,2",
    family: "iPhone 15 Pro Max",
    intro: "September 12, 2023",
    disc: "N/A",
    region: "Global",
    minIOS: "17.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 15 Pro Max",
    model: "A3108",
    id: "iPhone16,2",
    family: "iPhone 15 Pro Max",
    intro: "September 12, 2023",
    disc: "N/A",
    region: "China",
    minIOS: "17.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 15 Pro",
    model: "A2848",
    id: "iPhone16,1",
    family: "iPhone 15 Pro",
    intro: "September 12, 2023",
    disc: "N/A",
    region: "United States",
    minIOS: "17.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 15 Pro",
    model: "A3101",
    id: "iPhone16,1",
    family: "iPhone 15 Pro",
    intro: "September 12, 2023",
    disc: "N/A",
    region: "Canada, Mexico, Japan, Saudi Arabia",
    minIOS: "17.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 15 Pro",
    model: "A3102",
    id: "iPhone16,1",
    family: "iPhone 15 Pro",
    intro: "September 12, 2023",
    disc: "N/A",
    region: "Global",
    minIOS: "17.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 15 Pro",
    model: "A3104",
    id: "iPhone16,1",
    family: "iPhone 15 Pro",
    intro: "September 12, 2023",
    disc: "N/A",
    region: "China",
    minIOS: "17.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 15 Plus",
    model: "A2847",
    id: "iPhone15,5",
    family: "iPhone 15 Plus",
    intro: "September 12, 2023",
    disc: "N/A",
    region: "United States",
    minIOS: "17.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 15 Plus",
    model: "A3093",
    id: "iPhone15,5",
    family: "iPhone 15 Plus",
    intro: "September 12, 2023",
    disc: "N/A",
    region: "Canada, Mexico, Japan, Saudi Arabia",
    minIOS: "17.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 15 Plus",
    model: "A3094",
    id: "iPhone15,5",
    family: "iPhone 15 Plus",
    intro: "September 12, 2023",
    disc: "N/A",
    region: "Global",
    minIOS: "17.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 15 Plus",
    model: "A3096",
    id: "iPhone15,5",
    family: "iPhone 15 Plus",
    intro: "September 12, 2023",
    disc: "N/A",
    region: "China",
    minIOS: "17.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 15",
    model: "A2846",
    id: "iPhone15,4",
    family: "iPhone 15",
    intro: "September 12, 2023",
    disc: "N/A",
    region: "United States",
    minIOS: "17.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 15",
    model: "A3089",
    id: "iPhone15,4",
    family: "iPhone 15",
    intro: "September 12, 2023",
    disc: "N/A",
    region: "Canada, Mexico, Japan, Saudi Arabia",
    minIOS: "17.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 15",
    model: "A3090",
    id: "iPhone15,4",
    family: "iPhone 15",
    intro: "September 12, 2023",
    disc: "N/A",
    region: "Global",
    minIOS: "17.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 15",
    model: "A3092",
    id: "iPhone15,4",
    family: "iPhone 15",
    intro: "September 12, 2023",
    disc: "N/A",
    region: "China",
    minIOS: "17.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 14 Pro Max",
    model: "A2651",
    id: "iPhone15,3",
    family: "iPhone 14 Pro Max",
    intro: "September 7, 2022",
    disc: "September 12, 2023",
    region: "United States",
    minIOS: "16.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 14 Pro Max",
    model: "A2893",
    id: "iPhone15,3",
    family: "iPhone 14 Pro Max",
    intro: "September 7, 2022",
    disc: "September 12, 2023",
    region: "Canada, Mexico, Japan, Saudi Arabia",
    minIOS: "16.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 14 Pro Max",
    model: "A2894",
    id: "iPhone15,3",
    family: "iPhone 14 Pro Max",
    intro: "September 7, 2022",
    disc: "September 12, 2023",
    region: "Global",
    minIOS: "16.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 14 Pro Max",
    model: "A2896",
    id: "iPhone15,3",
    family: "iPhone 14 Pro Max",
    intro: "September 7, 2022",
    disc: "September 12, 2023",
    region: "China",
    minIOS: "16.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 14 Pro",
    model: "A2650",
    id: "iPhone15,2",
    family: "iPhone 14 Pro",
    intro: "September 7, 2022",
    disc: "September 12, 2023",
    region: "United States",
    minIOS: "16.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 14 Pro",
    model: "A2889",
    id: "iPhone15,2",
    family: "iPhone 14 Pro",
    intro: "September 7, 2022",
    disc: "September 12, 2023",
    region: "Canada, Mexico, Japan, Saudi Arabia",
    minIOS: "16.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 14 Pro",
    model: "A2890",
    id: "iPhone15,2",
    family: "iPhone 14 Pro",
    intro: "September 7, 2022",
    disc: "September 12, 2023",
    region: "Global",
    minIOS: "16.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 14 Pro",
    model: "A2892",
    id: "iPhone15,2",
    family: "iPhone 14 Pro",
    intro: "September 7, 2022",
    disc: "September 12, 2023",
    region: "China",
    minIOS: "16.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 14 Plus",
    model: "A2632",
    id: "iPhone14,8",
    family: "iPhone 14 Plus",
    intro: "September 7, 2022",
    disc: "N/A",
    region: "United States",
    minIOS: "16.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 14 Plus",
    model: "A2885",
    id: "iPhone14,8",
    family: "iPhone 14 Plus",
    intro: "September 7, 2022",
    disc: "N/A",
    region: "Canada, Mexico, Japan, Saudi Arabia",
    minIOS: "16.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 14 Plus",
    model: "A2886",
    id: "iPhone14,8",
    family: "iPhone 14 Plus",
    intro: "September 7, 2022",
    disc: "N/A",
    region: "Global",
    minIOS: "16.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 14 Plus",
    model: "A2888",
    id: "iPhone14,8",
    family: "iPhone 14 Plus",
    intro: "September 7, 2022",
    disc: "N/A",
    region: "China",
    minIOS: "16.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 14",
    model: "A2649",
    id: "iPhone14,7",
    family: "iPhone 14",
    intro: "September 7, 2022",
    disc: "N/A",
    region: "United States",
    minIOS: "16.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 14",
    model: "A2881",
    id: "iPhone14,7",
    family: "iPhone 14",
    intro: "September 7, 2022",
    disc: "N/A",
    region: "Canada, Mexico, Japan, Saudi Arabia",
    minIOS: "16.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 14",
    model: "A2882",
    id: "iPhone14,7",
    family: "iPhone 14",
    intro: "September 7, 2022",
    disc: "N/A",
    region: "Global",
    minIOS: "16.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 14",
    model: "A2884",
    id: "iPhone14,7",
    family: "iPhone 14",
    intro: "September 7, 2022",
    disc: "N/A",
    region: "China",
    minIOS: "16.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone SE (3rd generation)",
    model: "A2595",
    id: "iPhone14,6",
    family: "iPhone SE",
    intro: "March 8, 2022",
    disc: "N/A",
    region: "United States, Canada",
    minIOS: "15.4",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone SE (3rd generation)",
    model: "A2782",
    id: "iPhone14,6",
    family: "iPhone SE",
    intro: "March 8, 2022",
    disc: "N/A",
    region: "Japan",
    minIOS: "15.4",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone SE (3rd generation)",
    model: "A2783",
    id: "iPhone14,6",
    family: "iPhone SE",
    intro: "March 8, 2022",
    disc: "N/A",
    region: "Global",
    minIOS: "15.4",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone SE (3rd generation)",
    model: "A2785",
    id: "iPhone14,6",
    family: "iPhone SE",
    intro: "March 8, 2022",
    disc: "N/A",
    region: "China",
    minIOS: "15.4",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 13 Pro Max",
    model: "A2484",
    id: "iPhone14,3",
    family: "iPhone 13 Pro Max",
    intro: "September 14, 2021",
    disc: "September 7, 2022",
    region: "United States",
    minIOS: "15.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 13 Pro Max",
    model: "A2641",
    id: "iPhone14,3",
    family: "iPhone 13 Pro Max",
    intro: "September 14, 2021",
    disc: "September 7, 2022",
    region: "Canada, Japan, Mexico, Saudi Arabia",
    minIOS: "15.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 13 Pro Max",
    model: "A2643",
    id: "iPhone14,3",
    family: "iPhone 13 Pro Max",
    intro: "September 14, 2021",
    disc: "September 7, 2022",
    region: "Global",
    minIOS: "15.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 13 Pro Max",
    model: "A2644",
    id: "iPhone14,3",
    family: "iPhone 13 Pro Max",
    intro: "September 14, 2021",
    disc: "September 7, 2022",
    region: "China",
    minIOS: "15.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 13 Pro",
    model: "A2483",
    id: "iPhone14,2",
    family: "iPhone 13 Pro",
    intro: "September 14, 2021",
    disc: "September 7, 2022",
    region: "United States",
    minIOS: "15.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 13 Pro",
    model: "A2636",
    id: "iPhone14,2",
    family: "iPhone 13 Pro",
    intro: "September 14, 2021",
    disc: "September 7, 2022",
    region: "Canada, Japan, Mexico, Saudi Arabia",
    minIOS: "15.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 13 Pro",
    model: "A2638",
    id: "iPhone14,2",
    family: "iPhone 13 Pro",
    intro: "September 14, 2021",
    disc: "September 7, 2022",
    region: "Global",
    minIOS: "15.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 13 Pro",
    model: "A2639",
    id: "iPhone14,2",
    family: "iPhone 13 Pro",
    intro: "September 14, 2021",
    disc: "September 7, 2022",
    region: "China",
    minIOS: "15.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 13",
    model: "A2482",
    id: "iPhone14,5",
    family: "iPhone 13",
    intro: "September 14, 2021",
    disc: "N/A",
    region: "United States",
    minIOS: "15.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 13",
    model: "A2631",
    id: "iPhone14,5",
    family: "iPhone 13",
    intro: "September 14, 2021",
    disc: "N/A",
    region: "Canada, Japan, Mexico, Saudi Arabia",
    minIOS: "15.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 13",
    model: "A2633",
    id: "iPhone14,5",
    family: "iPhone 13",
    intro: "September 14, 2021",
    disc: "N/A",
    region: "Global",
    minIOS: "15.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 13",
    model: "A2634",
    id: "iPhone14,5",
    family: "iPhone 13",
    intro: "September 14, 2021",
    disc: "N/A",
    region: "China",
    minIOS: "15.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 13 mini",
    model: "A2481",
    id: "iPhone14,4",
    family: "iPhone 13 mini",
    intro: "September 14, 2021",
    disc: "September 12, 2023",
    region: "United States",
    minIOS: "15.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 13 mini",
    model: "A2626",
    id: "iPhone14,4",
    family: "iPhone 13 mini",
    intro: "September 14, 2021",
    disc: "September 12, 2023",
    region: "Canada, Japan, Mexico, Saudi Arabia",
    minIOS: "15.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 13 mini",
    model: "A2628",
    id: "iPhone14,4",
    family: "iPhone 13 mini",
    intro: "September 14, 2021",
    disc: "September 12, 2023",
    region: "Global",
    minIOS: "15.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 13 mini",
    model: "A2629",
    id: "iPhone14,4",
    family: "iPhone 13 mini",
    intro: "September 14, 2021",
    disc: "September 12, 2023",
    region: "China",
    minIOS: "15.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 12 Pro Max",
    model: "A2342",
    id: "iPhone13,4",
    family: "iPhone 12 Pro Max",
    intro: "October 13, 2020",
    disc: "September 14, 2021",
    region: "United States",
    minIOS: "14.1",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 12 Pro Max",
    model: "A2410",
    id: "iPhone13,4",
    family: "iPhone 12 Pro Max",
    intro: "October 13, 2020",
    disc: "September 14, 2021",
    region: "Canada, Japan",
    minIOS: "14.1",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 12 Pro Max",
    model: "A2411",
    id: "iPhone13,4",
    family: "iPhone 12 Pro Max",
    intro: "October 13, 2020",
    disc: "September 14, 2021",
    region: "Global",
    minIOS: "14.1",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 12 Pro Max",
    model: "A2412",
    id: "iPhone13,4",
    family: "iPhone 12 Pro Max",
    intro: "October 13, 2020",
    disc: "September 14, 2021",
    region: "China",
    minIOS: "14.1",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 12 Pro",
    model: "A2341",
    id: "iPhone13,3",
    family: "iPhone 12 Pro",
    intro: "October 13, 2020",
    disc: "September 14, 2021",
    region: "United States",
    minIOS: "14.1",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 12 Pro",
    model: "A2406",
    id: "iPhone13,3",
    family: "iPhone 12 Pro",
    intro: "October 13, 2020",
    disc: "September 14, 2021",
    region: "Canada, Japan",
    minIOS: "14.1",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 12 Pro",
    model: "A2407",
    id: "iPhone13,3",
    family: "iPhone 12 Pro",
    intro: "October 13, 2020",
    disc: "September 14, 2021",
    region: "Global",
    minIOS: "14.1",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 12 Pro",
    model: "A2408",
    id: "iPhone13,3",
    family: "iPhone 12 Pro",
    intro: "October 13, 2020",
    disc: "September 14, 2021",
    region: "China",
    minIOS: "14.1",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 12",
    model: "A2172",
    id: "iPhone13,2",
    family: "iPhone 12",
    intro: "October 13, 2020",
    disc: "September 12, 2023",
    region: "United States",
    minIOS: "14.1",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 12",
    model: "A2402",
    id: "iPhone13,2",
    family: "iPhone 12",
    intro: "October 13, 2020",
    disc: "September 12, 2023",
    region: "Canada, Japan",
    minIOS: "14.1",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 12",
    model: "A2403",
    id: "iPhone13,2",
    family: "iPhone 12",
    intro: "October 13, 2020",
    disc: "September 12, 2023",
    region: "Global",
    minIOS: "14.1",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 12",
    model: "A2404",
    id: "iPhone13,2",
    family: "iPhone 12",
    intro: "October 13, 2020",
    disc: "September 12, 2023",
    region: "China",
    minIOS: "14.1",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 12 mini",
    model: "A2176",
    id: "iPhone13,1",
    family: "iPhone 12 mini",
    intro: "October 13, 2020",
    disc: "September 7, 2022",
    region: "United States",
    minIOS: "14.1",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 12 mini",
    model: "A2398",
    id: "iPhone13,1",
    family: "iPhone 12 mini",
    intro: "October 13, 2020",
    disc: "September 7, 2022",
    region: "Canada, Japan",
    minIOS: "14.1",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 12 mini",
    model: "A2399",
    id: "iPhone13,1",
    family: "iPhone 12 mini",
    intro: "October 13, 2020",
    disc: "September 7, 2022",
    region: "Global",
    minIOS: "14.1",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 12 mini",
    model: "A2400",
    id: "iPhone13,1",
    family: "iPhone 12 mini",
    intro: "October 13, 2020",
    disc: "September 7, 2022",
    region: "China",
    minIOS: "14.1",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone SE (2nd generation)",
    model: "A2275",
    id: "iPhone12,8",
    family: "iPhone SE",
    intro: "April 15, 2020",
    disc: "March 8, 2022",
    region: "United States, Canada",
    minIOS: "13.4",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone SE (2nd generation)",
    model: "A2296",
    id: "iPhone12,8",
    family: "iPhone SE",
    intro: "April 15, 2020",
    disc: "March 8, 2022",
    region: "Global",
    minIOS: "13.4",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone SE (2nd generation)",
    model: "A2298",
    id: "iPhone12,8",
    family: "iPhone SE",
    intro: "April 15, 2020",
    disc: "March 8, 2022",
    region: "China",
    minIOS: "13.4",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 11 Pro Max",
    model: "A2161",
    id: "iPhone12,5",
    family: "iPhone 11 Pro Max",
    intro: "September 10, 2019",
    disc: "October 13, 2020",
    region: "United States, Canada",
    minIOS: "13.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 11 Pro Max",
    model: "A2218",
    id: "iPhone12,5",
    family: "iPhone 11 Pro Max",
    intro: "September 10, 2019",
    disc: "October 13, 2020",
    region: "Global",
    minIOS: "13.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 11 Pro Max",
    model: "A2220",
    id: "iPhone12,5",
    family: "iPhone 11 Pro Max",
    intro: "September 10, 2019",
    disc: "October 13, 2020",
    region: "China",
    minIOS: "13.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 11 Pro",
    model: "A2160",
    id: "iPhone12,3",
    family: "iPhone 11 Pro",
    intro: "September 10, 2019",
    disc: "October 13, 2020",
    region: "United States, Canada",
    minIOS: "13.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 11 Pro",
    model: "A2215",
    id: "iPhone12,3",
    family: "iPhone 11 Pro",
    intro: "September 10, 2019",
    disc: "October 13, 2020",
    region: "Global",
    minIOS: "13.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 11 Pro",
    model: "A2217",
    id: "iPhone12,3",
    family: "iPhone 11 Pro",
    intro: "September 10, 2019",
    disc: "October 13, 2020",
    region: "China",
    minIOS: "13.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 11",
    model: "A2111",
    id: "iPhone12,1",
    family: "iPhone 11",
    intro: "September 10, 2019",
    disc: "September 7, 2022",
    region: "United States, Canada",
    minIOS: "13.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 11",
    model: "A2221",
    id: "iPhone12,1",
    family: "iPhone 11",
    intro: "September 10, 2019",
    disc: "September 7, 2022",
    region: "Global",
    minIOS: "13.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone 11",
    model: "A2223",
    id: "iPhone12,1",
    family: "iPhone 11",
    intro: "September 10, 2019",
    disc: "September 7, 2022",
    region: "China",
    minIOS: "13.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone XS Max",
    model: "A1921",
    id: "iPhone11,6",
    family: "iPhone XS Max",
    intro: "September 12, 2018",
    disc: "September 10, 2019",
    region: "United States, Canada",
    minIOS: "12.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone XS Max",
    model: "A2101",
    id: "iPhone11,6",
    family: "iPhone XS Max",
    intro: "September 12, 2018",
    disc: "September 10, 2019",
    region: "Global",
    minIOS: "12.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone XS Max",
    model: "A2102",
    id: "iPhone11,6",
    family: "iPhone XS Max",
    intro: "September 12, 2018",
    disc: "September 10, 2019",
    region: "China",
    minIOS: "12.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone XS",
    model: "A1920",
    id: "iPhone11,2",
    family: "iPhone XS",
    intro: "September 12, 2018",
    disc: "September 10, 2019",
    region: "United States, Canada",
    minIOS: "12.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone XS",
    model: "A2097",
    id: "iPhone11,2",
    family: "iPhone XS",
    intro: "September 12, 2018",
    disc: "September 10, 2019",
    region: "Global",
    minIOS: "12.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone XS",
    model: "A2098",
    id: "iPhone11,2",
    family: "iPhone XS",
    intro: "September 12, 2018",
    disc: "September 10, 2019",
    region: "China",
    minIOS: "12.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone XR",
    model: "A1984",
    id: "iPhone11,8",
    family: "iPhone XR",
    intro: "September 12, 2018",
    disc: "September 14, 2021",
    region: "United States, Canada",
    minIOS: "12.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone XR",
    model: "A2105",
    id: "iPhone11,8",
    family: "iPhone XR",
    intro: "September 12, 2018",
    disc: "September 14, 2021",
    region: "Global",
    minIOS: "12.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone XR",
    model: "A2106",
    id: "iPhone11,8",
    family: "iPhone XR",
    intro: "September 12, 2018",
    disc: "September 14, 2021",
    region: "China",
    minIOS: "12.0",
    maxIOS: "17.5.1"
  },
  {
    name: "iPhone X",
    model: "A1865",
    id: "iPhone10,3",
    family: "iPhone X",
    intro: "September 12, 2017",
    disc: "September 12, 2018",
    region: "United States, Canada",
    minIOS: "11.0.1",
    maxIOS: "16.7.5"
  },
  {
    name: "iPhone X",
    model: "A1901",
    id: "iPhone10,6",
    family: "iPhone X",
    intro: "September 12, 2017",
    disc: "September 12, 2018",
    region: "Global",
    minIOS: "11.0.1",
    maxIOS: "16.7.5"
  },
  {
    name: "iPhone X",
    model: "A1902",
    id: "iPhone10,3",
    family: "iPhone X",
    intro: "September 12, 2017",
    disc: "September 12, 2018",
    region: "China",
    minIOS: "11.0.1",
    maxIOS: "16.7.5"
  },
  {
    name: "iPhone 8 Plus",
    model: "A1864",
    id: "iPhone10,2",
    family: "iPhone 8 Plus",
    intro: "September 12, 2017",
    disc: "April 15, 2020",
    region: "United States, Canada",
    minIOS: "11.0",
    maxIOS: "16.7.5"
  },
  {
    name: "iPhone 8 Plus",
    model: "A1897",
    id: "iPhone10,5",
    family: "iPhone 8 Plus",
    intro: "September 12, 2017",
    disc: "April 15, 2020",
    region: "Global",
    minIOS: "11.0",
    maxIOS: "16.7.5"
  },
  {
    name: "iPhone 8 Plus",
    model: "A1898",
    id: "iPhone10,2",
    family: "iPhone 8 Plus",
    intro: "September 12, 2017",
    disc: "April 15, 2020",
    region: "China",
    minIOS: "11.0",
    maxIOS: "16.7.5"
  },
  {
    name: "iPhone 8",
    model: "A1863",
    id: "iPhone10,1",
    family: "iPhone 8",
    intro: "September 12, 2017",
    disc: "April 15, 2020",
    region: "United States, Canada",
    minIOS: "11.0",
    maxIOS: "16.7.5"
  },
  {
    name: "iPhone 8",
    model: "A1905",
    id: "iPhone10,4",
    family: "iPhone 8",
    intro: "September 12, 2017",
    disc: "April 15, 2020",
    region: "Global",
    minIOS: "11.0",
    maxIOS: "16.7.5"
  },
  {
    name: "iPhone 8",
    model: "A1906",
    id: "iPhone10,1",
    family: "iPhone 8",
    intro: "September 12, 2017",
    disc: "April 15, 2020",
    region: "China",
    minIOS: "11.0",
    maxIOS: "16.7.5"
  },
  {
    name: "iPhone 7 Plus",
    model: "A1661",
    id: "iPhone9,2",
    family: "iPhone 7 Plus",
    intro: "September 7, 2016",
    disc: "September 10, 2019",
    region: "Global",
    minIOS: "10.0",
    maxIOS: "15.7.9"
  },
  {
    name: "iPhone 7 Plus",
    model: "A1784",
    id: "iPhone9,4",
    family: "iPhone 7 Plus",
    intro: "September 7, 2016",
    disc: "September 10, 2019",
    region: "Global",
    minIOS: "10.0",
    maxIOS: "15.7.9"
  },
  {
    name: "iPhone 7 Plus",
    model: "A1785",
    id: "iPhone9,2",
    family: "iPhone 7 Plus",
    intro: "September 7, 2016",
    disc: "September 10, 2019",
    region: "Japan",
    minIOS: "10.0",
    maxIOS: "15.7.9"
  },
  {
    name: "iPhone 7",
    model: "A1660",
    id: "iPhone9,1",
    family: "iPhone 7",
    intro: "September 7, 2016",
    disc: "September 10, 2019",
    region: "Global",
    minIOS: "10.0",
    maxIOS: "15.7.9"
  },
  {
    name: "iPhone 7",
    model: "A1778",
    id: "iPhone9,3",
    family: "iPhone 7",
    intro: "September 7, 2016",
    disc: "September 10, 2019",
    region: "Global",
    minIOS: "10.0",
    maxIOS: "15.7.9"
  },
  {
    name: "iPhone 7",
    model: "A1779",
    id: "iPhone9,1",
    family: "iPhone 7",
    intro: "September 7, 2016",
    disc: "September 10, 2019",
    region: "Japan",
    minIOS: "10.0",
    maxIOS: "15.7.9"
  },
  {
    name: "iPhone SE (1st generation)",
    model: "A1662",
    id: "iPhone8,4",
    family: "iPhone SE",
    intro: "March 21, 2016",
    disc: "September 12, 2018",
    region: "United States",
    minIOS: "9.3",
    maxIOS: "15.7.9"
  },
  {
    name: "iPhone SE (1st generation)",
    model: "A1723",
    id: "iPhone8,4",
    family: "iPhone SE",
    intro: "March 21, 2016",
    disc: "September 12, 2018",
    region: "Global",
    minIOS: "9.3",
    maxIOS: "15.7.9"
  },
  {
    name: "iPhone SE (1st generation)",
    model: "A1724",
    id: "iPhone8,4",
    family: "iPhone SE",
    intro: "March 21, 2016",
    disc: "September 12, 2018",
    region: "China",
    minIOS: "9.3",
    maxIOS: "15.7.9"
  },
  {
    name: "iPhone 6s Plus",
    model: "A1634",
    id: "iPhone8,2",
    family: "iPhone 6s Plus",
    intro: "September 9, 2015",
    disc: "September 12, 2018",
    region: "United States",
    minIOS: "9.0",
    maxIOS: "15.7.9"
  },
  {
    name: "iPhone 6s Plus",
    model: "A1687",
    id: "iPhone8,2",
    family: "iPhone 6s Plus",
    intro: "September 9, 2015",
    disc: "September 12, 2018",
    region: "Global",
    minIOS: "9.0",
    maxIOS: "15.7.9"
  },
  {
    name: "iPhone 6s Plus",
    model: "A1690",
    id: "iPhone8,2",
    family: "iPhone 6s Plus",
    intro: "September 9, 2015",
    disc: "September 12, 2018",
    region: "China",
    minIOS: "9.0",
    maxIOS: "15.7.9"
  },
  {
    name: "iPhone 6s Plus",
    model: "A1699",
    id: "iPhone8,2",
    family: "iPhone 6s Plus",
    intro: "September 9, 2015",
    disc: "September 12, 2018",
    region: "China",
    minIOS: "9.0",
    maxIOS: "15.7.9"
  },
  {
    name: "iPhone 6s",
    model: "A1633",
    id: "iPhone8,1",
    family: "iPhone 6s",
    intro: "September 9, 2015",
    disc: "September 12, 2018",
    region: "United States",
    minIOS: "9.0",
    maxIOS: "15.7.9"
  },
  {
    name: "iPhone 6s",
    model: "A1688",
    id: "iPhone8,1",
    family: "iPhone 6s",
    intro: "September 9, 2015",
    disc: "September 12, 2018",
    region: "Global",
    minIOS: "9.0",
    maxIOS: "15.7.9"
  },
  {
    name: "iPhone 6s",
    model: "A1691",
    id: "iPhone8,1",
    family: "iPhone 6s",
    intro: "September 9, 2015",
    disc: "September 12, 2018",
    region: "China",
    minIOS: "9.0",
    maxIOS: "15.7.9"
  },
  {
    name: "iPhone 6s",
    model: "A1700",
    id: "iPhone8,1",
    family: "iPhone 6s",
    intro: "September 9, 2015",
    disc: "September 12, 2018",
    region: "China",
    minIOS: "9.0",
    maxIOS: "15.7.9"
  }
]


const IPhoneModelSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchField, setSearchField] = useState('all');

  const filteredData = useMemo(() => {
    if (!searchTerm) return iPhoneData;
    return iPhoneData.filter(item => {
      if (searchField === 'all') {
        return Object.values(item).some(value =>
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
      } else {
        return item[searchField].toString().toLowerCase().includes(searchTerm.toLowerCase());
      }
    });
  }, [searchTerm, searchField]);

  const highlightText = (text, highlight) => {
    if (!highlight.trim()) {
      return <span>{text}</span>;
    }
    const regex = new RegExp(`(${highlight})`, 'gi');
    const parts = text.split(regex);
    return (
      <span>
        {parts.map((part, i) =>
          regex.test(part) ? <mark key={i} className="bg-yellow-200">{part}</mark> : <span key={i}>{part}</span>
        )}
      </span>
    );
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">iPhone Model Search</h1>
      <div className="relative mb-4">
        <div className="absolute inset-y-0 left-0 flex items-center">
          <select
            className="h-full rounded-l-md border-transparent bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
          >
            <option value="all">All Fields</option>
            <option value="name">Name</option>
            <option value="model">Model</option>
            <option value="id">ID</option>
            <option value="family">Family</option>
            <option value="region">Region</option>
            <option value="minIOS">Min iOS</option>
            <option value="maxIOS">Max iOS</option>
          </select>
        </div>
        <input
          type="text"
          placeholder="Search iPhone models..."
          className="w-full p-2 pl-32 pr-10 border border-gray-300 rounded"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Search className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-100">
            <tr>
              <th className="p-2 border">Name</th>
              <th className="p-2 border">Model</th>
              <th className="p-2 border">ID</th>
              <th className="p-2 border">Family</th>
              <th className="p-2 border">Intro Date</th>
              <th className="p-2 border">Disc Date</th>
              <th className="p-2 border">Region</th>
              <th className="p-2 border">Min iOS</th>
              <th className="p-2 border">Max iOS</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                <td className="p-2 border">{highlightText(item.name, searchTerm)}</td>
                <td className="p-2 border">{highlightText(item.model, searchTerm)}</td>
                <td className="p-2 border">{highlightText(item.id, searchTerm)}</td>
                <td className="p-2 border">{highlightText(item.family, searchTerm)}</td>
                <td className="p-2 border">{highlightText(item.intro, searchTerm)}</td>
                <td className="p-2 border">{highlightText(item.disc, searchTerm)}</td>
                <td className="p-2 border">{highlightText(item.region, searchTerm)}</td>
                <td className="p-2 border">{highlightText(item.minIOS, searchTerm)}</td>
                <td className="p-2 border">{highlightText(item.maxIOS, searchTerm)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default IPhoneModelSearch;