import React, { useState, useMemo, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import routes from '../routes';
import { ThemeContext } from '../contexts/ThemeContext';
import TypewriterTitle from './common/TypewriterTitle';
import { Heart, Users, Zap, Search } from 'lucide-react';
import CommandPalette from './common/CommandPalette';

const HomePage = () => {
    const { theme } = useContext(ThemeContext);
    const [isCommandPaletteOpen, setIsCommandPaletteOpen] = useState(false);
    const commands = routes.filter(route => route.path !== '/');

    const faqData = [
        {
            question: "Can I cancel at anytime?",
            answer: "Yes, you can cancel anytime. No questions asked, but we'd appreciate your feedback."
        },
        {
            question: "How does pricing work?",
            answer: "Our pricing is tiered based on the features and usage. Please check our pricing page for more details."
        }
    ];

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
                e.preventDefault();
                setIsCommandPaletteOpen(prev => !prev);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, []);

    return (
        <div className={`min-h-screen w-full overflow-x-hidden ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-teal-50 text-gray-800'} flex flex-col items-center justify-center p-4 sm:p-10`}>
            <TypewriterTitle />

            <p className="mt-6 text-xl sm:text-2xl text-center max-w-3xl mx-auto">
                Boost your productivity, unleash your creativity, and simplify your workflow with our powerful tools.
            </p>

            <div className="mt-10 flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-6 text-lg w-full max-w-md sm:max-w-2xl">
                <Link
                    to="/get-started"
                    className={`bg-slate-900 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 text-white font-semibold h-12 px-6 rounded-full w-full flex items-center justify-center sm:w-auto ${theme === 'dark' ? 'dark:bg-sky-500 dark:highlight-white/20 dark:hover:bg-sky-400' : ''
                        }`}
                >
                    Get started
                </Link>
                <button
                    type="button"
                    className={`flex items-center w-full sm:w-72 text-left space-x-3 px-4 h-12 bg-white ring-1 ring-slate-900/10 hover:ring-slate-300 focus:outline-none focus:ring-2 focus:ring-sky-500 shadow-sm rounded-full text-slate-400 ${theme === 'dark' ? 'dark:bg-slate-800 dark:ring-0 dark:text-slate-300 dark:highlight-white/5 dark:hover:bg-slate-700' : ''
                        }`}
                    onClick={() => setIsCommandPaletteOpen(true)}
                >
                    <Search className="flex-none text-slate-300 dark:text-slate-400" size={20} />
                    <span className="flex-auto">Quick search...</span>
                    <kbd className="font-sans font-semibold dark:text-slate-500">
                        <abbr title="Control" className="no-underline text-slate-300 dark:text-slate-500">Ctrl </abbr> K
                    </kbd>
                </button>
            </div>
            <CommandPalette
                isOpen={isCommandPaletteOpen}
                setIsOpen={setIsCommandPaletteOpen}
                commands={commands}
            />

            {commands.length > 0 && (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-8 mt-10 sm:mt-20 w-full max-w-7xl mx-auto px-4 justify-items-center">
                    {commands.map((command, index) => (
                        <ToolCard key={index} {...command} index={index} />
                    ))}
                </div>
            )}
            <FAQ faqs={faqData} />
        </div>
    );
};

const getRandomColor = () => {
    const hue = Math.floor(Math.random() * 360);
    return `hsl(${hue}, 70%, 80%)`;
};

const getRandomPosition = () => {
    return `${Math.floor(Math.random() * 100)}%`;
};

const getRandomSize = () => {
    return `${Math.floor(Math.random() * 60 + 40)}%`;
};

const ToolCard = ({ icon: Icon, name, desc, path, index }) => {
    const { t } = useTranslation();
    const { theme } = useContext(ThemeContext);

    const gradientStyle = useMemo(() => {
        return {
            background: `
          radial-gradient(circle at ${getRandomPosition()} ${getRandomPosition()}, ${getRandomColor()} 0%, transparent ${getRandomSize()}),
          radial-gradient(circle at ${getRandomPosition()} ${getRandomPosition()}, ${getRandomColor()} 0%, transparent ${getRandomSize()}),
          radial-gradient(circle at ${getRandomPosition()} ${getRandomPosition()}, ${getRandomColor()} 0%, transparent ${getRandomSize()})
        `
        };

        // const startColor = `hsl(${index * 40}, 70%, 80%)`;
        // const endColor = `hsl(${index * 40 + 20}, 70%, 90%)`;
        // return {
        //     background: `linear-gradient(135deg, ${startColor} 0%, ${endColor} 100%)`
        // };
    }, []);

    const translatedName = useMemo(() => t(name), [t, name]);
    const translatedDescription = useMemo(() => t(desc), [t, desc]);

    const usersCount = 1234;
    const usageCount = 5678;
    const likesCount = 910;

    return (
        <motion.div
            className={`relative overflow-hidden rounded-xl p-4 sm:p-8 ${theme === 'dark' ? 'bg-gray-800' : ''}`}
            style={{
                width: '100%',
                maxWidth: '350px',
                height: 'auto',
                minHeight: '280px',
                boxShadow: theme === 'dark'
                    ? '0 0 15px rgba(255, 255, 255, 0.35)'
                    : '0 4px 6px rgba(0, 0, 0, 0.2)'
            }}
            whileHover={{
                scale: 1.05,
                boxShadow: theme === 'dark'
                    ? '0 0 25px rgba(255, 255, 255, 0.5)'
                    : '0 10px 20px rgba(0, 0, 0, 0.15)',
                transition: { type: "spring", stiffness: 300, damping: 20 }
            }}
        >
            <div
                className="absolute inset-0 opacity-70 blur-xxl"
                style={gradientStyle}
            />

            <motion.div
                className="relative z-10 h-full flex flex-col justify-between"
                initial="rest"
                whileHover="hover"
            >
                <Link to={path} className="flex-grow">
                    <div className='flex-grow'>
                        <motion.h2
                            className="text-2xl sm:text-4xl font-bold mb-4 font-sans text-white"
                            style={{
                                textShadow: '2px 2px 4px rgba(0,0,0,0.3)'
                            }}
                        >
                            {translatedName}
                        </motion.h2>

                        <motion.p className={`text-sm sm:text-md ${theme === 'dark' ? 'text-white' : 'text-gray-400'}`}>
                            {translatedDescription}
                        </motion.p>
                    </div>

                    <motion.div
                        className="absolute bottom-4 right-4"
                        variants={{
                            rest: { rotate: 0 },
                            hover: {
                                rotate: [0, -30, 30, -20, 20, -10, 10, 0],
                                transition: { duration: 0.6 }
                            }
                        }}
                    >
                        <Icon className="w-24 h-24 sm:w-32 sm:h-32 text-black opacity-10" />
                    </motion.div>
                </Link>

                <div className={`flex justify-between items-center mt-4 text-xs sm:text-sm ${theme === 'dark' ? 'text-white' : 'text-gray-400'}`}>
                    <div className="flex space-x-4">
                        <div className="flex items-center">
                            <Users size={16} className="mr-1" />
                            <span>{usersCount}</span>
                        </div>
                        <div className="flex items-center">
                            <Zap size={16} className="mr-1" />
                            <span>{usageCount}</span>
                        </div>
                        <div className="flex items-center">
                            <Heart size={16} className="mr-1" />
                            <span>{likesCount}</span>
                        </div>
                    </div>
                </div>
            </motion.div>
        </motion.div>
    );
};

const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { theme } = useContext(ThemeContext);

    return (
        <motion.div
            className={`border border-gray-200 rounded-xl p-4 sm:p-6 transition-all duration-300 ease-in-out cursor-pointer ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'}`}
            whileHover={{
                backgroundColor: "#f0fdfa"
            }}
            onClick={() => setIsOpen(!isOpen)}
        >
            <div className="flex justify-between items-center">
                <h3 className="text-md sm:text-lg font-semibold">{question}</h3>
                <motion.button
                    className={`text-gray-400 hover:text-gray-600 focus:outline-none ${theme === 'dark' ? 'text-gray-300 hover:text-gray-100' : ''}`}
                    aria-label={isOpen ? "Collapse" : "Expand"}
                    animate={{ rotate: isOpen ? 180 : 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <svg
                        className="w-5 h-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                </motion.button>
            </div>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        {answer.split('\n').map((line, index) => (
                            <p key={index} className="my-2 text-sm sm:text-base">{line}</p>
                        ))}
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    );
};

const FAQ = ({ faqs }) => {
    return (
        <div className="w-full max-w-6xl mt-10 px-4">
            <h2 className="text-2xl sm:text-3xl font-bold text-center mb-6 sm:mb-8">Frequently Asked Questions</h2>
            <div className="grid grid-cols-1 gap-6 sm:gap-8">
                {faqs.map((faq, index) => (
                    <FAQItem key={index} question={faq.question} answer={faq.answer} />
                ))}
            </div>
        </div>
    );
};

export default HomePage;