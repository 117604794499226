import React, { useState, useContext, useMemo } from 'react';
import { motion } from 'framer-motion';
import { ThemeContext } from '../../contexts/ThemeContext';
import { Copy, Check } from 'lucide-react';

const CheatsheetCard = ({ command }) => {
  const [copied, setCopied] = useState(false);
  const { theme } = useContext(ThemeContext);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(command.command).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const gradientStyle = useMemo(() => {
    const getRandomColor = () => `hsl(${Math.floor(Math.random() * 360)}, 70%, 80%)`;
    const getRandomPosition = () => `${Math.floor(Math.random() * 100)}%`;
    const getRandomSize = () => `${Math.floor(Math.random() * 60 + 40)}%`;

    return {
      background: `
        radial-gradient(circle at ${getRandomPosition()} ${getRandomPosition()}, ${getRandomColor()} 0%, transparent ${getRandomSize()}),
        radial-gradient(circle at ${getRandomPosition()} ${getRandomPosition()}, ${getRandomColor()} 0%, transparent ${getRandomSize()}),
        radial-gradient(circle at ${getRandomPosition()} ${getRandomPosition()}, ${getRandomColor()} 0%, transparent ${getRandomSize()})
      `
    };
  }, []);

  return (
    <motion.div
      className={`relative overflow-hidden rounded-xl p-6 ${
        theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'
      } shadow-lg border border-gray-200 hover:border-blue-300 transition-colors duration-300`}
      style={{
        boxShadow: theme === 'dark'
          ? '0 0 15px rgba(255, 255, 255, 0.1)'
          : '0 4px 6px rgba(0, 0, 0, 0.1)'
      }}
      whileHover={{
        scale: 1.02,
        boxShadow: theme === 'dark'
          ? '0 0 20px rgba(255, 255, 255, 0.2)'
          : '0 10px 20px rgba(0, 0, 0, 0.15)',
      }}
    >
      <div
        className="absolute inset-0 opacity-30 blur-xl"
        style={gradientStyle}
      />
      <div className="relative z-10">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-xl font-semibold pr-4">{command.description}</h3>
          <span className={`inline-block px-2 py-1 rounded-xl text-sm font-semibold whitespace-nowrap ${
            command.usage === "高" ? "bg-red-100 text-red-800" :
            command.usage === "中" ? "bg-yellow-100 text-yellow-800" : "bg-green-100 text-green-800"
          }`}>
            使用频率: {command.usage}
          </span>
        </div>
        <p className="text-sm mb-4">{command.explanation}</p>

        <div className={`flex items-center mb-3 overflow-hidden ${
          theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100'
        } rounded-xl`}>
          <button
            onClick={copyToClipboard}
            className={`rounded-xl m-2 p-2 ${
              theme === 'dark'
                ? 'bg-gray-600 hover:bg-gray-500'
                : 'bg-gray-200 hover:bg-gray-300'
            } focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out`}
          >
            {copied ? <Check size={20} /> : <Copy size={20} />}
          </button>
          <code className={`flex-grow px-3 py-2 text-base font-mono ${
            theme === 'dark' ? 'text-gray-200' : 'text-gray-700'
          }`}>
            {command.command}
          </code>
        </div>
      </div>
      {command.options.length > 0 && (
        <div className={`mt-4 pt-3 border-t ${
          theme === 'dark' ? 'border-gray-700' : 'border-gray-200'
        }`}>
          <h4 className="font-semibold mb-2 text-sm">可选参数：</h4>
          <ul className="space-y-1">
            {command.options.map((option, index) => (
              <li key={index} className="flex text-sm">
                <span className="font-mono text-blue-500 mr-2">{option.flag}</span>
                <span>{option.description}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </motion.div>
  );
};

const CheatsheetDashboard = ({ title, commands }) => {
  const [activeTab, setActiveTab] = useState(commands[0].category);
  const { theme } = useContext(ThemeContext);

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-teal-50 text-gray-800'
      } p-8`}>
      <div className="container mx-auto max-w-6xl">
        <h1 className="text-4xl font-bold mb-8 text-center">{title}</h1>
        <div className="mb-8 overflow-x-auto">
          <div className="flex space-x-2 pb-2">
            {commands.map((category) => (
              <button
                key={category.category}
                className={`px-4 py-2 font-medium rounded-full transition duration-150 ease-in-out ${activeTab === category.category
                  ? theme === 'dark'
                    ? "bg-blue-500 text-white"
                    : "bg-blue-100 text-blue-800"
                  : theme === 'dark'
                    ? "text-gray-300 hover:bg-gray-800"
                    : "text-gray-600 hover:bg-gray-100"
                  }`}
                onClick={() => setActiveTab(category.category)}
              >
                {category.category}
              </button>
            ))}
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {commands.find(cat => cat.category === activeTab)?.commands.map((command, index) => (
            <CheatsheetCard key={index} command={command} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CheatsheetDashboard;