import React, { useState } from 'react';

export const ThemeContext = React.createContext({
  theme: 'light',
  toggleTheme: () => {},
});

export const ThemeProvider = ({ children }) => {
  const [isDarkTheme, setIsDarkTheme] = useState(false)

  const toggleTheme = () => setIsDarkTheme(prevTheme => !prevTheme)

  return (
      <ThemeContext.Provider value={{ isDarkTheme, toggleTheme }}>
          {children}
      </ThemeContext.Provider>
  )
}