import React from 'react';
import CheatsheetDashboard from './common/CheatsheetDashboard';

const adbCommands = [
    {
        category: "设备连接",
        commands: [
            {
                command: "adb devices",
                description: "列出连接的设备",
                usage: "高",
                explanation: "这个命令用于列出所有通过USB连接或者网络连接的Android设备。它是最常用的ADB命令之一，通常用于确认设备是否正确连接。",
                options: [
                    { flag: "-l", description: "使用长输出格式" }
                ]
            },
            {
                command: "adb connect <ip>:<port>",
                description: "通过网络连接设备",
                usage: "中",
                explanation: "当你想要通过Wi-Fi连接设备而不是USB时，使用这个命令。你需要知道设备的IP地址和端口号(通常是5555)。",
                options: []
            },
            {
                command: "adb disconnect [<ip>:<port>]",
                description: "断开网络连接的设备",
                usage: "低",
                explanation: "断开指定IP和端口的网络连接设备。如果不指定参数，则断开所有网络连接的设备。",
                options: []
            }
        ]
    },
    {
        category: "应用管理",
        commands: [
            {
                command: "adb install <path_to_apk>",
                description: "安装应用",
                usage: "高",
                explanation: "这个命令用于安装APK文件。你需要提供APK文件的路径。",
                options: [
                    { flag: "-r", description: "覆盖安装已存在的应用" },
                    { flag: "-d", description: "允许降级安装" },
                    { flag: "-g", description: "授予所有运行时权限" }
                ]
            },
            {
                command: "adb uninstall <package_name>",
                description: "卸载应用",
                usage: "中",
                explanation: "用于卸载指定包名的应用。你需要知道应用的包名。",
                options: [
                    { flag: "-k", description: "卸载应用但保留数据和缓存" }
                ]
            },
            {
                command: "adb shell pm list packages",
                description: "列出所有已安装的包",
                usage: "中",
                explanation: "显示设备上所有已安装的应用包名。",
                options: [
                    { flag: "-f", description: "显示包名对应的APK文件路径" },
                    { flag: "-3", description: "仅显示第三方应用" }
                ]
            }
        ]
    },
    {
        category: "文件管理",
        commands: [
            {
                command: "adb push <local> <remote>",
                description: "从电脑复制文件到设备",
                usage: "高",
                explanation: "这个命令用于将文件从电脑复制到Android设备。<local>是电脑上的文件路径，<remote>是设备上的目标路径。",
                options: []
            },
            {
                command: "adb pull <remote> <local>",
                description: "从设备复制文件到电脑",
                usage: "高",
                explanation: "与push相反，这个命令用于从Android设备复制文件到电脑。<remote>是设备上的文件路径，<local>是电脑上的目标路径。",
                options: []
            },
            {
                command: "adb shell ls <path>",
                description: "列出设备上指定目录的内容",
                usage: "中",
                explanation: "这个命令用于查看Android设备上指定目录的内容，类似于Linux的ls命令。",
                options: [
                    { flag: "-l", description: "使用长列表格式" },
                    { flag: "-a", description: "显示所有文件，包括隐藏文件" }
                ]
            }
        ]
    },
    {
        category: "调试",
        commands: [
            {
                command: "adb logcat",
                description: "查看设备日志",
                usage: "高",
                explanation: "这个命令用于实时查看设备的日志输出，对于调试非常有用。",
                options: [
                    { flag: "-v <format>", description: "设置输出格式，如 time, threadtime" },
                    { flag: "*:V", description: "显示所有优先级的日志" }
                ]
            },
            {
                command: "adb shell dumpsys",
                description: "dump系统信息",
                usage: "中",
                explanation: "这个命令用于获取系统服务的状态信息，可以指定具体的服务名。",
                options: [
                    { flag: "<service>", description: "指定要dump的系统服务，如 battery, wifi" }
                ]
            },
            {
                command: "adb shell am start -n <package>/<activity>",
                description: "启动应用的特定Activity",
                usage: "中",
                explanation: "这个命令用于启动指定应用的特定Activity，通常用于测试和调试。",
                options: []
            }
        ]
    }
];

const ADBCommandsDashboard = () => {
    return (
        <CheatsheetDashboard
            title="ADB 常用命令面板"
            commands={adbCommands}
        />
    );
};

export default ADBCommandsDashboard;