import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import PageContainer from './layout/PageContainer';
import SectionTitle from './common/SectionTitle';
import Button from './common/Button';

const LineNumbers = ({ code }) => {
  const lines = code.split('\n');
  return (
    <div className="line-numbers text-gray-500 pr-4 text-right select-none bg-gray-800 p-4" style={{ lineHeight: '1.5em' }}>
      {lines.map((_, i) => <div key={i}>{i + 1}</div>)}
    </div>
  );
};

const TimeComplexityAnalyzer = () => {
  const algorithms = {
    quicksort: {
      name: '快速排序',
      code: `function quickSort(arr) {
  if (arr.length <= 1) return arr;
  const pivot = arr[Math.floor(arr.length / 2)];
  const left = arr.filter(x => x < pivot);
  const middle = arr.filter(x => x === pivot);
  const right = arr.filter(x => x > pivot);
  return [...quickSort(left), ...middle, ...quickSort(right)];
}`,
      complexity: {
        best: 'O(n log n)',
        average: 'O(n log n)',
        worst: 'O(n^2)'
      }
    },
    bubblesort: {
      name: '冒泡排序',
      code: `function bubbleSort(arr) {
  const n = arr.length;
  for (let i = 0; i < n - 1; i++) {
    for (let j = 0; j < n - i - 1; j++) {
      if (arr[j] > arr[j + 1]) {
        [arr[j], arr[j + 1]] = [arr[j + 1], arr[j]];
      }
    }
  }
  return arr;
}`,
      complexity: {
        best: 'O(n)',
        average: 'O(n^2)',
        worst: 'O(n^2)'
      }
    },
    mergesort: {
      name: '归并排序',
      code: `function mergeSort(arr) {
  if (arr.length <= 1) return arr;
  const mid = Math.floor(arr.length / 2);
  const left = arr.slice(0, mid);
  const right = arr.slice(mid);
  return merge(mergeSort(left), mergeSort(right));
}

function merge(left, right) {
  let result = [];
  let leftIndex = 0;
  let rightIndex = 0;
  while (leftIndex < left.length && rightIndex < right.length) {
    if (left[leftIndex] < right[rightIndex]) {
      result.push(left[leftIndex]);
      leftIndex++;
    } else {
      result.push(right[rightIndex]);
      rightIndex++;
    }
  }
  return result.concat(left.slice(leftIndex)).concat(right.slice(rightIndex));
}`,
      complexity: {
        best: 'O(n log n)',
        average: 'O(n log n)',
        worst: 'O(n log n)'
      }
    },
  };

  const [code, setCode] = useState(algorithms.quicksort.code);
  const [selectedAlgorithm, setSelectedAlgorithm] = useState('quicksort');
  const [result, setResult] = useState('');
  const [analysisList, setAnalysisList] = useState([]);

  const handleAlgorithmChange = (e) => {
    const selected = e.target.value;
    setSelectedAlgorithm(selected);
    if (selected in algorithms) {
      setCode(algorithms[selected].code);
    }
  };

  const analyzeComplexity = () => {
    const lines = code.split('\n');
    let complexity = {
      best: 'O(1)',
      average: 'O(1)',
      worst: 'O(1)'
    };
    let nestedLoops = 0;
    let hasRecursion = false;
    let functionName = 'Anonymous Function';

    for (const line of lines) {
      if (line.includes('function') && line.includes('(')) {
        functionName = line.split('function')[1].split('(')[0].trim() || functionName;
      }
      if (line.includes('for') || line.includes('while')) {
        nestedLoops++;
        if (nestedLoops > 1) {
          complexity = {
            best: `O(n^${nestedLoops})`,
            average: `O(n^${nestedLoops})`,
            worst: `O(n^${nestedLoops})`
          };
        } else {
          complexity = {
            best: 'O(n)',
            average: 'O(n)',
            worst: 'O(n)'
          };
        }
      }
      if (line.includes('sort(') || line.includes('.sort(')) {
        complexity = {
          best: 'O(n log n)',
          average: 'O(n log n)',
          worst: 'O(n log n)'
        };
        break;
      }
      if (line.includes('function') && line.includes('(')) {
        const funcName = line.split('function')[1].split('(')[0].trim();
        if (code.includes(funcName + '(')) {
          hasRecursion = true;
        }
      }
    }

    if (hasRecursion) {
      if (complexity.worst === 'O(n)') {
        complexity = {
          best: 'O(log n)',
          average: 'O(n log n)',
          worst: 'O(n^2)'
        };
      } else if (complexity.worst.startsWith('O(n^')) {
        complexity = {
          best: 'O(n log n)',
          average: 'O(n^(log n))',
          worst: 'O(n^n)'
        };
      }
    }

    setResult(`估计的时间复杂度:\n最佳情况: ${complexity.best}\n平均情况: ${complexity.average}\n最坏情况: ${complexity.worst}`);

    const newAnalysis = {
      functionName,
      complexity: complexity.worst,
      bestCase: complexity.best,
      averageCase: complexity.average,
      worstCase: complexity.worst,
      timestamp: Date.now(),
      code
    };

    const updatedList = [...analysisList, newAnalysis].sort((a, b) => {
      const order = ['O(1)', 'O(log n)', 'O(n)', 'O(n log n)', 'O(n^2)', 'O(n^3)', 'O(2^n)', 'O(n!)'];
      return order.indexOf(a.complexity) - order.indexOf(b.complexity);
    });

    setAnalysisList(updatedList);
    localStorage.setItem('analysisHistory', JSON.stringify(updatedList));
  };

  const getRelativeTime = (timestamp) => {
    const seconds = Math.floor((Date.now() - timestamp) / 1000);
    if (seconds < 60) return `${seconds}秒前`;
    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) return `${minutes}分钟前`;
    const hours = Math.floor(minutes / 60);
    if (hours < 24) return `${hours}小时前`;
    const days = Math.floor(hours / 24);
    return `${days}天前`;
  };

  const clearHistory = () => {
    setAnalysisList([]);
    localStorage.removeItem('analysisHistory');
  };

  useEffect(() => {
    const savedAnalyses = localStorage.getItem('analysisHistory');
    if (savedAnalyses) {
      setAnalysisList(JSON.parse(savedAnalyses));
    }
  }, []);

  return (
    <PageContainer>
      <SectionTitle>Time Complexity Analyzer</SectionTitle>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3, duration: 0.5 }}
        className="space-y-6"
      >
        <div className="mb-4">
          <label htmlFor="algorithm-select" className="block text-sm font-medium text-gray-700 mb-2">
            Select Sorting Algorithm
          </label>
          <select
            id="algorithm-select"
            value={selectedAlgorithm}
            onChange={handleAlgorithmChange}
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white bg-opacity-80 backdrop-filter backdrop-blur-sm"
          >
            <option value="">-- Select Algorithm --</option>
            {Object.entries(algorithms).map(([key, value]) => (
              <option key={key} value={key}>{value.name}</option>
            ))}
          </select>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          className="relative flex bg-gray-900 rounded-lg overflow-hidden shadow-lg"
        >
          <LineNumbers code={code} />
          <div className="flex-grow relative">
            <SyntaxHighlighter
              language="javascript"
              style={vscDarkPlus}
              showLineNumbers={false}
              wrapLines={true}
              customStyle={{
                margin: 0,
                padding: '1em',
                backgroundColor: 'transparent',
              }}
              codeTagProps={{
                style: {
                  fontFamily: 'monospace',
                  fontSize: '14px',
                  lineHeight: '20px',
                  display: 'inline-block',
                }
              }}
            >
              {code}
            </SyntaxHighlighter>
            <textarea
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="Enter your function code here..."
              className="absolute top-0 left-0 w-full h-full opacity-0 resize-none p-4 text-white"
              style={{ fontFamily: 'monospace', caretColor: 'white' }}
            />
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.5 }}
        >
          <Button onClick={analyzeComplexity} className="w-full">
            Analyze Time Complexity
          </Button>
        </motion.div>

        {result && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6, duration: 0.5 }}
            className="mt-4 p-4 bg-white bg-opacity-80 rounded-lg shadow-md backdrop-filter backdrop-blur-sm"
          >
            <h2 className="text-lg font-semibold mb-2 text-gray-800">Analysis Result</h2>
            <pre className="whitespace-pre-wrap text-sm text-gray-600">{result}</pre>
          </motion.div>
        )}

        {analysisList.length > 0 && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.7, duration: 0.5 }}
            className="mt-6"
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold text-gray-800">Analysis History</h2>
              <Button onClick={clearHistory} className="bg-red-500 hover:bg-red-600">
                Clear History
              </Button>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {analysisList.map((analysis, index) => (
                <motion.div
                  key={analysis.timestamp}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1, duration: 0.3 }}
                  className="bg-white bg-opacity-80 p-4 rounded-lg shadow-md backdrop-filter backdrop-blur-sm relative"
                >
                  <div className="absolute top-2 left-2 bg-blue-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-sm font-bold">
                    {index + 1}
                  </div>
                  <div className="pl-8">
                    <h3 className="font-semibold text-lg mb-2 text-gray-800">{analysis.functionName}</h3>
                    <p className="text-md text-gray-800 mb-2">
                      <span className="font-medium">Average Case:</span> {analysis.averageCase}
                    </p>
                    <p className="text-xs text-gray-500">
                      <span className="font-medium">Best Case:</span> {analysis.bestCase}
                    </p>
                    <p className="text-xs text-gray-500">
                      <span className="font-medium">Worst Case:</span> {analysis.worstCase}
                    </p>
                    <p className="text-sm text-gray-500">{getRelativeTime(analysis.timestamp)}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        )}
      </motion.div>
    </PageContainer>
  );
};

export default TimeComplexityAnalyzer;