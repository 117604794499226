import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import moment from 'moment-timezone';
import { RefreshCw, ChevronDown, Trash2 } from 'lucide-react';

const TimestampConverter = () => {
    const [timestamp, setTimestamp] = useState(moment().unix().toString());
    const [isMilliseconds, setIsMilliseconds] = useState(false);
    const [formattedTime, setFormattedTime] = useState('');
    const [timezone, setTimezone] = useState(moment.tz.guess());
    const [isReversed, setIsReversed] = useState(false);
    const [isTimestampUnitOpen, setIsTimestampUnitOpen] = useState(false);
    const [isTimezoneOpen, setIsTimezoneOpen] = useState(false);
    const [history, setHistory] = useState([]);

    const toggleTimestampUnit = useCallback(() => {
        console.log('Toggling timestamp unit dropdown');
        setIsTimestampUnitOpen(prev => !prev);
    }, []);

    const timezones = useMemo(() =>
        moment.tz.names().map(tz => ({
            name: tz,
            abbr: moment.tz(tz).format('z'),
            offset: moment.tz(tz).format('Z')
        })),
        []);

    const timezoneOptions = useMemo(() =>
        timezones.map(tz => ({
            value: tz.name,
            label: (
                <div className="flex flex-col">
                    <div className="flex justify-between">
                        <span className="font-semibold">{tz.abbr}</span>
                        <span className="text-sm text-gray-500">{tz.offset}</span>
                    </div>
                    <span className="text-xs text-gray-400">{tz.name}</span>
                </div>
            )
        })),
        [timezones]);


    useEffect(() => {
        if (timestamp) {
            const ts = isMilliseconds ? parseInt(timestamp) : parseInt(timestamp) * 1000;
            const formatted = moment(ts).tz(timezone).format('YYYY-MM-DD HH:mm:ss');
            setFormattedTime(formatted);
            addToHistory('Timestamp to Formatted', timestamp, formatted);
        }
    }, [timestamp, isMilliseconds, timezone]);

    const handleTimeChange = (e) => {
        const time = e.target.value;
        const ts = moment.tz(time, 'YYYY-MM-DD HH:mm:ss', timezone).valueOf();
        const newTimestamp = isMilliseconds ? ts : Math.floor(ts / 1000);
        setTimestamp(newTimestamp.toString());
        addToHistory('Formatted to Timestamp', time, newTimestamp.toString());
    };

    const addToHistory = (type, input, output) => {
        setHistory(prev => [{ type, input, output }, ...prev.slice(0, 9)]);
    };

    const clearHistory = () => {
        setHistory([]);
    };

    const swapCards = () => {
        setIsReversed(!isReversed);
    };

    const Card = useCallback(({ title, content, selector, onChange }) => (
        <div className="..." onClick={(e) => e.stopPropagation()}>

            <div className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-2xl shadow-lg p-6 w-full h-48">
                <h2 className="text-xl font-bold mb-4 text-gray-800">{title}</h2>
                <div className="relative flex items-center bg-white rounded-full overflow-hidden">
                    {selector}
                    <input
                        type="text"
                        className="flex-grow p-3 pl-24 focus:outline-none focus:ring-2 focus:ring-blue-400 bg-transparent"
                        value={content}
                        onChange={onChange}
                        placeholder={title.includes('Unix') ? "Enter Unix timestamp" : "Enter formatted time"}
                    />
                </div>
            </div>
        </div>

    ), []);

    const Dropdown = React.memo(({ options, value, onChange, displayValue, isOpen, setIsOpen }) => {
        const dropdownRef = useRef(null);

        useEffect(() => {
            const handleClickOutside = (event) => {
                if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                    setIsOpen(false);
                }
            };

            // 只在 isOpen 为 true 时添加事件监听器
            if (isOpen) {
                document.addEventListener('mousedown', handleClickOutside);
            }

            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [isOpen, setIsOpen]);

        const toggleDropdown = (e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
        };

        return (
            <div className="absolute left-0 top-0 bottom-0 z-20" ref={dropdownRef}>
                <button
                    onClick={toggleDropdown}
                    className="h-full w-20 flex items-center justify-between bg-gray-100 pl-4 rounded-l-full"
                >
                    {displayValue}
                    <ChevronDown size={16} />
                </button>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.2 }}
                        className="absolute left-0 top-full mt-1 w-80 bg-white border rounded-md shadow-lg z-50 max-h-60 overflow-y-auto"
                    >
                        {options.map((option) => (
                            <div
                                key={option.value}
                                className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => {
                                    onChange(option.value);
                                    setIsOpen(false);
                                }}
                            >
                                {option.label}
                            </div>
                        ))}
                    </motion.div>
                )}
            </div>
        );
    });

    const cards = [
        {
            title: "Unix Timestamp",
            content: timestamp,
            selector: (
                <Dropdown
                    options={[
                        { value: 's', label: 's' },
                        { value: 'ms', label: 'ms' }
                    ]}
                    value={isMilliseconds ? 'ms' : 's'}
                    onChange={(value) => setIsMilliseconds(value === 'ms')}
                    displayValue={isMilliseconds ? 'ms' : 's'}
                    isOpen={isTimestampUnitOpen}
                    setIsOpen={toggleTimestampUnit}
                />
            ),
            onChange: (e) => setTimestamp(e.target.value)
        },
        {
            title: "Formatted Time",
            content: formattedTime,
            selector: (
                <Dropdown
                    options={timezoneOptions}
                    value={timezone}
                    onChange={setTimezone}
                    displayValue={moment.tz(timezone).format('z')}
                    isOpen={isTimezoneOpen}
                    setIsOpen={setIsTimezoneOpen}
                />
            ),
            onChange: handleTimeChange
        }
    ];


    return (
        <div className="min-h-screen bg-gradient-to-br from-[#91EAE4] via-[#86A8E7] to-[#7F7FD5] py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
                <h1 className="text-4xl font-extrabold mb-8 text-center text-white">
                    Timestamp Converter
                </h1>
                <div className="relative z-10"> {/* Add this wrapper */}

                    <AnimatePresence mode="wait">
                        <motion.div
                            key={isReversed ? 'reversed' : 'normal'}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.3 }}
                            className="flex items-center justify-between space-x-4"
                        >
                            <Card {...(isReversed ? cards[1] : cards[0])} />
                            <motion.button
                                onClick={swapCards}
                                className="bg-white bg-opacity-20 text-white font-bold p-3 rounded-full hover:bg-opacity-30 transition duration-300 ease-in-out transform hover:scale-105 backdrop-filter backdrop-blur-lg shadow-lg"
                                whileHover={{ rotate: 180 }}
                                transition={{ duration: 0.3 }}
                            >
                                <RefreshCw size={24} />
                            </motion.button>
                            <Card {...(isReversed ? cards[0] : cards[1])} />
                        </motion.div>
                    </AnimatePresence>
                </div>


                {/* ... (历史记录部分保持不变) */}
                <motion.div
                    className="mt-8 bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-2xl shadow-lg p-6 relative z-0"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                >
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-2xl font-bold text-white">Conversion History</h2>
                        <motion.button
                            onClick={clearHistory}
                            className="text-white p-2 rounded-full transition duration-300 ease-in-out"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <Trash2 size={24} />
                        </motion.button>
                    </div>
                    <table className="w-full">
                        <thead>
                            <tr className="border-b border-gray-200">
                                <th className="text-left p-2 text-white">#</th>
                                <th className="text-left p-2 text-white">Type</th>
                                <th className="text-left p-2 text-white">Input</th>
                                <th className="text-left p-2 text-white">Output</th>
                            </tr>
                        </thead>
                        <tbody>
                            {history.map((item, index) => (
                                <motion.tr
                                    key={index}
                                    initial={{ opacity: 0, y: -10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.3, delay: index * 0.05 }}
                                    className="border-b border-gray-200 last:border-b-0"
                                >
                                    <td className="p-2 text-white">{index + 1}</td>
                                    <td className="p-2 text-white">{item.type}</td>
                                    <td className="p-2 text-white">{item.input}</td>
                                    <td className="p-2 text-green-300">{item.output}</td>
                                </motion.tr>
                            ))}
                        </tbody>
                    </table>
                </motion.div>
            </div>
        </div>
    );
};

export default TimestampConverter;