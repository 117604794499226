import React, { useState, useEffect, useCallback, useContext } from 'react';
import { motion } from 'framer-motion';
import { ThemeContext } from '../contexts/ThemeContext';
import { Info, Clock, ChevronRight, ChevronLeft } from 'lucide-react';

const CrontabExplainer = () => {
  const { theme } = useContext(ThemeContext);
  const [crontab, setCrontab] = useState('');
  const [lastExecutionTime, setLastExecutionTime] = useState('');
  const [lastExecutionGMT, setLastExecutionGMT] = useState('');
  const [explanation, setExplanation] = useState('');
  const [nextExecutions, setNextExecutions] = useState([]);
  const [displayCount, setDisplayCount] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const formatLocalDate = (date) => {
    const offset = date.getTimezoneOffset() * 60000;
    return (new Date(date - offset)).toISOString().slice(0, 16);
  };

  const getNearestValidTime = useCallback((crontabStr) => {
    const now = new Date();
    const [minute, hour, dayOfMonth, month, dayOfWeek] = crontabStr.split(' ');
    let nearestTime = new Date(now);

    while (!matchesCron(nearestTime, minute, hour, dayOfMonth, month, dayOfWeek)) {
      nearestTime.setMinutes(nearestTime.getMinutes() - 1);
    }

    return nearestTime;
  }, []);

  const getNextExecutions = useCallback((crontabStr, startDate, count) => {
    const [minute, hour, dayOfMonth, month, dayOfWeek] = crontabStr.split(' ');
    let currentDate = new Date(startDate);
    const executions = [];

    while (executions.length < count) {
      if (matchesCron(currentDate, minute, hour, dayOfMonth, month, dayOfWeek)) {
        executions.push({
          local: new Date(currentDate),
          gmt: new Date(currentDate.toUTCString())
        });
      }
      currentDate.setMinutes(currentDate.getMinutes() + 1);
    }

    return executions;
  }, []);

  const parseCrontab = useCallback((crontabStr = crontab) => {
    const parts = crontabStr.split(' ');
    if (parts.length !== 5) {
      setExplanation('无效的 crontab 格式。请使用 5 个字段的标准格式。');
      setNextExecutions([]);
      return;
    }

    const [minute, hour, dayOfMonth, month, dayOfWeek] = parts;
    let result = '这个 crontab 会在以下时间执行：\n';

    result += `分钟：${parseField(minute, 0, 59)}\n`;
    result += `小时：${parseField(hour, 0, 23)}\n`;
    result += `日期：${parseField(dayOfMonth, 1, 31, false, true)}\n`;
    result += `月份：${parseField(month, 1, 12, true)}\n`;
    result += `星期：${parseField(dayOfWeek, 0, 7, true)}\n`;

    setExplanation(result);

    const startDate = new Date(lastExecutionTime);
    const nextHundredExecutions = getNextExecutions(crontabStr, startDate, 100);
    setNextExecutions(nextHundredExecutions);
  }, [crontab, getNextExecutions, lastExecutionTime]);

  const parseField = (field, min, max, isMonthOrWeekday = false, isDayOfMonth = false) => {
    if (field === '*') return isMonthOrWeekday ? '每' + (min === 1 ? '月' : '天') : '每' + (min === 0 ? '分钟' : '小时');
    if (field.includes('/')) {
      const [, step] = field.split('/');
      return `每${step}` + (isMonthOrWeekday ? (min === 1 ? '月' : '天') : (min === 0 ? '分钟' : '小时'));
    }
    if (field.includes('-')) {
      const [start, end] = field.split('-');
      return `从${start}到${end}`;
    }
    if (field.includes(',')) {
      return isDayOfMonth ? `每月的${field.replace(/,/g, '日、')}日` : field;
    }
    return isDayOfMonth ? `每月的${field}日` : field;
  };

  const matchesCron = (date, minute, hour, dayOfMonth, month, dayOfWeek) => {
    const matchField = (value, field) => {
      if (field === '*') return true;
      if (field.includes(',')) {
        return field.split(',').some(f => matchField(value, f));
      }
      if (field.includes('-')) {
        const [start, end] = field.split('-').map(Number);
        return value >= start && value <= end;
      }
      if (field.includes('/')) {
        const [, step] = field.split('/');
        return value % parseInt(step) === 0;
      }
      return parseInt(field) === value;
    };

    const matchWeekday = (date, dayOfWeek) => {
      if (dayOfWeek === '*') return true;
      const day = date.getDay();
      return matchField(day === 0 ? 7 : day, dayOfWeek);
    };

    return matchField(date.getMinutes(), minute) &&
      matchField(date.getHours(), hour) &&
      matchField(date.getDate(), dayOfMonth) &&
      matchField(date.getMonth() + 1, month) &&
      matchWeekday(date, dayOfWeek);
  };

  useEffect(() => {
    const now = new Date();
    setLastExecutionTime(formatLocalDate(now));
    setLastExecutionGMT(now.toUTCString());
  }, []);

  useEffect(() => {
    const trimmedCrontab = crontab.replace(/\s+/g, ' ').trim();
    if (trimmedCrontab !== crontab) {
      setCrontab(trimmedCrontab);
    }
    if (trimmedCrontab.split(' ').length === 5) {
      const nearestValidTime = getNearestValidTime(trimmedCrontab);
      setLastExecutionTime(formatLocalDate(nearestValidTime));
      setLastExecutionGMT(nearestValidTime.toUTCString());
      parseCrontab(trimmedCrontab);
    }
  }, [crontab, getNearestValidTime, parseCrontab]);

  const inputClass = `w-full p-3 border rounded-lg bg-opacity-50 backdrop-filter backdrop-blur-lg ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'
    } focus:outline-none focus:ring-2 focus:ring-purple-400 transition duration-300 ease-in-out`;

  const buttonClass = `px-6 py-3 rounded-full font-semibold text-white transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500`;

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-purple-50 text-gray-800'} py-12 px-4 sm:px-6 lg:px-8`}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className={`max-w-4xl mx-auto ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} rounded-2xl overflow-hidden shadow-2xl`}
      >
        <div className="relative overflow-hidden p-8">
          {/* Mesh Gradient Background */}
          <div
            className="absolute inset-0 opacity-30"
            style={{
              background: `
                radial-gradient(circle at 0% 0%, #8b5cf6 0%, transparent 50%),
                radial-gradient(circle at 100% 100%, #ec4899 0%, transparent 50%),
                radial-gradient(circle at 50% 50%, #6366f1 0%, transparent 50%)
              `,
              filter: 'blur(100px)',
            }}
          />

          <div className="relative z-10">
            <motion.h1
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2, duration: 0.5 }}
              className="text-4xl font-extrabold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-500"
            >
              Crontab 解析器
            </motion.h1>

            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.3, duration: 0.5 }}
              className="mb-6"
            >
              <label className="block mb-2 font-medium">Crontab 表达式：</label>
              <input
                type="text"
                value={crontab}
                onChange={(e) => setCrontab(e.target.value)}
                placeholder="输入 crontab (例如: * * * * *)"
                className={inputClass}
              />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.4, duration: 0.5 }}
              className="mb-6"
            >
              <label className="block mb-2 font-medium">上次执行时间：</label>
              <input
                type="datetime-local"
                value={lastExecutionTime}
                onChange={(e) => {
                  const localDate = new Date(e.target.value);
                  setLastExecutionTime(e.target.value);
                  setLastExecutionGMT(localDate.toUTCString());
                }}
                className={inputClass}
              />
              <div className="mt-2 text-sm text-gray-500 dark:text-gray-400 flex items-center">
                <Info size={14} className="mr-1" />
                <span>GMT: {lastExecutionGMT}</span>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 0.5 }}
              className="flex justify-center"
            >
              <button
                onClick={() => parseCrontab()}
                className={`${buttonClass} bg-gradient-to-r from-purple-500 to-pink-500`}
              >
                <Clock className="inline-block mr-2" size={20} />
                解析 Crontab
              </button>
            </motion.div>

            {explanation && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6, duration: 0.5 }}
                className={`mt-8 p-6 rounded-lg ${theme === 'dark' ? 'bg-gray-700' : 'bg-purple-100'}`}
              >
                <h2 className="text-2xl font-semibold mb-4">解析结果</h2>
                <pre className="whitespace-pre-wrap text-sm">{explanation}</pre>
              </motion.div>
            )}

            {nextExecutions.length > 0 && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.7, duration: 0.5 }}
                className="mt-8"
              >
                <h2 className="text-2xl font-semibold mb-4">接下来的执行时间</h2>
                <div className="overflow-x-auto">
                  <table className="w-full border-collapse">
                    <thead>
                      <tr className={`${theme === 'dark' ? 'bg-gray-700' : 'bg-purple-100'}`}>
                        <th className="border border-gray-300 p-2 text-left">序号</th>
                        <th className="border border-gray-300 p-2 text-left">本地时间</th>
                        <th className="border border-gray-300 p-2 text-left">GMT时间</th>
                      </tr>
                    </thead>
                    <tbody>
                      {nextExecutions.slice((currentPage - 1) * displayCount, currentPage * displayCount).map((execution, index) => (
                        <tr key={index} className={index % 2 === 0 ? (theme === 'dark' ? 'bg-gray-800' : 'bg-white') : (theme === 'dark' ? 'bg-gray-700' : 'bg-purple-50')}>
                          <td className="border border-gray-300 p-2">{(currentPage - 1) * displayCount + index + 1}</td>
                          <td className="border border-gray-300 p-2">{execution.local.toLocaleString()}</td>
                          <td className="border border-gray-300 p-2">{execution.gmt.toUTCString()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="mt-4 flex justify-between items-center">
                  <button
                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                    className={`${buttonClass} ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                  >
                    <ChevronLeft size={20} />
                  </button>
                  <span>{currentPage} / {Math.ceil(nextExecutions.length / displayCount)}</span>
                  <button
                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(nextExecutions.length / displayCount)))}
                    disabled={currentPage === Math.ceil(nextExecutions.length / displayCount)}
                    className={`${buttonClass} ${currentPage === Math.ceil(nextExecutions.length / displayCount) ? 'opacity-50 cursor-not-allowed' : ''}`}
                  >
                    <ChevronRight size={20} />
                  </button>
                </div>
                {currentPage * displayCount < 100 && (
                  <motion.button
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.8, duration: 0.5 }}
                    onClick={() => setDisplayCount(prev => prev === 5 ? 20 : 5)}
                    className={`mt-4 w-full ${buttonClass} bg-gradient-to-r from-purple-500 to-pink-500`}
                  >
                    {displayCount === 5 ? '显示更多' : '显示更少'}
                  </motion.button>
                )}
              </motion.div>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default CrontabExplainer;