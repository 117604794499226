import { BugPlay, ScrollText, PencilRuler, Monitor, History, Apple, Clock, Microscope, Smartphone, Hourglass, BatteryCharging, Flag, Timer } from 'lucide-react';
import HomePage from './components/HomePage';
import ChargingTimeCalculator from './components/ChargingTimeCalculator';
import PPICalculator from './components/PPICalculator';
import AndroidVersionTable from './components/AndroidVersionTable';
import IPhoneModelSearch from './components/IPhoneModelSearch';
import TimestampConverter from './components/TimestampConverter';
import MockDataGenerator from './components/MockDataGenerator';
import PhoneNumberParser from './components/PhoneNumberParser';
import BaseConverter from './components/BaseConverter';
import PrivacyPolicyGenerator from './components/PrivacyPolicyGenerator';
import ADBCommandsDashboard from './components/ADBCommandsDashboard';
import LLDBCommandsDashboard from './components/LLDBCommandsDashboard';
import CrontabExplainer from './components/CrontabExplainer';
import TimeComplexityAnalyzer from './components/TimeComplexityAnalyzer';
import RegionsExplorer from './components/RegionsExplorer';
import ModernReactTimer from './components/ModernReactTimer';
// import WithAuth from './components/features/login/WithAuth';

const routes = [
  { path: '/', name: 'Home', Component: HomePage, icon: null },
  { path: '/regions-explorer', name: 'regionsExplorer', desc: 'regionsExplorerDesc', Component: RegionsExplorer, icon: Flag },
  { path: '/mock-data', name: 'mockDataGenerator', desc: 'mockDataGeneratorDesc', Component: MockDataGenerator, icon: Microscope },
  { path: '/privacy-gen', name: 'privacyPolicyGenerator', desc: 'privacyPolicyGeneratorDesc', Component: PrivacyPolicyGenerator, icon: ScrollText },
  { path: '/time-convert', name: 'timestampConverter', desc: 'timestampConverterDesc', Component: TimestampConverter, icon: Clock },
  { path: '/modern-timer', name: 'modernTimer', desc: 'modernTimerDesc', Component: ModernReactTimer, icon: Timer },
  { path: '/phone-parse', name: 'phoneNumberAnalyzer', desc: 'phoneNumberAnalyzerDesc', Component: PhoneNumberParser, icon: Smartphone },
  { path: '/base-convert', name: 'numberBaseConverter', desc: 'numberBaseConverterDesc', Component: BaseConverter, icon: PencilRuler },
  { path: '/algo-complexity', name: 'algorithmComplexityAnalyzer', desc: 'algorithmComplexityAnalyzerDesc', Component: TimeComplexityAnalyzer, icon: Hourglass },
  { path: '/ppi-calc', name: 'ppiCalculator', desc: 'ppiCalculatorDesc', Component: PPICalculator, icon: Monitor },
  { path: '/battery-life', name: 'batteryLifeEstimator', desc: 'batteryLifeEstimatorDesc', Component: ChargingTimeCalculator, icon: BatteryCharging },
  { path: '/android-history', name: 'androidVersionHistory', desc: 'androidVersionHistoryDesc', Component: AndroidVersionTable, icon: History },
  { path: '/apple-finder', name: 'appleDeviceFinder', desc: 'appleDeviceFinderDesc', Component: IPhoneModelSearch, icon: Apple },
  { path: '/adb-guide', name: 'adbCommandReference', desc: 'adbCommandReferenceDesc', Component: ADBCommandsDashboard, icon: BugPlay },
  { path: '/lldb-guide', name: 'lldbCommandReference', desc: 'lldbCommandReferenceDesc', Component: LLDBCommandsDashboard, icon: BugPlay },
  { path: '/cron-decode', name: 'cronExpressionDecoder', desc: 'cronExpressionDecoderDesc', Component: CrontabExplainer, icon: Hourglass },

];

export default routes;