import React from 'react';
import { motion } from 'framer-motion';

const SectionTitle = ({ children }) => (
  <motion.h1
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay: 0.2, duration: 0.5 }}
    className="text-4xl font-extrabold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-600"
  >
    {children}
  </motion.h1>
);

export default SectionTitle;
