import React from 'react';
import { motion } from 'framer-motion';

const PageContainer = ({ children }) => (
  <div className="min-h-screen bg-gradient-to-br from-blue-100 to-purple-100 py-12 px-4 sm:px-6 lg:px-8">
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="max-w-4xl mx-auto bg-white bg-opacity-80 rounded-2xl overflow-hidden shadow-2xl backdrop-filter backdrop-blur-lg"
    >
      <div className="relative overflow-hidden p-8">
        {/* Mesh Gradient Background */}
        <div
          className="absolute inset-0 opacity-30"
          style={{
            background: `
              radial-gradient(circle at 0% 0%, #3b82f6 0%, transparent 50%),
              radial-gradient(circle at 100% 100%, #ef4444 0%, transparent 50%),
              radial-gradient(circle at 50% 50%, #10b981 0%, transparent 50%)
            `,
            filter: 'blur(100px)',
          }}
        />
        <div className="relative z-10">
          {children}
        </div>
      </div>
    </motion.div>
  </div>
);

export default PageContainer;
