import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { faker, allFakers } from '@faker-js/faker';
import { PlusCircle, Trash2, GripVertical, Copy, RefreshCw } from 'lucide-react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import PageContainer from './layout/PageContainer';
import SectionTitle from './common/SectionTitle';
import Button from './common/Button';
import Tooltip from './common/Tooltip';

const getFieldTypes = () => {
  return Object.keys(faker).filter(key =>
    typeof faker[key] === 'object' &&
    key !== 'helpers' &&
    key !== 'datatype' &&
    key !== '_randomizer' &&
    Object.keys(faker[key]).some(subKey => typeof faker[key][subKey] === 'function')
  );
};

const MockDataGenerator = () => {
  const [fields, setFields] = useState([
    { id: 'field-1', name: 'id', type: 'string', subType: 'uuid', required: true },
    { id: 'field-2', name: 'name', type: 'person', subType: 'fullName', required: true },
    { id: 'field-3', name: 'email', type: 'internet', subType: 'email', required: false },
  ]);
  const [language, setLanguage] = useState('en');
  const [count, setCount] = useState(5);
  const [fieldTypes, setFieldTypes] = useState([]);
  const [outputFormat, setOutputFormat] = useState('json');
  const [generatedData, setGeneratedData] = useState('');

  useEffect(() => {
    setFieldTypes(getFieldTypes());
  }, []);

  const addField = useCallback(() => {
    const newField = {
      id: `field-${fields.length + 1}`,
      name: `field${fields.length + 1}`,
      type: 'string',
      subType: 'word',
      required: false,
    };
    setFields(prevFields => [...prevFields, newField]);
  }, [fields.length]);

  const updateField = useCallback((id, updates) => {
    setFields(prevFields =>
      prevFields.map(field =>
        field.id === id
          ? { ...field, ...updates }
          : field
      )
    );
  }, []);

  const removeField = useCallback((id) => {
    setFields(prevFields => prevFields.filter(field => field.id !== id));
  }, []);

  const onDragEnd = useCallback((result) => {
    if (!result.destination) return;
    const items = Array.from(fields);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFields(items);
  }, [fields]);

  const generateMockData = useCallback(() => {
    const selectedFaker = allFakers[language];
    const mockData = Array.from({ length: count }, () => {
      return fields.reduce((acc, field) => {
        try {
          if (selectedFaker[field.type] && typeof selectedFaker[field.type][field.subType] === 'function') {
            acc[field.name] = selectedFaker[field.type][field.subType]();
          } else {
            acc[field.name] = `Unsupported: ${field.type}.${field.subType}`;
          }
        } catch (error) {
          console.error(`Error generating ${field.name}: ${error.message}`);
          acc[field.name] = `Error: ${field.type}.${field.subType}`;
        }
        return acc;
      }, {});
    });

    let formattedData = '';
    switch (outputFormat) {
      case 'json':
        formattedData = JSON.stringify(mockData, null, 2);
        break;
      case 'xml':
        formattedData = `<?xml version="1.0" encoding="UTF-8"?>
<root>
    ${mockData.map(item => `
    <item>
        ${Object.entries(item).map(([key, value]) => `
        <${key}>${value}</${key}>`).join('')}
    </item>`).join('')}
</root>`;
        break;
      default:
        formattedData = JSON.stringify(mockData, null, 2);
        break;
    }
    setGeneratedData(formattedData);
  }, [fields, language, count, outputFormat]);

  const getSubTypes = useCallback((type) => {
    if (!faker[type]) return [];
    return Object.keys(faker[type]).filter(key => typeof faker[type][key] === 'function');
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(generatedData).then(() => {
      alert('Copied to clipboard!');
    });
  };

  const resetFields = () => {
    setFields([
      { id: 'field-1', name: 'id', type: 'string', subType: 'uuid', required: true },
      { id: 'field-2', name: 'name', type: 'person', subType: 'fullName', required: true },
      { id: 'field-3', name: 'email', type: 'internet', subType: 'email', required: false },
    ]);
    setLanguage('en');
    setCount(5);
    setOutputFormat('json');
    setGeneratedData('');
  };

  return (
    <PageContainer className="bg-gradient-to-br from-blue-50 to-purple-50">
      <SectionTitle>Mock Data Generator</SectionTitle>

      <div className="space-y-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.5 }}
        >
          <h2 className="text-xl font-semibold mb-4">Field Settings</h2>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="fields">
              {(provided) => (
                <ul {...provided.droppableProps} ref={provided.innerRef}>
                  {fields.map((field, index) => (
                    <Draggable key={field.id} draggableId={field.id} index={index}>
                      {(provided, snapshot) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`bg-gray-50 p-4 rounded-lg flex items-center space-x-4 ${snapshot.isDragging ? 'shadow-lg' : 'shadow-sm'
                            }`}
                        >
                          <GripVertical size={20} className="text-gray-400" />
                          <input
                            type="text"
                            value={field.name}
                            onChange={(e) => updateField(field.id, { name: e.target.value })}
                            className="flex-grow p-2 border rounded focus:ring-2 focus:ring-blue-300 transition"
                            placeholder="Field Name"
                          />
                          <div className="flex border rounded overflow-hidden">
                            <select
                              value={field.type}
                              onChange={(e) => {
                                const newType = e.target.value;
                                const subTypes = getSubTypes(newType);
                                updateField(field.id, {
                                  type: newType,
                                  subType: subTypes.length > 0 ? subTypes[0] : ''
                                });
                              }}
                              className="p-2 w-1/2 border-r focus:ring-2 focus:ring-blue-300 transition"
                            >
                              {fieldTypes.map(type => (
                                <option key={type} value={type}>{type}</option>
                              ))}
                            </select>
                            <select
                              value={field.subType}
                              onChange={(e) => updateField(field.id, { subType: e.target.value })}
                              className="p-2 w-1/2 focus:ring-2 focus:ring-blue-300 transition"
                            >
                              {getSubTypes(field.type).map(subType => (
                                <option key={subType} value={subType}>{subType}</option>
                              ))}
                            </select>
                          </div>
                          <Tooltip content="Mark this field as required">
                            <label className="flex items-center cursor-pointer">
                              <input
                                type="checkbox"
                                checked={field.required}
                                onChange={(e) => updateField(field.id, { required: e.target.checked })}
                                className="mr-2 focus:ring-2 focus:ring-blue-300 transition"
                              />
                              Required
                            </label>
                          </Tooltip>
                          <Tooltip content="Remove this field">
                            <button onClick={() => removeField(field.id)} className="text-red-500 hover:text-red-700 transition">
                              <Trash2 size={20} />
                            </button>
                          </Tooltip>
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>

          <Button onClick={addField} className="w-full mt-4 inline-flex items-center justify-center">
            <PlusCircle size={20} className="mr-2" />
            Add Field
          </Button>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6, duration: 0.5 }}
          className="bg-white rounded-lg p-6 shadow-md"
        >
          <h2 className="text-xl font-semibold mb-4">Generation Options</h2>
          <div className="flex items-center justify-between space-x-4">
            <div className="flex items-center space-x-2">
              <label>Locale:</label>
              <select
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                className="p-2 rounded-lg border focus:ring-2 focus:ring-blue-300 transition"
              >
                <option value="en">English</option>
                <option value="zh_CN">Chinese</option>
                <option value="fr">French</option>
              </select>
            </div>

            <div className="flex items-center space-x-2">
              <label>Format:</label>
              <select
                value={outputFormat}
                onChange={(e) => setOutputFormat(e.target.value)}
                className="p-2 rounded-lg border focus:ring-2 focus:ring-blue-300 transition"
              >
                <option value="json">JSON</option>
                <option value="xml">XML</option>
              </select>
            </div>

            <div className="flex items-center space-x-2">
              <label>Generate:</label>
              <input
                type="number"
                value={count}
                onChange={(e) => setCount(parseInt(e.target.value) || 1)}
                className="w-16 p-2 rounded-lg text-center border focus:ring-2 focus:ring-blue-300 transition"
                min="1"
              />
              <span>records</span>
            </div>

            <Button onClick={generateMockData}>
              Generate
            </Button>

            <Tooltip content="Reset all settings">
              <Button onClick={resetFields} variant="secondary">
                <RefreshCw size={20} />
              </Button>
            </Tooltip>
          </div>
        </motion.div>

        {generatedData && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.7, duration: 0.5 }}
            className="bg-gray-800 rounded-lg overflow-hidden shadow-lg"
          >
            <div className="flex justify-between items-center bg-gray-700 p-4">
              <h2 className="text-xl font-semibold text-white">Generated Data</h2>
              <Button onClick={copyToClipboard} variant="secondary">
                <Copy size={20} />
              </Button>
            </div>
            <div className="flex">
              <div className="bg-gray-700 p-4 text-right text-gray-400 select-none">
                {generatedData.split('\n').map((_, i) => (
                  <div key={i}>{i + 1}</div>
                ))}
              </div>
              <div className="overflow-auto flex-grow">
                <SyntaxHighlighter
                  language={outputFormat}
                  style={vscDarkPlus}
                  showLineNumbers={false}
                  customStyle={{
                    margin: 0,
                    padding: '1em',
                    backgroundColor: 'transparent',
                  }}
                  codeTagProps={{
                    style: {
                      fontFamily: 'monospace',
                      fontSize: '14px',
                      lineHeight: '20px',
                      display: 'inline-block',
                    }
                  }}
                >
                  {generatedData}
                </SyntaxHighlighter>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </PageContainer>
  );
};

export default MockDataGenerator;